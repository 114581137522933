import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@material-ui/core";
import { ArrowDropDown, Close } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import formInitialValues from "../../../GlobalComponent/FormModel/formInitialValues";
import moment from "moment";
import GlobalFunction from "../../../GlobalFunction";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: "2vw",
  },
  header: {
    padding: "3vw",
    borderBottom: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
  },
  headerIsClose: {
    padding: "2.175vw 3vw",
    borderBottom: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
  },
  headerArea: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "1.1vw",
    fontWeight: "bold",
    margin: "auto",
    marginLeft: 0,
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
    }
  },
  content: {
    padding: 0,
    "&&:last-child": {
      paddingBottom: 0,
    },
  },
  container: {
    padding: "2vw",
    borderBottom: "1px solid #DDDDDD",
  },
  grid: {
    padding: "1vw",
  },
  label: {
    fontSize: "0.8vw",
    textAlign: "left",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.8rem",
    }
  },
  buttonAdd: {
    fontSize: "0.9vw",
    fontWeight: "bold",
    paddingTop: "1vw",
    paddingBottom: "1vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "2vw",
      padding: "2%",
      marginTop: "4%",
    }
  },
  close: {
    textAlign: "right",
  },
  iconButtom: {
    padding: "0.8vw",
  },
  closeIcon: {
    fontSize: "1.7vw",
  },
}));

const Form = ({ title, formik, riwayatPekerjaan }) => {
  const classes = useStyles();
  const numberValidation = /^[0-9]*$/;
  const [dataIndustri, setDataIndustri] = useState([]);
  const [dataLevel, setDataLevel] = useState([]);
  const [fieldAnotherState, setFieldAnotherState] = useState([]);
  const [industryNameAnotherFieldState, setIndustryNameAnotherFieldState] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  
  // const valueAnotherDropDown = {
  //   createdBy: "0",
  //   createdOn: 1648012797000,
  //   industriId: 0,
  //   industriName: "Lainnya",
  //   isDeleted: false,
  //   lastModifiedBy: "0",
  //   lastModifiedOn: 1647494397000,
  // }
  
  useEffect(() => {
    getDataIndustri();
    getDataLevel();
  }, []);

  const getDataIndustri = (index) => {
    API(`formulirKerja.getLovIndustri`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          var listIndustry = res.data.Data

          API(`profile.getDefaultData`, {
            query: { id: localStorage.getItem("applicantId") },
          })
          .then((res) => {
            if (res.data?.Data.workExperiencesList.length !== 0) {
              const tempFieldAnotherState = [...fieldAnotherState];
              if (formik.values.riwayatPekerjaan[0].workExpIndustry === 'Lainnya') {
                tempFieldAnotherState.push(true);
                tempFieldAnotherState[index] = true;
                if (isMounted) {
                  setFieldAnotherState(tempFieldAnotherState);
                }
              }
            }
          });
          
          if (isMounted) {
            setDataIndustri(listIndustry);
          }

        } else {
          Toast.fire({
            icon: "warning",
            title: res.data?.Message || "Gagal mendapat data industri!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Gagal mendapat data industri!",
        });
      });
  };


  const getDataLevel = () => {
    API(`formulirKerja.getLovLevel`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataLevel(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: res.data?.Message || "Gagal mendapat data level!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Gagal mendapat data level!",
        });
      });
  };

  const coba = (index) => {
  

  }

  const onAddForm = () => {
    const tempForm = [...formik.values.riwayatPekerjaan];

    const tempFieldAnotherState = fieldAnotherState
    const tempIndustryNameAnotherFieldState = industryNameAnotherFieldState
  
    tempForm.push({ ...formInitialValues.riwayatPekerjaan });
    tempFieldAnotherState.push(false)
    tempIndustryNameAnotherFieldState.push(false)
  

    formik.setValues({ ...formik.values, riwayatPekerjaan: tempForm });
    setFieldAnotherState(tempFieldAnotherState)
    setIndustryNameAnotherFieldState(tempIndustryNameAnotherFieldState)
  };

  const onRemoveForm = (i) => {
    const formTemp = [...formik.values?.riwayatPekerjaan];
    const touchTemp = [...formik.touched?.riwayatPekerjaan];
    const tempFieldAnotherState = [...fieldAnotherState]
    const tempIndustryNameAnotherFieldState = [...industryNameAnotherFieldState]

    formTemp?.splice(i, 1);
    touchTemp?.splice(i, 1);
    tempFieldAnotherState.splice(i,1)
    tempIndustryNameAnotherFieldState.splice(i,1)

    formik.setValues({ ...formik.values, riwayatPekerjaan: formTemp });
    formik.setTouched({ ...formik.touched, riwayatPekerjaan: touchTemp });
    setFieldAnotherState(tempFieldAnotherState)
    setIndustryNameAnotherFieldState(tempIndustryNameAnotherFieldState)
    if (formik.errors?.hasOwnProperty("riwayatPekerjaan")) {
      const errorTemp = [...formik.errors?.riwayatPekerjaan];
      errorTemp?.splice(i, 1);
      formik.setErrors({ ...formik.errors, riwayatPekerjaan: errorTemp });
    }
  };

  /************* start handle value for dropdown another industry name */
  const handleChangeVal= (event,index) =>{
    const tempFieldAnotherState = [...fieldAnotherState]
    const tempIndustryNameAnotherFieldState = [...industryNameAnotherFieldState]
    if(event.target.value === "Lainnya" ){
      tempIndustryNameAnotherFieldState[index] = true
      tempFieldAnotherState[index] = true;
      setFieldAnotherState(tempFieldAnotherState);
      setIndustryNameAnotherFieldState(tempIndustryNameAnotherFieldState)
    }else{
      tempFieldAnotherState[index] = false
      setFieldAnotherState(tempFieldAnotherState)
    }
   return formik.handleChange(event)
  }

  

  const handleVal = (index) =>{
      return formik.values.riwayatPekerjaan[index][riwayatPekerjaan.industri.name]
  }

  const handleValAnother = (index) =>{
    const tempIndustryNameAnotherFieldState = [...industryNameAnotherFieldState]
    if(industryNameAnotherFieldState[index]){
      formik.setFieldValue(
        `riwayatPekerjaan.${index}.${riwayatPekerjaan.industriKeterangan.name}`,""
      )
      tempIndustryNameAnotherFieldState[index]=false
      setIndustryNameAnotherFieldState(tempIndustryNameAnotherFieldState)
    }
    
    return formik.values.riwayatPekerjaan[index][riwayatPekerjaan.industriKeterangan.name]
  }
  /************* end handle value for dropdown another industry name */

  return (
    <Box>
      {formik.values.riwayatPekerjaan?.map((e, i) => (
        <Card className={classes.card} key={i}>
          <CardHeader
            className={i === 0 ? classes.header : classes.headerIsClose}
            title={
              <Box className={classes.headerArea}>
                <Typography className={classes.title}>
                  {title + " - " + (i + 1)}
                </Typography>

                <IconButton
                  className={classes.iconButtom}
                  onClick={() => onRemoveForm(i)}
                >
                  <Close className={classes.closeIcon} />
                </IconButton>
              </Box>
            }
          />
          <CardContent className={classes.content}>
            <Grid className={classes.container} container>
              <Grid className={classes.grid} item xs={12}>
                <Typography className={classes.label}>
                  {riwayatPekerjaan.namaPerusahaan.label}
                </Typography>               
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Nama Perusahaan"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatPekerjaan.${i}.${riwayatPekerjaan.namaPerusahaan.name}`}
                  value={
                    formik.values.riwayatPekerjaan[i][
                    riwayatPekerjaan.namaPerusahaan.name
                    ]
                  }
                  onChange={(formik.handleChange)} 
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatPekerjaan &&
                    formik.touched.riwayatPekerjaan &&
                    formik.touched?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.namaPerusahaan.name
                    ] &&
                    formik.errors?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.namaPerusahaan.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatPekerjaan &&
                      formik.touched.riwayatPekerjaan &&
                      formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.namaPerusahaan.name
                      ] &&
                      formik.touched.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.namaPerusahaan.name
                      ]
                      ? formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.namaPerusahaan.name
                      ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPekerjaan.industri.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  select
                  size="small"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatPekerjaan.${i}.${riwayatPekerjaan.industri.name}`}
                  value={handleVal(i)}
                  onChange ={event => handleChangeVal(event,i)}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatPekerjaan &&
                    formik.touched.riwayatPekerjaan &&
                    formik.touched?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.industri.name
                    ] &&
                    formik.errors?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.industri.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatPekerjaan &&
                      formik.touched.riwayatPekerjaan &&
                      formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.industri.name
                      ] &&
                      formik.touched.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.industri.name
                      ]
                      ? formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.industri.name
                      ]
                      : ""
                  }
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem disabled value="">
                    Pilih Industri
                  </MenuItem>
                  {dataIndustri.map((item) => (
                    <MenuItem key={item.industriId} value={item.industriName}>
                      {item.industriName}                    
                    </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPekerjaan.status.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  select
                  size="small"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatPekerjaan.${i}.${riwayatPekerjaan.status.name}`}
                  value={
                    formik.values.riwayatPekerjaan[i][
                    riwayatPekerjaan.status.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatPekerjaan &&
                    formik.touched.riwayatPekerjaan &&
                    formik.touched?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.status.name
                    ] &&
                    formik.errors?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.status.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatPekerjaan &&
                      formik.touched.riwayatPekerjaan &&
                      formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.status.name
                      ] &&
                      formik.touched.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.status.name
                      ]
                      ? formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.status.name
                      ]
                      : ""
                  }
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem disabled value="">
                    Pilih Status
                  </MenuItem>
                  <MenuItem value="Masih Bekerja">Masih Bekerja</MenuItem>
                  <MenuItem value="Sudah Tidak Bekerja">
                    Sudah Tidak Bekerja
                  </MenuItem>
                </TextField>
              </Grid>

              {/* for input textfield if industry name is another */}
              {fieldAnotherState[i]   && (
              
                <Grid item xs={12}>
                  <Grid className={classes.grid} item xs={6}>
                    <Typography className={classes.label}>
                      {riwayatPekerjaan.industriKeterangan.label}
                    </Typography>
                    <TextField
                      fullWidth
                      // hiddenLabel
                      size="small"
                      variant="filled"
                      placeholder="Masukkan Nama Industri"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      
                      name={`riwayatPekerjaan.${i}.${riwayatPekerjaan.industriKeterangan.name}`}
                      value={handleValAnother(i)}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.riwayatPekerjaan &&
                        formik.touched.riwayatPekerjaan &&
                        formik.touched?.riwayatPekerjaan[i]?.[
                        riwayatPekerjaan.industriKeterangan.name
                        ] &&
                        formik.errors?.riwayatPekerjaan[i]?.[
                        riwayatPekerjaan.industriKeterangan.name
                        ] &&
                        true
                      }
                      helperText={
                        formik.errors.riwayatPekerjaan &&
                          formik.touched.riwayatPekerjaan &&
                          formik.errors.riwayatPekerjaan[i]?.[
                          riwayatPekerjaan.industriKeterangan.name
                          ] &&
                          formik.touched.riwayatPekerjaan[i]?.[
                          riwayatPekerjaan.industriKeterangan.name
                          ]
                          ? formik.errors.riwayatPekerjaan[i]?.[
                          riwayatPekerjaan.industriKeterangan.name
                          ]
                          : ""
                      }
                    />
                  </Grid>
                </Grid>

              )}

              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPekerjaan.tahunMasuk.label}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    hiddenLabel
                    fullWidth
                    disableFuture
                    size="small"
                    variant="dialog"
                    inputVariant="filled"
                    format="yyyy"
                    placeholder="Pilih Tahun Masuk"
                    views={["year"]}
                    keyboardIcon={<ArrowDropDown />}
                    InputProps={{
                      style: { padding: 0 },
                      disableUnderline: true,
                      readOnly: true,
                    }}
                    onChange={(val) => {
                      formik.setFieldValue(
                        `riwayatPekerjaan.${i}.${riwayatPekerjaan.totalBekerja.name}`,
                        ""
                      );
                      formik.setFieldValue(
                        `riwayatPekerjaan.${i}.${riwayatPekerjaan.masihBerkerja.name}`,
                        false
                      );
                      formik.setFieldValue(
                        `riwayatPekerjaan.${i}.${riwayatPekerjaan.tahunAkhir.name}`,
                        null
                      );
                      formik.setFieldValue(
                        `riwayatPekerjaan.${i}.${riwayatPekerjaan.tahunMasuk.name}`,
                        moment(val).format("YYYY")
                      );
                    }}
                    name={`riwayatPekerjaan.${i}.${riwayatPekerjaan.tahunMasuk.name}`}
                    value={
                      formik.values.riwayatPekerjaan[i][
                      riwayatPekerjaan.tahunMasuk.name
                      ]
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.riwayatPekerjaan &&
                      formik.touched.riwayatPekerjaan &&
                      formik.touched?.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.tahunMasuk.name
                      ] &&
                      formik.errors?.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.tahunMasuk.name
                      ] &&
                      true
                    }
                    helperText={
                      formik.errors.riwayatPekerjaan &&
                        formik.touched.riwayatPekerjaan &&
                        formik.errors.riwayatPekerjaan[i]?.[
                        riwayatPekerjaan.tahunMasuk.name
                        ] &&
                        formik.touched.riwayatPekerjaan[i]?.[
                        riwayatPekerjaan.tahunMasuk.name
                        ]
                        ? formik.errors.riwayatPekerjaan[i]?.[
                        riwayatPekerjaan.tahunMasuk.name
                        ]
                        : ""
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPekerjaan.tahunAkhir.label}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    hiddenLabel
                    fullWidth
                    disabled={
                      formik.values.riwayatPekerjaan[i][
                        riwayatPekerjaan.tahunMasuk.name
                      ] === null ||
                      formik.values.riwayatPekerjaan[i][
                        riwayatPekerjaan.masihBerkerja.name]
                    }
                    disableFuture
                    minDate={
                      formik.values.riwayatPekerjaan[i][
                        riwayatPekerjaan.tahunMasuk.name
                      ] !== null &&
                      formik.values.riwayatPekerjaan[i][
                        riwayatPekerjaan.tahunMasuk.name
                      ]
                    }
                    size="small"
                    variant="dialog"
                    inputVariant="filled"
                    format="yyyy"
                    placeholder="Pilih Tahun Akhir"
                    views={["year"]}
                    keyboardIcon={<ArrowDropDown />}
                    InputProps={{
                      style: { padding: 0 },
                      disableUnderline: true,
                      readOnly: true,
                    }}
                    onChange={(val) => {
                      formik.setFieldValue(
                        `riwayatPekerjaan.${i}.${riwayatPekerjaan.masihBerkerja.name}`,
                        false
                      );
                      formik.setFieldValue(
                        `riwayatPekerjaan.${i}.${riwayatPekerjaan.totalBekerja.name}`,
                        parseInt(
                          moment(val).format("YYYY") -
                          formik.values.riwayatPekerjaan[i][
                            riwayatPekerjaan.tahunMasuk.name
                          ]
                        )
                      );
                      formik.setFieldValue(
                        `riwayatPekerjaan.${i}.${riwayatPekerjaan.tahunAkhir.name}`,
                        moment(val).format("YYYY")
                      );
                    }}
                    name={`riwayatPekerjaan.${i}.${riwayatPekerjaan.tahunAkhir.name}`}
                    value={
                      formik.values.riwayatPekerjaan[i][
                        riwayatPekerjaan.tahunAkhir.name
                      ]
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.riwayatPekerjaan &&
                      formik.touched.riwayatPekerjaan &&
                      formik.touched?.riwayatPekerjaan[i]?.[
                        riwayatPekerjaan.tahunAkhir.name
                      ] &&
                      formik.errors?.riwayatPekerjaan[i]?.[
                        riwayatPekerjaan.tahunAkhir.name
                      ] &&
                      true
                    }
                    helperText={
                      formik.errors.riwayatPekerjaan &&
                      formik.touched.riwayatPekerjaan &&
                      formik.errors.riwayatPekerjaan[i]?.[
                        riwayatPekerjaan.tahunAkhir.name
                      ] &&
                      formik.touched.riwayatPekerjaan[i]?.[
                        riwayatPekerjaan.tahunAkhir.name
                      ]
                        ? formik.errors.riwayatPekerjaan[i]?.[
                          riwayatPekerjaan.tahunAkhir.name
                        ]
                        : ""
                    }
                  />
                </MuiPickersUtilsProvider>

                <FormControlLabel
                  name={`riwayatPekerjaan.${i}.${riwayatPekerjaan.masihBerkerja.name}`}
                  value={
                    formik.values.riwayatPekerjaan[i][
                      riwayatPekerjaan.masihBerkerja.name
                    ]
                  }
                  disabled={
                    formik.values.riwayatPekerjaan[i][
                      riwayatPekerjaan.tahunMasuk.name
                    ] === null
                  }
                  onChange={(val) => {
                    const isChecked = val.target.checked;
                    formik.setFieldValue(
                      `riwayatPekerjaan.${i}.${riwayatPekerjaan.masihBerkerja.name}`,
                      isChecked
                    );
                    if (isChecked) {
                      formik.setFieldValue(
                        `riwayatPekerjaan.${i}.${riwayatPekerjaan.totalBekerja.name}`,
                        parseInt(
                          moment(new Date()).format("YYYY") -
                          formik.values.riwayatPekerjaan[i][
                            riwayatPekerjaan.tahunMasuk.name
                          ]
                        )
                      );
                      formik.setFieldValue(
                        `riwayatPekerjaan.${i}.${riwayatPekerjaan.tahunAkhir.name}`,
                        moment(new Date()).format("YYYY")
                      );
                    } else {
                      formik.setFieldValue(
                        `riwayatPekerjaan.${i}.${riwayatPekerjaan.totalBekerja.name}`,
                        ""
                      );
                      formik.setFieldValue(
                        `riwayatPekerjaan.${i}.${riwayatPekerjaan.tahunAkhir.name}`,
                        null
                      );
                    }
                  }}
                  onBlur={formik.handleBlur}
                  control={
                    <Checkbox
                      checked={
                        formik.values.riwayatPekerjaan[i][
                          riwayatPekerjaan.masihBerkerja.name
                        ]
                      }
                      color="secondary"
                    />
                  }
                  label="Hingga Saat Ini"
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPekerjaan.level.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  select
                  size="small"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatPekerjaan.${i}.${riwayatPekerjaan.level.name}`}
                  value={
                    formik.values.riwayatPekerjaan[i][
                    riwayatPekerjaan.level.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatPekerjaan &&
                    formik.touched.riwayatPekerjaan &&
                    formik.touched?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.level.name
                    ] &&
                    formik.errors?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.level.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatPekerjaan &&
                      formik.touched.riwayatPekerjaan &&
                      formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.level.name
                      ] &&
                      formik.touched.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.level.name
                      ]
                      ? formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.level.name
                      ]
                      : ""
                  }
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem disabled value="">
                    Pilih Level
                  </MenuItem>
                  {dataLevel.map((item) => (
                    <MenuItem key={item.levelId} value={item.levelName}>
                      {item.levelName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPekerjaan.jabatan.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Jabatan"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatPekerjaan.${i}.${riwayatPekerjaan.jabatan.name}`}
                  value={
                    formik.values.riwayatPekerjaan[i][
                    riwayatPekerjaan.jabatan.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatPekerjaan &&
                    formik.touched.riwayatPekerjaan &&
                    formik.touched?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.jabatan.name
                    ] &&
                    formik.errors?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.jabatan.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatPekerjaan &&
                      formik.touched.riwayatPekerjaan &&
                      formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.jabatan.name
                      ] &&
                      formik.touched.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.jabatan.name
                      ]
                      ? formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.jabatan.name
                      ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPekerjaan.gajiTerakhir.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Gaji"
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                  name={`riwayatPekerjaan.${i}.${riwayatPekerjaan.gajiTerakhir.name}`}
                  value={GlobalFunction.numberWithDot(
                    formik.values.riwayatPekerjaan[i][
                    riwayatPekerjaan.gajiTerakhir.name
                    ]
                  )}
                  onChange={(e) =>
                    numberValidation.test(
                      GlobalFunction.clearDotNumber(e.target.value)
                    ) && e.target.value !== ""
                      ? formik.setFieldValue(
                        `riwayatPekerjaan.${i}.${riwayatPekerjaan.gajiTerakhir.name}`,
                        parseInt(
                          GlobalFunction.clearDotNumber(e.target.value)
                        )
                      )
                      : formik.setFieldValue(
                        `riwayatPekerjaan.${i}.${riwayatPekerjaan.gajiTerakhir.name}`,
                        ""
                      )
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatPekerjaan &&
                    formik.touched.riwayatPekerjaan &&
                    formik.touched?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.gajiTerakhir.name
                    ] &&
                    formik.errors?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.gajiTerakhir.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatPekerjaan &&
                      formik.touched.riwayatPekerjaan &&
                      formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.gajiTerakhir.name
                      ] &&
                      formik.touched.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.gajiTerakhir.name
                      ]
                      ? formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.gajiTerakhir.name
                      ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPekerjaan.namaAtasan.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Nama Atasan"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatPekerjaan.${i}.${riwayatPekerjaan.namaAtasan.name}`}
                  value={
                    formik.values.riwayatPekerjaan[i][
                    riwayatPekerjaan.namaAtasan.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatPekerjaan &&
                    formik.touched.riwayatPekerjaan &&
                    formik.touched?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.namaAtasan.name
                    ] &&
                    formik.errors?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.namaAtasan.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatPekerjaan &&
                      formik.touched.riwayatPekerjaan &&
                      formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.namaAtasan.name
                      ] &&
                      formik.touched.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.namaAtasan.name
                      ]
                      ? formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.namaAtasan.name
                      ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPekerjaan.kontakAtasan.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Kontak Atasan"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: riwayatPekerjaan.kontakAtasan.maximum,
                  }}
                  name={`riwayatPekerjaan.${i}.${riwayatPekerjaan.kontakAtasan.name}`}
                  value={
                    formik.values.riwayatPekerjaan[i][
                    riwayatPekerjaan.kontakAtasan.name
                    ]
                  }
                  onChange={(e) =>
                    numberValidation.test(e.target.value) &&
                    formik.handleChange(e)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatPekerjaan &&
                    formik.touched.riwayatPekerjaan &&
                    formik.touched?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.kontakAtasan.name
                    ] &&
                    formik.errors?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.kontakAtasan.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatPekerjaan &&
                      formik.touched.riwayatPekerjaan &&
                      formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.kontakAtasan.name
                      ] &&
                      formik.touched.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.kontakAtasan.name
                      ]
                      ? formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.kontakAtasan.name
                      ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPekerjaan.kontakPerusahaan.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Kontak Perusahaan"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: riwayatPekerjaan.kontakPerusahaan.maximum,
                  }}
                  name={`riwayatPekerjaan.${i}.${riwayatPekerjaan.kontakPerusahaan.name}`}
                  value={
                    formik.values.riwayatPekerjaan[i][
                    riwayatPekerjaan.kontakPerusahaan.name
                    ]
                  }
                  onChange={(e) =>
                    numberValidation.test(e.target.value) &&
                    formik.handleChange(e)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatPekerjaan &&
                    formik.touched.riwayatPekerjaan &&
                    formik.touched?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.kontakPerusahaan.name
                    ] &&
                    formik.errors?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.kontakPerusahaan.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatPekerjaan &&
                      formik.touched.riwayatPekerjaan &&
                      formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.kontakPerusahaan.name
                      ] &&
                      formik.touched.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.kontakPerusahaan.name
                      ]
                      ? formik.errors.riwayatPekerjaan[i]?.[
                      riwayatPekerjaan.kontakPerusahaan.name
                      ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPekerjaan.totalBekerja.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Tahun"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatPekerjaan.${i}.${riwayatPekerjaan.totalBekerja.name}`}
                  disabled
                  value={
                    formik.values.riwayatPekerjaan[i][
                    riwayatPekerjaan.totalBekerja.name
                    ]
                  }
                  error={
                    formik.errors.riwayatPekerjaan &&
                    formik.touched.riwayatPekerjaan &&
                    formik.touched?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.totalBekerja.name
                    ] &&
                    formik.errors?.riwayatPekerjaan[i]?.[
                    riwayatPekerjaan.totalBekerja.name
                    ] &&
                    true
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
      {formik.values.riwayatPekerjaan.length < 1 && (
        <Typography
          align="center"
          className={classes.buttonAdd}
          color="secondary"
        >
          Dapat Dilewati Apabila Tidak Relevan
        </Typography>
      )}
      <Button
        className={classes.buttonAdd}
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={() => {
          onAddForm();
        }}
      >
        + Tambah {title}
      </Button>
    </Box>
  );
};

export default Form;
