import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { API } from "../../../service/axios";
import { Toast } from "../../Component/Notification";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "13vh 19vw",
    [theme.breakpoints.down(750)]: {
      padding: "5vw",
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.25vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "1rem",
    }
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: "0.7vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.5rem",
    }
  },
  card: {
    padding: "13vh 2.5vw",
    [theme.breakpoints.down(750)]: {
      padding: "inherit",
    },
  },
  divider: {
    width: "2.5vw",
    margin: "1vw auto",
    background: theme.palette.secondary.main,
    height: "0.3vw",
  },
  accordion: {
    backgroundColor: "#F3F4F5",
  },
  accordionTitle: {
    fontSize: "0.7vw", 
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.5rem",
    }
  },
  accordionSubtitle: { 
    fontSize: "0.7vw" ,
    [theme.breakpoints.down(750)]: {
      fontSize: "0.5rem",
    }
  },
}));

const FAQ = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState("");
  const [dataFaq, setDataFaq] = useState([]);
  const isLogin = localStorage.getItem("isLogin");

  useEffect(() => {
    if (isLogin) {
      getFaq("secured");
    } else {
      getFaq("open");
    }
  }, [isLogin]);

  const getFaq = (type) => {
    const url = type === "open" ? "footer.getFaqOpen" : "footer.getFaqSecured";

    API(`${url}`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataFaq(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data FAQ!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data FAQ!",
        });
      });
  };

  const handleChange = (panel) => (newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={1}
        >
          <Grid item>
            <Typography className={classes.title}>Butuh Bantuan?</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.subtitle}>
              Pertanyaan yang sering ditanyakan
            </Typography>
          </Grid>
          <Grid item>
            <Divider className={classes.divider} />
          </Grid>
          <Grid container direction="column" spacing={0} item>
            {dataFaq?.map((item, idx) => (
              <Grid key={`faq-item-${idx}`} item>
                <Accordion
                  id={`accordion-${idx}`}
                  expanded={expanded === `panel-${idx}`}
                  onChange={handleChange(`panel-${idx}`)}
                  className={classes.accordion}
                  style={
                    idx === 0
                      ? { borderRadius: "8px 8px 0px 0px" }
                      : idx === dataFaq.length - 1
                      ? { borderRadius: "0px 0px 8px 8px" }
                      : { borderRadius: 0 }
                  }
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography className={classes.accordionTitle}>
                      {item.faqQuestion}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.accordionSubtitle}>
                      {item.faqAnswer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default FAQ;
