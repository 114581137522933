import React, { useEffect, useState } from "react";
import { makeStyles, Box, Grid, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { API } from "../../../service/axios";
import { Toast } from "../../Component/Notification";
import { config } from "../../../config";
import GlobalFunction from "../../../GlobalFunction";

const useStyles = makeStyles((theme) => ({
  banner: {
    height: "30vw",
  },
  decoration: {
    position: "absolute",
    left: 0,
    height: "30vw",
  },
  people: {
    position: "absolute",
    right: 0,
    height: "30vw",
  },
  bannerPadding: {
    padding: "5vw 14vw",
  },
  index: {
    zIndex: 1,
    display: "flex",
  },
  bannerTitle: {
    color: theme.palette.primary.main,
    fontSize: "3.5vw",
    fontWeight: "bold",
  },
  bannerDesc: {
    color: theme.palette.primary.main,
    fontSize: "1.1vw",
    margin: "1vw 0 2vw 0",
    
  },
  buttonDaftar: {
    fontSize: "0.9vw",
    padding: "1vw 3vw",
    marginRight: "1vw",
  },
  buttonLowongan: {
    fontSize: "0.9vw",
    padding: "1vw",
  },
  buttonKategori: {
    fontSize: "0.9vw",
    padding: "1vw",
    marginRight: "1vw",
    
  },
}));

const Banner = ({ innerRef }) => {
  const classes = useStyles();
  const history = useHistory();
  const [dataHero, setDataHero] = useState({});
  const [jalurKhusus, setJalurKhusus] = useState(false);
  const isLogin = localStorage.getItem("isLogin");
  const [message, setMessage] = useState("Success");
  const [dataCategory, setDataCategory] = useState([]);
  const [dataSetting, setDataSetting] = useState([]);
  const [isStartYourCareerClicked, setIsStartYourCareerClicked] = useState(true)

  useEffect(() => {
    if (isLogin) {
      getHome("secured");
      getDataCategory("secured");
      getJalurKhusus();
      getSetting();
    } else {
      getHome("open");
      getDataCategory("open");
    }
  }, [isLogin]);

  const getHome = (type) => {
    const url = type === "open" ? "home.getHeroOpen" : "home.getHeroSecured";

    API(`${url}`)
      .then((res) => {
        setMessage(res.data?.Message);
        if (res.status === 200 && res.data?.Message === "Success" || res.data?.Message === "Not Found") {
          setDataHero(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data Hero!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data Hero!",
        });
      });
  };

  const getJalurKhusus = (type) => {
    API(`validate.getJalurKhusus`)
      .then((res) => {
        if (res.status === 200) {
          setJalurKhusus(res.data.isProgramKhususAvailable);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat cek jalur khusus!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat cek jalur khusus!",
        });
      });
  };

  const getDataCategory = (type) => {
    const url =
      type === "open"
        ? "home.getLovCategoryOpen"
        : "home.getLovCategorySecured";

    API(`${url}`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataCategory(res.data?.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: res?.data?.message || "Gagal mendapat data kategori!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title:
            res?.response?.data?.message || "Gagal mendapat data kategori!",
        });
      });
  };

  const getSetting = () => {
    API('home.getSetting').then(res => {
      if (res?.data?.Message === 'Success') {
        setDataSetting(res?.data?.Data || [])
      }
    }).catch(err => {
      console.error(err);
    })
  }

  return (
    <div>
      {message.toLowerCase() !== "not found" && (
        <Box className={classes.banner}>
          <img
            className={classes.decoration}
            alt="logo"
            src={process.env.PUBLIC_URL + "/images/home/decoration.png"}
          />
          {dataHero.heroSectionBannerPath &&
            <img
              className={classes.people}
              alt={dataHero.heroSectionBannerName || "logo"}
              src={`${config.BASE_URL}${dataHero.heroSectionBannerPath}`}
              onError={GlobalFunction.handleImageError}
            />
          }
          <Grid className={classes.bannerPadding} container>
            <Grid className={classes.index} item xs={6}>
              <Typography className={classes.bannerTitle}>
                {dataHero.heroSectionTitle || "Temukan Karir Impianmu Sekarang"}
              </Typography>
            </Grid>
            <Grid className={classes.index} item xs={12}>
              <Typography className={classes.bannerDesc}>
                {dataHero.heroSectionSubtitle ||
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
              </Typography>
            </Grid>
            {!isStartYourCareerClicked ?
              (isLogin ?
                (<Grid className={classes.index} item xs={12}>
                  {dataSetting.map((item, index) => (                    
                    <Button
                      key={index}
                      className={classes.buttonKategori}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setTimeout(() => {
                          localStorage.setItem("categoryApplicant",item.categoryId)
                          innerRef[
                            "lowongan".toLowerCase()
                          ].current.scrollIntoView({
                            behavior: "smooth",
                          });
                        }, 100);
                        // if (
                        //   localStorage.getItem("submitValidation") === "false"
                        // ) {
                        //   history.push(`/formulir-kerja/${item.categoryId}`);
                        // } else {
                        //   history.push(`/formulir-kerja/${item.categoryId}`);
                        //   Toast.fire({
                        //     icon: "info",
                        //     title:
                        //       "Anda hanya bisa melakukan lamaran 1 tahun sekali",
                        //   });
                        // }
                      }}
                      disabled={item.categoryId === 3 ? item.status ? !jalurKhusus : !item.status : !item.status}
                    >
                      {item.valueSetting}
                    </Button>
                  ))}
                </Grid>
                ) : (
                  <Grid className={classes.index} item xs={12}>
                  {dataCategory.map((item, index) => (                  
                    <Button
                      key={index}
                      className={classes.buttonKategori}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setTimeout(() => {
                          localStorage.setItem("categoryApplicant",item.categoryId)
                          innerRef[
                            "lowongan".toLowerCase()
                          ].current.scrollIntoView({
                            behavior: "smooth",
                          });
                        }, 100);
                      }}
                    >
                      {item.categoryName}
                    </Button>
                  ))}
                </Grid>
                )
              ) : (
                <Grid className={classes.index} item xs={12}>
                  <Button
                  className={classes.buttonKategori}
                  variant="contained"
                  color="secondary"
                  onClick={() => {setIsStartYourCareerClicked(!isStartYourCareerClicked)}}
                >
                  Awali Karirmu
                </Button>
                </Grid>
              )}
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default Banner;
