import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  makeStyles,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  TableRow,
  TableCell,
  Box,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import GlobalFunction from "../../../GlobalFunction";
import { API } from "../../../service/axios";
import { Toast } from "../../Component/Notification";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.1vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
    }
  },
  content: {
    padding: "3vh 2.5vw",
    [theme.breakpoints.down(550)]: {
      padding: "3vh",
    },
  },
  accordion: {
    borderRadius: 8,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
  },
  subtitleLabel: {
    color: "#005A7D",
    marginBottom: 8,
    // fontSize: "0.625vw",
    fontSize: "0.825vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.7rem",
    }
  },
  contentLabel: {
    color: "#011627",
    fontWeight: "bold",
    // fontSize: "0.7vw",
    fontSize: "0.9vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.7rem",
    }
  },
  sizeFormHide1: {
    [theme.breakpoints.down(550)]: {
      display: "none",
    },

  },
  sizeFormHide2: {
    [theme.breakpoints.up(550)]: {
      display: "none",
    },
  },
}));

const InformasiLamaranKerja = ({ data }) => {
  const classes = useStyles();
  const initialParams = {
    applicantId: localStorage.getItem("applicantId"),
    size: 10,
    page: 0,
    sort: "",
  };
  const [dataLamaranKerja, setDataLamaranKerja] = useState({ Data: [] });
  const [params, setParams] = useState(initialParams);
  const [index, setIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const getLamaranKerja = useCallback(() => {
    setLoading(true);
    API(`lamaranKerja.getLamaranKerja`, {
      params,
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200 || res.data?.Message === "Success") {
          setDataLamaranKerja(res.data);
        }
      })
      .catch((res) => {
        setLoading(false);
        Toast.fire({
          icon: "warning",
          title:
            res.response?.data?.Message || "Gagal mendapat data lamaran kerja!",
        });
      });
  }, [params]);

  useEffect(() => {
    getLamaranKerja();
  }, [getLamaranKerja]);

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        id="detail-informasi-lamaran"
        className={classes.content}
      >
        <Typography className={classes.title}>
          Informasi Lamaran Kerja
        </Typography>
      </AccordionSummary>
      <Grid direction="column" container>
        <Divider />
        {data?.map((item, i) => (
        <Box key={`informasi-lamaran-${i}`} className={classes.sizeFormHide1}>
          <Divider />
            <AccordionDetails className={classes.content}>
        
                    <React.Fragment key={i}>
              <Grid container spacing={5} className={classes.sizeFormHide1}>  
                  <Grid item xs={4}>
                      <Typography variant="subtitle2" className={classes.subtitleLabel}>
                        Posisi Kerja
                      </Typography>
                      <Typography variant="subtitle1" className={classes.contentLabel}>
                        {item.positionName || "-"}
                      </Typography>
                    
                  </Grid>
              
                <Grid item xs={4}>
                  <Typography variant="subtitle2" className={classes.subtitleLabel}>
                    Harapan Gaji
                  </Typography>
                  <Typography variant="subtitle1" className={classes.contentLabel}>
                    {item?.othersExpectSalary
                      ? `Rp ${GlobalFunction.numberWithDot(
                          item.othersExpectSalary
                        )}`
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle2" className={classes.subtitleLabel}>
                    Penempatan yang Diminati
                  </Typography>
                  <Typography variant="subtitle1" className={classes.contentLabel}>
                    {item?.othersPreferredPlacement || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle2" className={classes.subtitleLabel}>
                    Status Pencari Kerja
                  </Typography>
                  <Typography variant="subtitle1" className={classes.contentLabel}>
                    {item?.othersJobSeekerStatus || "-"}
                  </Typography>
                </Grid>
              </Grid>
              </React.Fragment>
            </AccordionDetails>
        </Box>
          ))}  
                 {data?.map((item, i) => (
        <Box key={`informasi-lamaran-${i}`} className={classes.sizeFormHide2}>
          <Divider />
        <AccordionDetails className={classes.content}>
          {/* <React.Fragment key={i}>     */}
          <Grid container spacing={5} className={classes.sizeFormHide2}>
          {dataLamaranKerja?.Data?.map((row, i) => (
              <Grid item xs={6}>
                <React.Fragment key={i}>
                  <Typography variant="subtitle2" className={classes.subtitleLabel}>
                    Posisi Kerja
                  </Typography>
                  <Typography variant="subtitle1" className={classes.contentLabel}>
                    {row.positionName || "-"}
                  </Typography>
                </React.Fragment>
              </Grid>
            ))}
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.subtitleLabel}>
                Harapan Gaji
              </Typography>
              <Typography variant="subtitle1" className={classes.contentLabel}>
                {data?.othersExpectSalary
                  ? `Rp ${GlobalFunction.numberWithDot(
                      data.othersExpectSalary
                    )}`
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.subtitleLabel}>
                Penempatan yang Diminati
              </Typography>
              <Typography variant="subtitle1" className={classes.contentLabel}>
                {data?.othersPreferredPlacement || "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.subtitleLabel}>
                Status Pencari Kerja
              </Typography>
              <Typography variant="subtitle1" className={classes.contentLabel}>
                {data?.othersJobSeekerStatus || "-"}
              </Typography>
            </Grid>
          </Grid>
          {/* </React.Fragment> */}
        </AccordionDetails>
        </Box>
          ))}   
      </Grid>
    </Accordion>
  );

};

export default InformasiLamaranKerja;
