import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  InputAdornment,
  IconButton,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import { Event, Visibility, VisibilityOff } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import formInitialValues from "../../../GlobalComponent/FormModel/formInitialValues";
import validationSchema from "../../../GlobalComponent/FormModel/validationSchema";
import formModel from "../../../GlobalComponent/FormModel/formModel";
import { Toast } from "../../Component/Notification";
import moment from "moment";
import { API } from "../../../service/axios";
import GlobalFunction from "../../../GlobalFunction";
import { Autocomplete } from "@material-ui/lab";
import * as Yup from "yup";

const { identitasDiri, register } = formModel;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5vw 0",
   
  },
  header: {
    padding: "2.5vw 0 0 0",
    color: theme.palette.primary.main,
    [theme.breakpoints.down(750)]: {
      padding: "5vw 0 2.5vw 0",
    },
    [theme.breakpoints.down(550)]: {
      padding: "7vw 0 2.5vw 0",
    },
  },
  title: {
    // fontSize: "1.875vw",
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  cardActionsRoot: {
    padding: 24,
    justifyContent: "center",
  },
  container: {
    padding: "2.5vw",
    borderBottom: "1px solid #DDDDDD",
    [theme.breakpoints.down(750)]: {
      padding: "6vw",
    }
  },
  grid: {
    padding: "1vw",
  },
  label: {
    // fontSize: "0.8vw",
    fontSize: "1rem",
    textAlign: "left",
    marginBottom: "0.4vw",
  },
  upload: {
    display: "flex",
    height: "40px",
    flexGrow: 1,
  },
  buttonUpload: {
    fontSize: "0.9vw",
    fontWeight: "bold",
    height: "100%",
    paddingLeft: "2vw",
    paddingRight: "2vw",
    [theme.breakpoints.down(1200)]: {
      fontSize: "0.7rem",
    },
    [theme.breakpoints.down(750)]: {
      fontSize: "0.7rem",
      padding: "0 5vw",
    }
  },
  textUpload: {
    fontSize: "0.9vw",
    margin: "auto",
    marginLeft: "1vw",
    wordBreak: "break-all",
  },
  boxUpload: {
    flexGrow: 1,
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  buttonLogin: {
    height: 40,
    fontSize: "0.9vw",
    [theme.breakpoints.down(1200)]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "0.9rem",
      padding: "0 5vw",
    }
  },
  gridBottom: {
    padding: "1vw",
    textAlign: "center",
  },
  labelBottom: {
    color: theme.palette.primary.main,
    fontSize: "1vw",
    fontWeight: "bold",
    display: "inline",
    // cursor: "pointer",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.8rem",
    }
  },
  labelBottom2: {
    color: theme.palette.secondary.main,
    fontSize: "1vw",
    fontWeight: "bold",
    display: "inline",
    cursor: "pointer",
    textDecoration: "underline",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.8rem",
    }
    
  },
  containerRegis: {
    maxWidth: "400px",
    margin: "0 auto",
    padding: "20px",
    // [theme.breakpoints.down(750)]: {
    //   // padding: "20%",
    //   maxWidth: "100%",
    //   padding: "5vh",
    // },
    [theme.breakpoints.down(750)]: {
      // padding: "20%",
      maxWidth: "100%",
      padding: "5vh 10vh 0",
    },
    // [theme.breakpoints.down(630)]: {
    //   // padding: "20%",
    //   maxWidth: "100%",
    //   padding: "0 10vh 10vh",
    // },
    [theme.breakpoints.down(600)]: {
      // padding: "20%",
      maxWidth: "100%",
      padding: "0 5vh",
    },
    [theme.breakpoints.down(450)]: {
      // padding: "20%",
      maxWidth: "100%",
      padding: "0 5vh",
    }
  },
  spasiEmail:{
    marginBottom: "1vw",
  }
}));

const Register = (props) => {
  const classes = useStyles();
  const history = useHistory();
  // const numberValidation = /^[0-9]*$/;
  const [visibility, setVisibility] = useState([true, true]);
  const [lovCity, setLovCity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validateEmail, setValidateEmail] = useState({
    validate: false,
    email: "",
  });

  useEffect(() => {
    getLovCity();
  }, []);

  const getLovCity = () => {
    API(`register.getLovCity`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setLovCity(res.data?.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data kota!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data kota!",
        });
      });
  };

  const uploadKtp = (dataImage) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", dataImage);
    API(`register.uploadKtp`, { data: formData })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data?.Status === "ACCEPTED") {
            formik.setFieldValue(
              identitasDiri.fileKtp.name,
              res.data?.FilePath
            );
          }
          Toast.fire({
            icon: "success",
            title: res.data?.Message || "Upload KTP berhasil!",
          });
        }
      })
      .catch((res) => {
        setLoading(false);
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Upload KTP gagal! File Max 3 MB",
        });
      });
  };

  const createRegister = (data) => {
    setLoading(true);
    API(`register.createRegister`, { data })
      .then((res) => {
        setLoading(false);
        // console.log(res);
        if (res.status === 200) {
          Toast.fire({
            icon: "success",
            title: res.data?.Message,
          });
          history.push("/login");
        } else {
          Toast.fire({
            icon: "info",
            title: res.data?.Message,
          });
        }
      })
      .catch((res) => {
        setLoading(false);
        // console.log(res);
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Registrasi gagal!",
        });
      });
  };

  const formik = useFormik({
    initialValues: formInitialValues.identitasDiriDaftar,
    validationSchema: validationSchema.identitasDiriDaftar, 
    onSubmit: (values, { setSubmitting, resetForm }) => {
      var dataSubmit = {
        ...values,
      };
      delete dataSubmit[`${identitasDiri.konfirmasiPassword.name}`];
      setSubmitting(true);
      createRegister(dataSubmit);
      setSubmitting(false);
    },
  });

  const validatingEmail = () => {
    setLoading(true);
    let data = {
      applicantName: formik.values[`${identitasDiri.namaLengkap.name}`],
      applicantEmail: formik.values[`${identitasDiri.email.name}`],
    };
    API(`register.validateEmail`, { data })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setValidateEmail({
            validate: true,
            email: formik.values[`${identitasDiri.email.name}`],
          });
          Toast.fire({
            icon: "success",
            title:
              res.data?.Message ||
              "Kode unik berhasil dikirim! Silahkan periksa E-mail Anda",
          });
        }
      })
      .catch((res) => {
        setLoading(false);
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Mengirim kode unik gagal!",
        });
      });
  };

  return (
    <Box className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid className={classes.containerRegis}>
          <Card>
            <CardHeader
              className={classes.header}
              title={
                <Typography className={classes.title}>Daftar Akun</Typography>
              }
            />
            <CardContent style={{ padding: 0 }}>
              <form onSubmit={formik.handleSubmit}>
                <Grid className={classes.container} container spacing={2}>
                  <Grid className={classes.grid} item xs={12} >
                    <Typography className={classes.label}>
                      {identitasDiri.namaLengkap.label}
                    </Typography>
                    <TextField
                      fullWidth
                      hiddenLabel
                      size="small"
                      variant="filled"
                      placeholder="Nama Sesuai KTP"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name={identitasDiri.namaLengkap.name}
                      value={formik.values[`${identitasDiri.namaLengkap.name}`]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      error={
                        formik.touched[`${identitasDiri.namaLengkap.name}`] &&
                        formik.errors[`${identitasDiri.namaLengkap.name}`] &&
                        true
                      }
                      helperText={
                        formik.errors[`${identitasDiri.namaLengkap.name}`] &&
                        formik.touched[`${identitasDiri.namaLengkap.name}`]
                          ? formik.errors[`${identitasDiri.namaLengkap.name}`]
                          : ""
                      }
                    />
                  </Grid>
                  <Grid className={classes.grid} item xs={12}>
                    <FormControl
                      error={
                        formik.touched[`${identitasDiri.jenisKelamin.name}`] &&
                        formik.errors[`${identitasDiri.jenisKelamin.name}`] &&
                        true
                      }
                    >
                      <Typography className={classes.label}>
                        {identitasDiri.jenisKelamin.label}
                      </Typography>
                      <RadioGroup
                        row
                        name={identitasDiri.jenisKelamin.name}
                        value={
                          formik.values[`${identitasDiri.jenisKelamin.name}`]
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Laki-Laki"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Perempuan"
                        />
                      </RadioGroup>
                      <FormHelperText>
                        {formik.errors[`${identitasDiri.jenisKelamin.name}`] &&
                        formik.touched[`${identitasDiri.jenisKelamin.name}`]
                          ? formik.errors[`${identitasDiri.jenisKelamin.name}`]
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid className={classes.grid} item xs={12}>
                    <Typography className={classes.label}>
                      {identitasDiri.nik.label}
                    </Typography>
                    <TextField
                      fullWidth
                      hiddenLabel
                      size="small"
                      variant="filled"
                      placeholder="Nomor Induk Kependudukan"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name={identitasDiri.nik.name}
                      value={formik.values[`${identitasDiri.nik.name}`]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      error={
                        formik.touched[`${identitasDiri.nik.name}`] &&
                        formik.errors[`${identitasDiri.nik.name}`] &&
                        true
                      }
                      helperText={
                        formik.errors[`${identitasDiri.nik.name}`] &&
                        formik.touched[`${identitasDiri.nik.name}`]
                          ? formik.errors[`${identitasDiri.nik.name}`]
                          : ""
                      }
                    />
                  </Grid>
                  <Grid className={classes.grid} item xs={12}>
                    <FormControl
                      error={
                        formik.touched[`${identitasDiri.fileKtp.name}`] &&
                        formik.errors[`${identitasDiri.fileKtp.name}`] &&
                        true
                      }
                    >
                      <Typography className={classes.label}>
                        {identitasDiri.fileKtp.label}
                      </Typography>
                      <Box className={classes.upload}>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="upload-file"
                          type="file"
                          onChange={(val) => uploadKtp(val.target.files[0])}
                          name={identitasDiri.fileKtp.name}
                        />
                        <label htmlFor="upload-file">
                          <Button
                            className={classes.buttonUpload}
                            variant="outlined"
                            color="secondary"
                            component="span"
                            disabled={loading}
                          >
                            {loading ? (
                              <i className="fas fa-circle-notch fa-spin" />
                            ) : (
                              ""
                            )}
                            Upload
                          </Button>
                        </label>
                        <Typography className={classes.textUpload}>
                          {GlobalFunction.getFileName(
                            formik.values[`${identitasDiri.fileKtp.name}`]
                          )}
                        </Typography>
                      </Box>
                      <FormHelperText>
                        {formik.touched[`${identitasDiri.fileKtp.name}`] &&
                        formik.errors[`${identitasDiri.fileKtp.name}`]
                          ? formik.errors[`${identitasDiri.fileKtp.name}`]
                          : ""}
                      </FormHelperText>
                      <FormHelperText error>
                        Maksimal file 3MB
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid className={classes.grid} item xs={12}>
                    <Typography className={classes.label}>
                      {identitasDiri.tempatLahir.label}
                    </Typography>
                    <Autocomplete
                      options={lovCity}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          fullWidth
                          size="small"
                          onBlur={formik.handleBlur}
                          disabled={formik.isSubmitting}
                          name={identitasDiri.tempatLahir.nameRegister}
                          error={
                            formik.touched[
                              `${identitasDiri.tempatLahir.nameRegister}`
                            ] &&
                            formik.errors[
                              `${identitasDiri.tempatLahir.nameRegister}`
                            ] &&
                            true
                          }
                          helperText={
                            formik.errors[
                              `${identitasDiri.tempatLahir.nameRegister}`
                            ] &&
                            formik.touched[
                              `${identitasDiri.tempatLahir.nameRegister}`
                            ]
                              ? formik.errors[
                                  `${identitasDiri.tempatLahir.nameRegister}`
                                ]
                              : ""
                          }
                          label="Pilih Tempat Lahir"
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                        />
                      )}
                      getOptionLabel={(option) => option.cityName}
                      value={
                        lovCity?.find(
                          (item) =>
                            item.cityId ===
                            formik.values[
                              `${identitasDiri.tempatLahir.nameRegister}`
                            ]
                        ) || null
                      }
                      onChange={(_event, newOption) => {
                        formik.setFieldValue(
                          identitasDiri.tempatLahir.nameRegister,
                          newOption?.cityId || null
                        );
                      }}
                    />
                  </Grid>
                  <Grid className={classes.grid} item xs={12}>
                    <Typography className={classes.label}>
                      {identitasDiri.tanggalLahir.label}
                    </Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        hiddenLabel
                        fullWidth
                        // maximal umur 17 tahun
                        maxDate={moment().subtract(17, 'year')}
                        disableFuture
                        size="small"
                        variant="dialog"
                        inputVariant="filled"
                        format="MM/dd/yyyy"
                        placeholder="MM/DD/YYYY"
                        keyboardIcon={<Event className={classes.icon} />}
                        InputProps={{
                          style: { padding: 0 },
                          disableUnderline: true,
                        }}
                        name={identitasDiri.tanggalLahir.name}
                        value={
                          formik.values[`${identitasDiri.tanggalLahir.name}`]
                        }
                        onChange={(val) =>
                          formik.setFieldValue(
                            identitasDiri.tanggalLahir.name,
                            moment(val).format("YYYY-MM-DD")
                          )
                        }
                        onBlur={formik.handleBlur}
                        disabled={formik.isSubmitting}
                        error={
                          formik.touched[
                            `${identitasDiri.tanggalLahir.name}`
                          ] &&
                          formik.errors[`${identitasDiri.tanggalLahir.name}`] &&
                          true
                        }
                        helperText={
                          formik.errors[`${identitasDiri.tanggalLahir.name}`] &&
                          formik.touched[`${identitasDiri.tanggalLahir.name}`]
                            ? formik.errors[
                                `${identitasDiri.tanggalLahir.name}`
                              ]
                            : ""
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid className={classes.grid} item xs={12}>
                    <Typography className={classes.label}>
                      {identitasDiri.email.label}
                    </Typography>
                    <TextField
                      className={classes.spasiEmail}
                      fullWidth
                      hiddenLabel
                      size="small"
                      variant="filled"
                      placeholder="Alamat Email"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name={identitasDiri.email.name}
                      value={formik.values[`${identitasDiri.email.name}`]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      error={
                        formik.touched[`${identitasDiri.email.name}`] &&
                        formik.errors[`${identitasDiri.email.name}`] &&
                        true
                      }
                      helperText={
                        formik.errors[`${identitasDiri.email.name}`] &&
                        formik.touched[`${identitasDiri.email.name}`]
                          ? formik.errors[`${identitasDiri.email.name}`]
                          : ""
                      }
                    />
                    <Button
                      className={classes.buttonLogin}
                      variant="outlined"
                      color="secondary"
                      component="span"
                      style={{ marginTop: "0.4vw" }}
                      disabled={
                        (validateEmail.validate &&
                          validateEmail.email ===
                            formik.values[`${identitasDiri.email.name}`]) ||
                        (formik.errors[`${identitasDiri.email.name}`] &&
                          formik.touched[`${identitasDiri.email.name}`]) ||
                        formik.values[`${identitasDiri.email.name}`] === "" ||
                        loading
                      }
                      onClick={() => validatingEmail()}
                    >
                      {loading ? (
                        <i className="fas fa-circle-notch fa-spin" />
                      ) : (
                        ""
                      )}{" "}
                      Validasi E-mail
                    </Button>
                  </Grid>
                  <Grid className={classes.grid} item xs={12}>
                    <Typography className={classes.label}>
                      {register.kodeUnik.label}
                    </Typography>
                    <TextField
                      fullWidth
                      hiddenLabel
                      size="small"
                      variant="filled"
                      placeholder="Kode Unik"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name={register.kodeUnik.name}
                      value={formik.values[`${register.kodeUnik.name}`]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      error={
                        formik.touched[`${register.kodeUnik.name}`] &&
                        formik.errors[`${register.kodeUnik.name}`] &&
                        true
                      }
                      helperText={
                        formik.errors[`${register.kodeUnik.name}`] &&
                        formik.touched[`${register.kodeUnik.name}`]
                          ? formik.errors[`${register.kodeUnik.name}`]
                          : ""
                      }
                    />
                  </Grid>
                  <Grid className={classes.grid} item xs={12}>
                    <Typography className={classes.label}>
                      {identitasDiri.nomorHandphone.label1}
                    </Typography>
                    <TextField
                      fullWidth
                      hiddenLabel
                      size="small"
                      variant="filled"
                      placeholder="Nomor Handphone"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputProps={{
                        maxLength: identitasDiri.nomorHandphone.maximum,
                      }}
                      name={identitasDiri.nomorHandphone.name}
                      value={
                        formik.values[`${identitasDiri.nomorHandphone.name}`]
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      error={
                        formik.touched[
                          `${identitasDiri.nomorHandphone.name}`
                        ] &&
                        formik.errors[`${identitasDiri.nomorHandphone.name}`] &&
                        true
                      }
                      helperText={
                        formik.errors[`${identitasDiri.nomorHandphone.name}`] &&
                        formik.touched[`${identitasDiri.nomorHandphone.name}`]
                          ? formik.errors[
                              `${identitasDiri.nomorHandphone.name}`
                            ]
                          : ""
                      }
                    />
                  </Grid>
                  <Grid className={classes.grid} item xs={12}>
                    <Typography className={classes.label}>
                      {identitasDiri.password.label}
                    </Typography>
                    <TextField
                      fullWidth
                      hiddenLabel
                      size="small"
                      variant="filled"
                      type={visibility[0] ? "password" : "text"}
                      placeholder="Masukkan Password"
                      InputProps={{
                        style: { padding: 0 },
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setVisibility([!visibility[0], visibility[1]])
                              }
                            >
                              {visibility[0] ? (
                                <Visibility className={classes.icon} />
                              ) : (
                                <VisibilityOff className={classes.icon} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      name={identitasDiri.password.name}
                      value={formik.values[`${identitasDiri.password.name}`]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      error={
                        formik.touched[`${identitasDiri.password.name}`] &&
                        formik.errors[`${identitasDiri.password.name}`] &&
                        true
                      }
                      helperText={
                        formik.errors[`${identitasDiri.password.name}`] &&
                        formik.touched[`${identitasDiri.password.name}`]
                          ? formik.errors[`${identitasDiri.password.name}`]
                          : ""
                      }
                    />
                  </Grid>
                  <Grid className={classes.grid} item xs={12}>
                    <Typography className={classes.label}>
                      {identitasDiri.konfirmasiPassword.label}
                    </Typography>
                    <TextField
                      fullWidth
                      hiddenLabel
                      size="small"
                      variant="filled"
                      type={visibility[1] ? "password" : "text"}
                      placeholder="Masukkan Password"
                      InputProps={{
                        style: { padding: 0 },
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setVisibility([visibility[0], !visibility[1]])
                              }
                            >
                              {visibility[1] ? (
                                <Visibility className={classes.icon} />
                              ) : (
                                <VisibilityOff className={classes.icon} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      name={identitasDiri.konfirmasiPassword.name}
                      value={
                        formik.values[
                          `${identitasDiri.konfirmasiPassword.name}`
                        ]
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      error={
                        formik.touched[
                          `${identitasDiri.konfirmasiPassword.name}`
                        ] &&
                        formik.errors[
                          `${identitasDiri.konfirmasiPassword.name}`
                        ] &&
                        true
                      }
                      helperText={
                        formik.errors[
                          `${identitasDiri.konfirmasiPassword.name}`
                        ] &&
                        formik.touched[
                          `${identitasDiri.konfirmasiPassword.name}`
                        ]
                          ? formik.errors[
                              `${identitasDiri.konfirmasiPassword.name}`
                            ]
                          : ""
                      }
                    />
                  </Grid>
                  <Grid className={classes.grid} item xs={12}>
                    <Button
                      className={classes.buttonLogin}
                      fullWidth
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? (
                        <i className="fas fa-circle-notch fa-spin" />
                      ) : (
                        ""
                      )}
                      Daftar
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <Grid className={classes.container} container>
                <Grid className={classes.gridBottom} item xs={12}>
                  <Typography className={classes.labelBottom}>
                    Sudah Daftar?&nbsp;
                  </Typography>
                  <Typography
                    className={classes.labelBottom2}
                    onClick={() => history.push("/login")}
                  >
                    Login
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Register;
