import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import GlobalFunction from "../../../GlobalFunction";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: "3vw",
    borderBottom: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: "1.1vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
    }
  },
  content: {
    padding: 0,
    "&&:last-child": {
      paddingBottom: 0,
    },
  },
  container: {
    padding: "2vw",
    borderBottom: "1px solid #DDDDDD",
  },
  grid: {
    padding: "1vw",
  },
  gridBottom: {
    padding: "0 1vw",
  },
  label: {
    fontSize: "0.8vw",
    textAlign: "left",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.8rem",
    }
  },
}));

const Form = ({ title, formik, informasiLamaran, data }) => {
  const classes = useStyles();
  const numberValidation = /^[0-9]*$/;
  const [dataPekerjaan, setDataPekerjaan] = useState([]);

  useEffect(() => {
    getDataPekerjaan();
    const informasiLamaran = {
      confIsCorrectStatement: false,
      othersExpectSalary: "",
      othersJobSeekerStatus : "",
      othersPreferredPlacement: data.location.state.data.location.locationName,
      positionId: data.location.state.data.position.positionId
    }
    formik.setValues({ ...formik.values, informasiLamaran: informasiLamaran });
  }, []);

  const getDataPekerjaan = () => {
    API(`formulirKerja.getLovPekerjaan`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataPekerjaan(res.data.Data);
          // console.log(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: res.data?.Message || "Gagal mendapat data posisi pekerjaan!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title:
            res.response?.data?.Message ||
            "Gagal mendapat data lembaga posisi pekerjaan!",
        });
      });
  };

  return (
    <Card>
      <CardHeader
        className={classes.header}
        title={<Typography className={classes.title}>{title}</Typography>}
      />
      <CardContent className={classes.content}>
        <Grid className={classes.container} container>
          <Grid className={classes.grid} item xs={12}>
            <Typography className={classes.label}>
              {informasiLamaran.posisiPekerjaan.label}
            </Typography>
            <Autocomplete
              options={dataPekerjaan}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  size="small"
                  name={`informasiLamaran.${informasiLamaran.posisiPekerjaan.name}`}
                  onBlur={formik.handleBlur}
                  disabled={true}
                  error={
                    formik.touched.informasiLamaran &&
                    formik.errors.informasiLamaran &&
                    formik.touched.informasiLamaran[
                      `${informasiLamaran.posisiPekerjaan.name}`
                    ] &&
                    formik.errors.informasiLamaran[
                      `${informasiLamaran.posisiPekerjaan.name}`
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.informasiLamaran &&
                    formik.touched.informasiLamaran &&
                    formik.errors.informasiLamaran[
                      `${informasiLamaran.posisiPekerjaan.name}`
                    ] &&
                    formik.touched.informasiLamaran[
                      `${informasiLamaran.posisiPekerjaan.name}`
                    ]
                      ? formik.errors.informasiLamaran[
                          `${informasiLamaran.posisiPekerjaan.name}`
                        ]
                      : ""
                  }
                  label="Pilih Posisi"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
              getOptionLabel={(option) => option.positionName}
              disabled={true}
              value={
                dataPekerjaan?.find(
                  (item) =>
                    item.positionId ===
                    formik.values.informasiLamaran[
                      `${informasiLamaran.posisiPekerjaan.name}`
                    ]
                ) || null
              }
              onChange={(_event, newOption) => {
                formik.setFieldValue(
                  `informasiLamaran.${informasiLamaran.posisiPekerjaan.name}`,
                  newOption?.positionId || null
                );
              }}
            />
          </Grid>
           <Grid className={classes.grid} item xs={12}>
            <Typography className={classes.label}>
              {informasiLamaran.minatPenempatan.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Masukkan Nama Kota"
              disabled={true}
              InputProps={{
                disableUnderline: true,
              }}
              name={`informasiLamaran.${informasiLamaran.minatPenempatan.name}`}
              value={
                formik.values.informasiLamaran[
                  `${informasiLamaran.minatPenempatan.name}`
                ]
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.informasiLamaran &&
                formik.errors.informasiLamaran &&
                formik.touched.informasiLamaran[
                  `${informasiLamaran.minatPenempatan.name}`
                ] &&
                formik.errors.informasiLamaran[
                  `${informasiLamaran.minatPenempatan.name}`
                ] &&
                true
              }
              helperText={
                formik.errors.informasiLamaran &&
                formik.touched.informasiLamaran &&
                formik.errors.informasiLamaran[
                  `${informasiLamaran.minatPenempatan.name}`
                ] &&
                formik.touched.informasiLamaran[
                  `${informasiLamaran.minatPenempatan.name}`
                ]
                  ? formik.errors.informasiLamaran[
                      `${informasiLamaran.minatPenempatan.name}`
                    ]
                  : ""
              }
            />
          </Grid>
          <Grid className={classes.grid} item xs={12}>
            <Typography className={classes.label}>
              {informasiLamaran.harapanGaji.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Masukkan Gaji"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">Rp</InputAdornment>
                ),
              }}
              name={`informasiLamaran.${informasiLamaran.harapanGaji.name}`}
              value={GlobalFunction.numberWithDot(
                formik.values.informasiLamaran[
                  `${informasiLamaran.harapanGaji.name}`
                ]
              )}
              onChange={(e) => {
                if (e.target.value.length == 12) return false;
                numberValidation.test(
                  GlobalFunction.clearDotNumber(e.target.value)
                ) && GlobalFunction.clearDotNumber(e.target.value) !== ""
                  ? formik.setFieldValue(
                      `informasiLamaran.${informasiLamaran.harapanGaji.name}`,
                      parseInt(GlobalFunction.clearDotNumber(e.target.value))
                    )
                  : formik.setFieldValue(
                      `informasiLamaran.${informasiLamaran.harapanGaji.name}`,
                      ""
                    )
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.informasiLamaran &&
                formik.errors.informasiLamaran &&
                formik.touched.informasiLamaran[
                  `${informasiLamaran.harapanGaji.name}`
                ] &&
                formik.errors.informasiLamaran[
                  `${informasiLamaran.harapanGaji.name}`
                ] &&
                true
              }
              helperText={
                formik.errors.informasiLamaran &&
                formik.touched.informasiLamaran &&
                formik.errors.informasiLamaran[
                  `${informasiLamaran.harapanGaji.name}`
                ] &&
                formik.touched.informasiLamaran[
                  `${informasiLamaran.harapanGaji.name}`
                ]
                  ? formik.errors.informasiLamaran[
                      `${informasiLamaran.harapanGaji.name}`
                    ]
                  : ""
              }
            />
          </Grid>
          <Grid className={classes.grid} item xs={12}>
            <Typography className={classes.label}>
              {informasiLamaran.status.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              select
              size="small"
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              name={`informasiLamaran.${informasiLamaran.status.name}`}
              value={
                formik.values.informasiLamaran[
                  `${informasiLamaran.status.name}`
                ]
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.informasiLamaran &&
                formik.errors.informasiLamaran &&
                formik.touched.informasiLamaran[
                  `${informasiLamaran.status.name}`
                ] &&
                formik.errors.informasiLamaran[
                  `${informasiLamaran.status.name}`
                ] &&
                true
              }
              helperText={
                formik.errors.informasiLamaran &&
                formik.touched.informasiLamaran &&
                formik.errors.informasiLamaran[
                  `${informasiLamaran.status.name}`
                ] &&
                formik.touched.informasiLamaran[
                  `${informasiLamaran.status.name}`
                ]
                  ? formik.errors.informasiLamaran[
                      `${informasiLamaran.status.name}`
                    ]
                  : ""
              }
              SelectProps={{
                displayEmpty: true,
              }}
            >
              <MenuItem disabled value="">
                Pilih Status
              </MenuItem>
              <MenuItem value="Pencari Kerja">Pencari Kerja</MenuItem>
              <MenuItem value="Sedang Bekerja">Sedang Bekerja</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid className={classes.container} container>
          <Grid className={classes.gridBottom} item xs={12}>
            <FormControl
              error={
                formik.touched.informasiLamaran &&
                formik.errors.informasiLamaran &&
                formik.touched.informasiLamaran[
                  `${informasiLamaran.setuju.name}`
                ] &&
                formik.errors.informasiLamaran[
                  `${informasiLamaran.setuju.name}`
                ] &&
                true
              }
            >
              <FormControlLabel
                name={`informasiLamaran.${informasiLamaran.setuju.name}`}
                value={
                  formik.values.informasiLamaran[
                    `${informasiLamaran.setuju.name}`
                  ]
                }
                onChange={formik.handleChange}
                control={
                  <Checkbox
                    checked={
                      formik.values.informasiLamaran[
                        `${informasiLamaran.setuju.name}`
                      ]
                    }
                    color="secondary"
                  />
                }
                label={informasiLamaran.setuju.label}
              />
              <FormHelperText>
                {formik.touched.informasiLamaran &&
                formik.errors.informasiLamaran &&
                formik.errors.informasiLamaran[
                  `${informasiLamaran.setuju.name}`
                ] &&
                formik.touched.informasiLamaran[
                  `${informasiLamaran.setuju.name}`
                ]
                  ? formik.errors.informasiLamaran[
                      `${informasiLamaran.setuju.name}`
                    ]
                  : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Form;
