import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  makeStyles,
  Box,
  Grid,
  Card,
  Paper,
  Typography,
  Avatar,
  Accordion,
  AccordionSummary,
  Divider,
  AccordionDetails,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import { ArrowBackIosRounded, ExpandMore } from "@material-ui/icons";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";
import StatusChip from "../../../GlobalComponent/StatusChip";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5vw",
    [theme.breakpoints.down(750)]: {
      padding: "5vw 9vw",
      
    }
  },
  titlePage: {
    color: theme.palette.primary.main,
    fontSize: "1.25vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
      alignContent: "center",
      textAlign: "center",
    }
  },
  titleCenter: {
    alignContent: "center"
  },
  subtitle: {
    color: "#011627",
    fontSize: "0.7vw",
    [theme.breakpoints.down(750)]: {
      fontSize:"0.6rem"
    },
  },
  circleIcon: {
    backgroundColor: "#E21A43",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 6,
    borderRadius: "50%",
    width: 28,
    height: 28,
    cursor: "pointer",
  },
  iconBack: {
    color: "#FCFCFC",
    fontSize: 16,
  },
  radioLabel: {
    color: "#011627",
    fontSize: "0.7vw",
    fontWeight: "bold",
  },
  avatar: { width: 96, height: 96 },
  card: {
    padding: "5vh 2vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
  },
  titleCard: {
    color: theme.palette.primary.main,
    fontSize: "1.1vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize:"0.9rem"
    },
  },
  content: {
    padding: "3vh 2.5vw",
    [theme.breakpoints.down(750)]: {
      padding:"3vh"
    },
  },
  accordion: {
    borderRadius: 8,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
  },
  subtitleLabel: {
    color: "#005A7D",
    marginBottom: 8,
    fontSize: "0.625vw",
    [theme.breakpoints.down(750)]: {
      fontSize:"0.7rem"
    },
  },
  contentLabel: {
    color: "#011627",
    fontWeight: "bold",
    fontSize: "0.7vw",
    [theme.breakpoints.down(750)]: {
      fontSize:"0.7rem"
    },
  },
  titleSection: {
    color: "#005A7D",
    fontWeight: "bold",
    fontSize: "0.9vw",
    [theme.breakpoints.down(750)]: {
      fontSize:"0.7rem"
    },
  },
  sizeFormHide1: {
    [theme.breakpoints.down(750)]: {
      display: "none",
    },

  },
  sizeFormHide2: {
    [theme.breakpoints.up(750)]: {
      display: "none",
    },
  },
}));

const DetailHasilRecruitment = () => {
  const classes = useStyles();
  const history = useHistory();
  const [dataDetailLamaran, setDataDetailLamaran] = useState({});
  const [loading, setLoading] = useState(false);
  const { lamaranId } = useParams();

  useEffect(() => {
    getDetailLamaran();
  }, []);

  const getDetailLamaran = () => {
    setLoading(true);
    API(`lamaranKerja.getDetailLamaranKerja`, {
      query: { id: lamaranId },
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataDetailLamaran(res.data.Data);
        } else {
          Toast.fire({
            icon: "info",
            title: res.data?.Message || "Gagal mendapat data detail lamaran!",
          });
        }
      })
      .catch((res) => {
        setLoading(false);
        Toast.fire({
          icon: "warning",
          title:
            res.response?.data?.Message ||
            "Gagal mendapat data detail lamaran!",
        });
      });
  };

  return (
    <Box className={classes.root}>
      <Grid spacing={4} container className={classes.sizeFormHide1}>
        <Grid container spacing={3} item>
          <Grid item>
            <Paper className={classes.circleIcon}>
              <ArrowBackIosRounded
                className={classes.iconBack}
                onClick={() => {
                  history.push("/lamaran-pekerjaan");
                }}
              />
            </Paper>
          </Grid>
          <Grid item>
            <Typography className={classes.titlePage}>
              Detail Hasil Rekrutmen
            </Typography>
          </Grid>
        </Grid>
        {loading ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "30vh",
              width: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container item spacing={4}>
            <Grid container spacing={5} direction="column" item xs={9}>
              <Grid item>
                <Accordion className={classes.accordion} expanded>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    id="detail-riwayat-organisasi"
                    className={classes.content}
                  >
                    <Typography className={classes.titleCard}>
                      Hasil Rekapan Rekrutmen
                    </Typography>
                  </AccordionSummary>
                  <Grid direction="column" container>
                    <Box>
                      <Divider />
                      <AccordionDetails className={classes.content}>
                        <Grid container spacing={5}>
                          <Grid item xs={12}>
                            <Typography className={classes.titleSection}>
                              {dataDetailLamaran?.stepSelectionName}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              variant="subtitle2"
                              className={classes.subtitleLabel}
                            >
                              Status
                            </Typography>
                            {dataDetailLamaran?.testResultsStatus ? (
                              <StatusChip
                                value={dataDetailLamaran?.testResultsStatus}
                              />
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={classes.contentLabel}
                              >
                                -
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              variant="subtitle2"
                              className={classes.subtitleLabel}
                            >
                              Tanggal
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              className={classes.contentLabel}
                            >
                              {dataDetailLamaran?.approvalOn || "-"}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              variant="subtitle2"
                              className={classes.subtitleLabel}
                            >
                              Lokasi
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              className={classes.contentLabel}
                            >
                              {dataDetailLamaran?.locationName || "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Box>
                  </Grid>
                </Accordion>
              </Grid>
            </Grid>
            <Grid direction="column" container item xs={3} spacing={3}>
              <Grid item>
                <Card className={classes.card}>
                  <Avatar className={classes.avatar} />
                  <Grid
                    justifyContent="center"
                    direction="column"
                    alignItems="center"
                    container
                  >
                    <Grid item>
                      <Typography className={classes.titlePage}>
                        {localStorage.getItem("userName") || "-"}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.subtitle}>
                        {localStorage.getItem("userEmail") || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid spacing={4} container className={classes.sizeFormHide2}>
        <Grid container spacing={3} item>
          <Grid item>
            <Paper className={classes.circleIcon}>
              <ArrowBackIosRounded
                className={classes.iconBack}
                onClick={() => {
                  history.push("/lamaran-pekerjaan");
                }}
              />
            </Paper>
          </Grid>
          <Grid item className={classes.titleCenter}>
            <Typography className={classes.titlePage}>
              Detail Hasil Rekrutmen
            </Typography>
          </Grid>
        </Grid>
        {loading ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "30vh",
              width: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container item spacing={4}>
            <Grid direction="column" container item spacing={3}>
              <Grid item>
                <Card className={classes.card}>
                  <Avatar className={classes.avatar} />
                  <Grid
                    justifyContent="center"
                    direction="column"
                    alignItems="center"
                    container
                  >
                    <Grid item>
                      <Typography className={classes.titlePage}>
                        {localStorage.getItem("userName") || "-"}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.subtitle}>
                        {localStorage.getItem("userEmail") || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={5} direction="column" item>
              <Grid item>
                <Accordion className={classes.accordion} expanded>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    id="detail-riwayat-organisasi"
                    className={classes.content}
                  >
                    <Typography className={classes.titleCard}>
                      Hasil Rekapan Rekrutmen
                    </Typography>
                  </AccordionSummary>
                  <Grid direction="column" container>
                    <Box>
                      <Divider />
                      <AccordionDetails className={classes.content}>
                        <Grid container spacing={5}>
                          <Grid item xs={12}>
                            <Typography className={classes.titleSection}>
                              {dataDetailLamaran?.stepSelectionName}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="subtitle2"
                              className={classes.subtitleLabel}
                            >
                              Status
                            </Typography>
                            {dataDetailLamaran?.testResultsStatus ? (
                              <StatusChip
                                value={dataDetailLamaran?.testResultsStatus}
                              />
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={classes.contentLabel}
                              >
                                -
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="subtitle2"
                              className={classes.subtitleLabel}
                            >
                              Tanggal
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              className={classes.contentLabel}
                            >
                              {dataDetailLamaran?.approvalOn || "-"}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="subtitle2"
                              className={classes.subtitleLabel}
                            >
                              Lokasi
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              className={classes.contentLabel}
                            >
                              {dataDetailLamaran?.locationName || "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Box>
                  </Grid>
                </Accordion>
              </Grid>
            </Grid>
            
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default DetailHasilRecruitment;
