import React from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Event, Close } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import formInitialValues from "../../../GlobalComponent/FormModel/formInitialValues";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: "2vw",
  },
  header: {
    padding: "3vw",
    borderBottom: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
  },
  headerIsClose: {
    padding: "2.175vw 3vw",
    borderBottom: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
  },
  headerArea: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "1.1vw",
    fontWeight: "bold",
    margin: "auto",
    marginLeft: 0,
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
    }
  },
  content: {
    padding: 0,
    "&&:last-child": {
      paddingBottom: 0,
    },
  },
  container: {
    padding: "2vw",
    borderBottom: "1px solid #DDDDDD",
  },
  grid: {
    padding: "1vw",
  },
  label: {
    fontSize: "0.8vw",
    textAlign: "left",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.8rem",
    }
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  buttonAdd: {
    fontSize: "0.9vw",
    fontWeight: "bold",
    paddingTop: "1vw",
    paddingBottom: "1vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "2vw",
      padding: "2%",
      marginTop: "4%",
    }
  },
  close: {
    textAlign: "right",
  },
  iconButtom: {
    padding: "0.8vw",
  },
  closeIcon: {
    fontSize: "1.7vw",
  },
}));

const Form = ({ title, formik, riwayatKursus }) => {
  const classes = useStyles();
  const numberValidation = /^[0-9]*$/;

  const onAddForm = () => {
    const tempForm = [...formik.values.riwayatKursus];
    tempForm.push({ ...formInitialValues.riwayatKursus });
    formik.setValues({ ...formik.values, riwayatKursus: tempForm });
  };

  const onRemoveForm = (i) => {
    const formTemp = [...formik.values?.riwayatKursus];
    const touchTemp = [...formik.touched?.riwayatKursus];

    formTemp?.splice(i, 1);
    touchTemp?.splice(i, 1);
    formik.setValues({ ...formik.values, riwayatKursus: formTemp });
    formik.setTouched({ ...formik.touched, riwayatKursus: touchTemp });

    if (formik.errors?.hasOwnProperty("riwayatKursus")) {
      const errorTemp = [...formik.errors?.riwayatKursus];
      errorTemp?.splice(i, 1);
      formik.setErrors({ ...formik.errors, riwayatKursus: errorTemp });
    }
  };

  return (
    <Box>
      {formik.values.riwayatKursus?.map((e, i) => (
        <Card className={classes.card} key={i}>
          <CardHeader
            className={i === 0 ? classes.header : classes.headerIsClose}
            title={
              <Box className={classes.headerArea}>
                <Typography className={classes.title}>
                  {title + " - " + (i + 1)}
                </Typography>

                <IconButton
                  className={classes.iconButtom}
                  onClick={() => onRemoveForm(i)}
                >
                  <Close className={classes.closeIcon} />
                </IconButton>
              </Box>
            }
          />
          <CardContent className={classes.content}>
            <Grid className={classes.container} container>
              <Grid className={classes.grid} item xs={12}>
                <Typography className={classes.label}>
                  {riwayatKursus.namaKursus.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Riwayat Kursus"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatKursus.${i}.${riwayatKursus.namaKursus.name}`}
                  value={
                    formik.values.riwayatKursus[i][
                      riwayatKursus.namaKursus.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatKursus &&
                    formik.touched.riwayatKursus &&
                    formik.touched?.riwayatKursus[i]?.[
                      riwayatKursus.namaKursus.name
                    ] &&
                    formik.errors?.riwayatKursus[i]?.[
                      riwayatKursus.namaKursus.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatKursus &&
                    formik.touched.riwayatKursus &&
                    formik.errors.riwayatKursus[i]?.[
                      riwayatKursus.namaKursus.name
                    ] &&
                    formik.touched.riwayatKursus[i]?.[
                      riwayatKursus.namaKursus.name
                    ]
                      ? formik.errors.riwayatKursus[i]?.[
                          riwayatKursus.namaKursus.name
                        ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={12}>
                <Typography className={classes.label}>
                  {riwayatKursus.penyelenggara.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Jenis Kursus"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatKursus.${i}.${riwayatKursus.penyelenggara.name}`}
                  value={
                    formik.values.riwayatKursus[i][
                      riwayatKursus.penyelenggara.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatKursus &&
                    formik.touched.riwayatKursus &&
                    formik.touched?.riwayatKursus[i]?.[
                      riwayatKursus.penyelenggara.name
                    ] &&
                    formik.errors?.riwayatKursus[i]?.[
                      riwayatKursus.penyelenggara.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatKursus &&
                    formik.touched.riwayatKursus &&
                    formik.errors.riwayatKursus[i]?.[
                      riwayatKursus.penyelenggara.name
                    ] &&
                    formik.touched.riwayatKursus[i]?.[
                      riwayatKursus.penyelenggara.name
                    ]
                      ? formik.errors.riwayatKursus[i]?.[
                          riwayatKursus.penyelenggara.name
                        ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatKursus.tanggalMulai.label}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    hiddenLabel
                    fullWidth
                    disableFuture
                    size="small"
                    variant="dialog"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    keyboardIcon={<Event className={classes.icon} />}
                    InputProps={{
                      style: { padding: 0 },
                      disableUnderline: true,
                    }}
                    onChange={(val) => {
                      formik.setFieldValue(
                        `riwayatKursus.${i}.${riwayatKursus.tanggalSertifikat.name}`,
                        null
                      );
                      formik.setFieldValue(
                        `riwayatKursus.${i}.${riwayatKursus.tanggalAkhir.name}`,
                        null
                      );
                      formik.setFieldValue(
                        `riwayatKursus.${i}.${riwayatKursus.tanggalMulai.name}`,
                        val
                      );
                    }}
                    name={`riwayatKursus.${i}.${riwayatKursus.tanggalMulai.name}`}
                    value={
                      formik.values.riwayatKursus[i][
                        riwayatKursus.tanggalMulai.name
                      ]
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.riwayatKursus &&
                      formik.touched.riwayatKursus &&
                      formik.touched?.riwayatKursus[i]?.[
                        riwayatKursus.tanggalMulai.name
                      ] &&
                      formik.errors?.riwayatKursus[i]?.[
                        riwayatKursus.tanggalMulai.name
                      ] &&
                      true
                    }
                    helperText={
                      formik.errors.riwayatKursus &&
                      formik.touched.riwayatKursus &&
                      formik.errors.riwayatKursus[i]?.[
                        riwayatKursus.tanggalMulai.name
                      ] &&
                      formik.touched.riwayatKursus[i]?.[
                        riwayatKursus.tanggalMulai.name
                      ]
                        ? formik.errors.riwayatKursus[i]?.[
                            riwayatKursus.tanggalMulai.name
                          ]
                        : ""
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatKursus.tanggalAkhir.label}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    hiddenLabel
                    fullWidth
                    disableFuture
                    size="small"
                    variant="dialog"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    keyboardIcon={<Event className={classes.icon} />}
                    InputProps={{
                      style: { padding: 0 },
                      disableUnderline: true,
                    }}
                    minDate={
                      formik.values.riwayatKursus[i][
                        riwayatKursus.tanggalMulai.name
                      ] !== null &&
                      formik.values.riwayatKursus[i][
                        riwayatKursus.tanggalMulai.name
                      ]
                    }
                    disabled={
                      formik.values.riwayatKursus[i][
                        riwayatKursus.tanggalMulai.name
                      ] === null ||
                      formik.values.riwayatKursus[i][
                        riwayatKursus.tanggalSertifikat.name
                      ]
                    }
                    onChange={(val) => {
                      formik.setFieldValue(
                        `riwayatKursus.${i}.${riwayatKursus.tanggalSertifikat.name}`,
                        null
                      );
                      formik.setFieldValue(
                        `riwayatKursus.${i}.${riwayatKursus.tanggalAkhir.name}`,
                        val
                      );
                    }}
                    name={`riwayatKursus.${i}.${riwayatKursus.tanggalAkhir.name}`}
                    value={
                      formik.values.riwayatKursus[i][
                        riwayatKursus.tanggalAkhir.name
                      ]
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.riwayatKursus &&
                      formik.touched.riwayatKursus &&
                      formik.touched?.riwayatKursus[i]?.[
                        riwayatKursus.tanggalAkhir.name
                      ] &&
                      formik.errors?.riwayatKursus[i]?.[
                        riwayatKursus.tanggalAkhir.name
                      ] &&
                      true
                    }
                    helperText={
                      formik.errors.riwayatKursus &&
                      formik.touched.riwayatKursus &&
                      formik.errors.riwayatKursus[i]?.[
                        riwayatKursus.tanggalAkhir.name
                      ] &&
                      formik.touched.riwayatKursus[i]?.[
                        riwayatKursus.tanggalAkhir.name
                      ]
                        ? formik.errors.riwayatKursus[i]?.[
                            riwayatKursus.tanggalAkhir.name
                          ]
                        : ""
                    }
                  />
                </MuiPickersUtilsProvider>
                <FormControlLabel
                  onChange={(val) => {
                    const isChecked = val.target.checked;
                    formik.setFieldValue(
                      `riwayatKursus.${i}.${riwayatKursus.isForever.name}`,
                      isChecked
                    );
                    if (isChecked) {
                      formik.setFieldValue(
                        `riwayatKursus.${i}.${riwayatKursus.tanggalSertifikat.name}`,
                        new Date("01/01/9999")
                      );
                      formik.setFieldValue(
                        `riwayatKursus.${i}.${riwayatKursus.tanggalAkhir.name}`,
                        new Date("01/01/9999")
                      );
                    } else {
                      formik.setFieldValue(
                        `riwayatKursus.${i}.${riwayatKursus.tanggalSertifikat.name}`,
                        null
                      );
                      formik.setFieldValue(
                        `riwayatKursus.${i}.${riwayatKursus.tanggalAkhir.name}`,
                        null
                      );
                    }
                  }}
                  disabled={
                    formik.values.riwayatKursus[i][
                      riwayatKursus.tanggalMulai.name
                    ] === null
                  }
                  control={
                    <Checkbox
                      checked={
                        formik.values.riwayatKursus[i][
                          riwayatKursus.isForever.name
                        ] ||
                        parseInt(
                          moment(
                            formik.values.riwayatKursus[i][
                              riwayatKursus.tanggalAkhir.name
                            ]
                          ).format("YYYY")
                        ) > parseInt(moment().toDate().getFullYear())
                      }
                      color="secondary"
                    />
                  }
                  label="Berlaku Selamanya"
                />
              </Grid>
              <Grid hidden={true} className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatKursus.nomorSertifikat.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan No. Sertifikat"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatKursus.${i}.${riwayatKursus.nomorSertifikat.name}`}
                  value={
                    formik.values.riwayatKursus[i][
                      riwayatKursus.nomorSertifikat.name
                    ]
                  }
                  onChange={(e) => formik.handleChange(e)}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatKursus &&
                    formik.touched.riwayatKursus &&
                    formik.touched?.riwayatKursus[i]?.[
                      riwayatKursus.nomorSertifikat.name
                    ] &&
                    formik.errors?.riwayatKursus[i]?.[
                      riwayatKursus.nomorSertifikat.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatKursus &&
                    formik.touched.riwayatKursus &&
                    formik.errors.riwayatKursus[i]?.[
                      riwayatKursus.nomorSertifikat.name
                    ] &&
                    formik.touched.riwayatKursus[i]?.[
                      riwayatKursus.nomorSertifikat.name
                    ]
                      ? formik.errors.riwayatKursus[i]?.[
                          riwayatKursus.nomorSertifikat.name
                        ]
                      : ""
                  }
                />
              </Grid>
              <Grid hidden={true} className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatKursus.tanggalSertifikat.label}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    hiddenLabel
                    fullWidth
                    size="small"
                    variant="dialog"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    keyboardIcon={<Event className={classes.icon} />}
                    InputProps={{
                      style: { padding: 0 },
                      disableUnderline: true,
                    }}
                    onChange={(val) => {
                      formik.setFieldValue(
                        `riwayatKursus.${i}.${riwayatKursus.tanggalSertifikat.name}`,
                        val
                      );
                    }}
                    minDate={
                      formik.values.riwayatKursus[i][
                        riwayatKursus.tanggalAkhir.name
                      ] !== null &&
                      formik.values.riwayatKursus[i][
                        riwayatKursus.tanggalAkhir.name
                      ]
                    }
                    disabled={
                      formik.values.riwayatKursus[i][
                        riwayatKursus.tanggalAkhir.name
                      ] === null
                    }
                    name={`riwayatKursus.${i}.${riwayatKursus.tanggalSertifikat.name}`}
                    value={
                      formik.values.riwayatKursus[i][
                        riwayatKursus.tanggalSertifikat.name
                      ]
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.riwayatKursus &&
                      formik.touched.riwayatKursus &&
                      formik.touched?.riwayatKursus[i]?.[
                        riwayatKursus.tanggalSertifikat.name
                      ] &&
                      formik.errors?.riwayatKursus[i]?.[
                        riwayatKursus.tanggalSertifikat.name
                      ] &&
                      true
                    }
                    helperText={
                      formik.errors.riwayatKursus &&
                      formik.touched.riwayatKursus &&
                      formik.errors.riwayatKursus[i]?.[
                        riwayatKursus.tanggalSertifikat.name
                      ] &&
                      formik.touched.riwayatKursus[i]?.[
                        riwayatKursus.tanggalSertifikat.name
                      ]
                        ? formik.errors.riwayatKursus[i]?.[
                            riwayatKursus.tanggalSertifikat.name
                          ]
                        : ""
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              {/* <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatKursus.predikat.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Predikat"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: riwayatKursus.predikat.maximum,
                  }}
                  name={`riwayatKursus.${i}.${riwayatKursus.predikat.name}`}
                  value={
                    formik.values.riwayatKursus[i][riwayatKursus.predikat.name]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatKursus &&
                    formik.touched.riwayatKursus &&
                    formik.touched?.riwayatKursus[i]?.[
                      riwayatKursus.predikat.name
                    ] &&
                    formik.errors?.riwayatKursus[i]?.[
                      riwayatKursus.predikat.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatKursus &&
                    formik.touched.riwayatKursus &&
                    formik.errors.riwayatKursus[i]?.[
                      riwayatKursus.predikat.name
                    ] &&
                    formik.touched.riwayatKursus[i]?.[
                      riwayatKursus.predikat.name
                    ]
                      ? formik.errors.riwayatKursus[i]?.[
                          riwayatKursus.predikat.name
                        ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatKursus.nilai.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Nilai"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: riwayatKursus.nilai.maximum,
                  }}
                  name={`riwayatKursus.${i}.${riwayatKursus.nilai.name}`}
                  value={
                    formik.values.riwayatKursus[i][riwayatKursus.nilai.name]
                  }
                  onChange={(e) =>
                    numberValidation.test(e.target.value) &&
                    formik.handleChange(e)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatKursus &&
                    formik.touched.riwayatKursus &&
                    formik.touched?.riwayatKursus[i]?.[
                      riwayatKursus.nilai.name
                    ] &&
                    formik.errors?.riwayatKursus[i]?.[
                      riwayatKursus.nilai.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatKursus &&
                    formik.touched.riwayatKursus &&
                    formik.errors.riwayatKursus[i]?.[
                      riwayatKursus.nilai.name
                    ] &&
                    formik.touched.riwayatKursus[i]?.[riwayatKursus.nilai.name]
                      ? formik.errors.riwayatKursus[i]?.[
                          riwayatKursus.nilai.name
                        ]
                      : ""
                  }
                />
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      ))}
      {formik.values.riwayatKursus.length < 1 && (
        <Typography
          align="center"
          className={classes.buttonAdd}
          color="secondary"
        >
          Dapat Dilewati Apabila Tidak Relevan
        </Typography>
      )}
      <Button
        className={classes.buttonAdd}
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={() => {
          onAddForm();
        }}
      >
        + Tambah {title}
      </Button>
    </Box>
  );
};

export default Form;
