import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Divider,
  Paper,
  Fab,
} from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";
import { config } from "../../../config";
import GlobalFunction from "../../../GlobalFunction";

const useStyles = makeStyles((theme) => ({
  nilaiBudaya: {
    textAlign: "center",
    padding: "7vw 0",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "2vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "3vw",
    }
  },
  desc: {
    color: theme.palette.primary.main,
    fontSize: "1vw",
    marginTop: "1.5vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "2.3vw",
    }
  },
  divider: {
    width: "2.5vw",
    margin: "1vw auto",
    background: theme.palette.secondary.main,
    height: "0.3vw",
  },
  arrowPadding: {
    width: "4.5vw",
    margin: "auto",
  },
  arrow: {
    background: theme.palette.background.paper,
    width: "3vw",
    height: "3vw",
    minHeight: 0,
    cursor: "pointer",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    "&&:active": {
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
  },
  icon: {
    color: theme.palette.grey.A200,
    fontSize: "2.5vw",
  },
  budayaPadding: {
    padding: "3vw 10vw 0 10vw",
    display: "flex",
  },
  grid: {
    padding: 10,
  },
  paper: {
    padding: "2vw",
    width: "12vw",
  },
  image: {
    width: "100%",
    marginBottom: "0.5vw",
  },
  text1: {
    color: theme.palette.primary.main,
    fontSize: "1.5vw",
    fontWeight: "bold",
    textAlign: "center",
    margin: "1vw 0",
  },
  text2: {
    color: theme.palette.primary.main,
    fontSize: "0.8vw",
    textAlign: "center",
  },
}));

const NilaiNilaiBudaya = ({ innerRef }) => {
  const classes = useStyles();
  const [dataCulture, setDataCulture] = useState([]);
  const isLogin = localStorage.getItem("isLogin");
  const [message, setMessage] = useState("Success");

  useEffect(() => {
    if (isLogin) {
      getAllCulture("secured");
    } else {
      getAllCulture("open");
    }
  }, [isLogin]);

  const getAllCulture = (type) => {
    const url =
      type === "open" ? "home.getAllCultureOpen" : "home.getAllCultureSecured";

    API(`${url}`)
      .then((res) => {
        setMessage(res.data?.Message);
        if (res.status === 200 && res.data?.Message === "Success" || res.data?.Message === "Not Found") {
          setDataCulture(res.data?.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data Budaya!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data Budaya!",
        });
      });
  };

  const [pageCard, setPageCard] = useState(0);

  const handleClickPageCard = (type) => {
    if (type === "next") {
      setPageCard(pageCard + 1);
    } else {
      setPageCard(pageCard - 1);
    }
  };

  return (
    <div>
      {message.toLowerCase() !== "not found" && (
        <Box ref={innerRef} className={classes.nilaiBudaya}>
          <Typography className={classes.title}>
            {dataCulture[0]?.cultureValueSectionTitle || "Nilai-Nilai Budaya"}
          </Typography>
          <Typography className={classes.desc}>
            {dataCulture[0]?.cultureValueSectionSubtitle ||
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
          </Typography>
          <Divider className={classes.divider} />
          {dataCulture.length > 0 && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Box className={classes.arrowPadding}>
                  <Fab
                    className={classes.arrow}
                    onClick={() => handleClickPageCard("previous")}
                    disabled={pageCard === 0}
                  >
                    <ArrowLeft className={classes.icon} />
                  </Fab>
                </Box>
              </Grid>
              <Grid
                container
                direction="row"
                item
                xs={9}
                justifyContent="center"
                alignItems="center"
              >
                {dataCulture.map((item, i) => (
                  <div key={i}>
                    {i >= pageCard && i <= pageCard + 3 && (
                      <Grid className={classes.grid} item>
                        <Paper className={classes.paper}>
                          <img
                            className={classes.image}
                            alt="logo"
                            src={
                              item.cultureValueSectionImg
                                ? `${config.BASE_URL}${item.cultureValueSectionImg}`
                                : `${process.env.PUBLIC_URL}/images/home/alur-${
                                    i + 1
                                  }.png`
                            }
                            onError={GlobalFunction.handleImageError}
                          />
                          <Box>
                            <Typography className={classes.text1}>
                              {item.cultureValueSectionName || "Lorem ipsum"}
                            </Typography>
                            <Typography className={classes.text2}>
                              {item.cultureValueSectionDesc ||
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
                            </Typography>
                          </Box>
                        </Paper>
                      </Grid>
                    )}
                  </div>
                ))}
              </Grid>
              <Grid item>
                <Box className={classes.arrowPadding}>
                  <Fab
                    className={classes.arrow}
                    onClick={() => handleClickPageCard("next")}
                    disabled={dataCulture.length - 1 <= pageCard + 3}
                  >
                    <ArrowRight className={classes.icon} />
                  </Fab>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </div>
  );
};

export default NilaiNilaiBudaya;
