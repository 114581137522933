import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import formInitialValues from "../../../GlobalComponent/FormModel/formInitialValues";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: "2vw",
  },
  header: {
    padding: "3vw",
    borderBottom: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
  },
  headerIsClose: {
    padding: "2.175vw 3vw",
    borderBottom: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
  },
  headerArea: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "1.1vw",
    fontWeight: "bold",
    margin: "auto",
    marginLeft: 0,
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
    }
  },
  content: {
    padding: 0,
    "&&:last-child": {
      paddingBottom: 0,
    },
  },
  container: {
    padding: "2vw",
    borderBottom: "1px solid #DDDDDD",
  },
  grid: {
    padding: "1vw",
  },
  label: {
    fontSize: "0.8vw",
    textAlign: "left",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.8rem",
    }
  },
  buttonAdd: {
    fontSize: "0.9vw",
    fontWeight: "bold",
    paddingTop: "1vw",
    paddingBottom: "1vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "2vw",
      padding: "2%",
      marginTop: "4%",
    }
  },
  close: {
    textAlign: "right",
  },
  iconButtom: {
    padding: "0.8vw",
  },
  closeIcon: {
    fontSize: "1.7vw",
  },
}));

const Form = ({ title, formik, riwayatOrganisasi }) => {
  const classes = useStyles();
  const numberValidation = /^[0-9]*$/;
  const [dataJabatan, setDataJabatan] = useState([]);

  useEffect(() => {
    getDataJabatan();
  }, []);

  const getDataJabatan = () => {
    API(`formulirKerja.getLovJabatan`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataJabatan(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: res.data?.Message || "Gagal mendapat data jabatan!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Gagal mendapat data jabatan!",
        });
      });
  };

  const onAddForm = () => {
    const tempForm = [...formik.values.riwayatOrganisasi];
    tempForm.push({ ...formInitialValues.riwayatOrganisasi });
    formik.setValues({ ...formik.values, riwayatOrganisasi: tempForm });
  };

  const onRemoveForm = (i) => {
    const formTemp = [...formik.values?.riwayatOrganisasi];
    const touchTemp = [...formik.touched?.riwayatOrganisasi];

    formTemp?.splice(i, 1);
    touchTemp?.splice(i, 1);
    formik.setValues({ ...formik.values, riwayatOrganisasi: formTemp });
    formik.setTouched({ ...formik.touched, riwayatOrganisasi: touchTemp });

    if (formik.errors?.hasOwnProperty("riwayatOrganisasi")) {
      const errorTemp = [...formik.errors?.riwayatOrganisasi];
      errorTemp?.splice(i, 1);
      formik.setErrors({ ...formik.errors, riwayatOrganisasi: errorTemp });
    }
  };

  return (
    <Box>
      {formik.values.riwayatOrganisasi?.map((e, i) => (
        <Card className={classes.card} key={i}>
          <CardHeader
            className={i === 0 ? classes.header : classes.headerIsClose}
            title={
              <Box className={classes.headerArea}>
                <Typography className={classes.title}>
                  {title + " - " + (i + 1)}
                </Typography>

                <IconButton
                  className={classes.iconButtom}
                  onClick={() => onRemoveForm(i)}
                >
                  <Close className={classes.closeIcon} />
                </IconButton>
              </Box>
            }
          />
          <CardContent className={classes.content}>
            <Grid className={classes.container} container>
              <Grid className={classes.grid} item xs={12}>
                <Typography className={classes.label}>
                  {riwayatOrganisasi.namaOrganisasi.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Nama Organisasi"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatOrganisasi.${i}.${riwayatOrganisasi.namaOrganisasi.name}`}
                  value={
                    formik.values.riwayatOrganisasi[i][
                      riwayatOrganisasi.namaOrganisasi.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatOrganisasi &&
                    formik.touched.riwayatOrganisasi &&
                    formik.touched?.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.namaOrganisasi.name
                    ] &&
                    formik.errors?.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.namaOrganisasi.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatOrganisasi &&
                    formik.touched.riwayatOrganisasi &&
                    formik.errors.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.namaOrganisasi.name
                    ] &&
                    formik.touched.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.namaOrganisasi.name
                    ]
                      ? formik.errors.riwayatOrganisasi[i]?.[
                          riwayatOrganisasi.namaOrganisasi.name
                        ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={12}>
                <Typography className={classes.label}>
                  {riwayatOrganisasi.lingkup.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Contoh: Masyarakat/Sekolah/Kampus"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatOrganisasi.${i}.${riwayatOrganisasi.lingkup.name}`}
                  value={
                    formik.values.riwayatOrganisasi[i][
                      riwayatOrganisasi.lingkup.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatOrganisasi &&
                    formik.touched.riwayatOrganisasi &&
                    formik.touched?.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.lingkup.name
                    ] &&
                    formik.errors?.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.lingkup.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatOrganisasi &&
                    formik.touched.riwayatOrganisasi &&
                    formik.errors.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.lingkup.name
                    ] &&
                    formik.touched.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.lingkup.name
                    ]
                      ? formik.errors.riwayatOrganisasi[i]?.[
                          riwayatOrganisasi.lingkup.name
                        ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatOrganisasi.jabatan.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  // select
                  placeholder="Masukan Jabatan"
                  size="small"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatOrganisasi.${i}.${riwayatOrganisasi.jabatan.name}`}
                  value={
                    formik.values.riwayatOrganisasi[i][
                      riwayatOrganisasi.jabatan.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatOrganisasi &&
                    formik.touched.riwayatOrganisasi &&
                    formik.touched?.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.jabatan.name
                    ] &&
                    formik.errors?.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.jabatan.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatOrganisasi &&
                    formik.touched.riwayatOrganisasi &&
                    formik.errors.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.jabatan.name
                    ] &&
                    formik.touched.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.jabatan.name
                    ]
                      ? formik.errors.riwayatOrganisasi[i]?.[
                          riwayatOrganisasi.jabatan.name
                        ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatOrganisasi.masaJabatan.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Masa Jabatan"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: riwayatOrganisasi.masaJabatan.maximum,
                  }}
                  name={`riwayatOrganisasi.${i}.${riwayatOrganisasi.masaJabatan.name}`}
                  value={
                    formik.values.riwayatOrganisasi[i][
                      riwayatOrganisasi.masaJabatan.name
                    ]
                  }
                  onChange={(e) =>
                    numberValidation.test(e.target.value) &&
                    e.target.value !== ""
                      ? formik.setFieldValue(
                          `riwayatOrganisasi.${i}.${riwayatOrganisasi.masaJabatan.name}`,
                          parseInt(e.target.value)
                        )
                      : formik.setFieldValue(
                          `riwayatOrganisasi.${i}.${riwayatOrganisasi.masaJabatan.name}`,
                          ""
                        )
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatOrganisasi &&
                    formik.touched.riwayatOrganisasi &&
                    formik.touched?.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.masaJabatan.name
                    ] &&
                    formik.errors?.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.masaJabatan.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatOrganisasi &&
                    formik.touched.riwayatOrganisasi &&
                    formik.errors.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.masaJabatan.name
                    ] &&
                    formik.touched.riwayatOrganisasi[i]?.[
                      riwayatOrganisasi.masaJabatan.name
                    ]
                      ? formik.errors.riwayatOrganisasi[i]?.[
                          riwayatOrganisasi.masaJabatan.name
                        ]
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
      {formik.values.riwayatOrganisasi.length < 1 && (
        <Typography
          align="center"
          className={classes.buttonAdd}
          color="secondary"
        >
          Dapat Dilewati Apabila Tidak Relevan
        </Typography>
      )}
      <Button
        className={classes.buttonAdd}
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={() => {
          onAddForm();
        }}
      >
        + Tambah {title}
      </Button>
    </Box>
  );
};

export default Form;
