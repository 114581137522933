import * as Yup from "yup";
import formModel from "./formModel";

const {
  identitasDiri,
  riwayatPendidikan,
  riwayatOrganisasi,
  riwayatPekerjaan,
  riwayatKursus,
  riwayatSertifikasi,
  informasiLamaran,
  register,
} = formModel;

const regex = {
  numberValidation: /^[0-9]*$/,
  gpaValidation: /^[0-4][.][0-0][0-0]$|^[0-3]\.\d{2}$/,
  nikValidation:
    /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$/,
};

const validationSchema = {
  identitasDiriDaftar: Yup.object({
    [identitasDiri.namaLengkap.name]: Yup.string().required(
      `${identitasDiri.namaLengkap.requiredErrorMsg}`
    ),
    [identitasDiri.jenisKelamin.name]: Yup.string().required(
      `${identitasDiri.jenisKelamin.requiredErrorMsg}`
    ),
    [identitasDiri.nik.name]: Yup.string()
      .required(`${identitasDiri.nik.requiredErrorMsg}`)
      .matches(regex.nikValidation, `${identitasDiri.nik.typeErrorMsg}`)
      .min(identitasDiri.nik.minimal, `${identitasDiri.nik.typeErrorMsgMin}`),
    [identitasDiri.fileKtp.name]: Yup.string().required(
      `${identitasDiri.fileKtp.requiredErrorMsg}`
    ),
    [identitasDiri.tempatLahir.nameRegister]: Yup.string()
      .typeError(`${identitasDiri.tempatLahir.requiredErrorMsg}`)
      .required(`${identitasDiri.tempatLahir.requiredErrorMsg}`),
    [identitasDiri.tanggalLahir.name]: Yup.date()
      .typeError(`${identitasDiri.tanggalLahir.typeErrorMsg}`)
      .required(`${identitasDiri.tanggalLahir.requiredErrorMsg}`),
    [identitasDiri.email.name]: Yup.string()
      .email(`${identitasDiri.email.typeErrorMsg}`)
      .required(`${identitasDiri.email.requiredErrorMsg}`),
    [identitasDiri.nomorHandphone.name]: Yup.string()
      .required(`${identitasDiri.nomorHandphone.requiredErrorMsg}`)
      .matches(
        regex.numberValidation,
        `${identitasDiri.nomorHandphone.typeErrorMsg}`
      )
      .min(
        identitasDiri.nomorHandphone.minimal,
        `${identitasDiri.nomorHandphone.typeErrorMsgMin}`
      ),
    [identitasDiri.password.name]: Yup.string().min(8, 'Minimal 8 karakter!')
    .matches(/[A-Z]/, 'Harap menggunakan kombinasi huruf besar!')
    .matches(/[a-z]/, 'Harap menggunakan kombinasi huruf kecil!')
    .matches(/[0-9]/, 'Harap menggunakan kombinasi angka!')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Harap menggunakan kombinasi simbol!')
    .required(
      `${identitasDiri.password.requiredErrorMsg}`
    ),
    [identitasDiri.konfirmasiPassword.name]: Yup.string()
      .oneOf(
        [Yup.ref(`${identitasDiri.password.name}`), ""],
        `${identitasDiri.konfirmasiPassword.typeErrorMsg}`
      )
      .required(`${identitasDiri.konfirmasiPassword.requiredErrorMsg}`),
    [register.kodeUnik.name]: Yup.string().required(
      `${register.kodeUnik.requiredErrorMsg}`
    ),
  }),
  identitasDiri: Yup.object({
    [identitasDiri.namaLengkap.name]: Yup.string().required(
      `${identitasDiri.namaLengkap.requiredErrorMsg}`
    ),
    [identitasDiri.jenisKelamin.name]: Yup.string().required(
      `${identitasDiri.jenisKelamin.requiredErrorMsg}`
    ),
    [identitasDiri.nik.name]: Yup.string()
      .required(`${identitasDiri.nik.requiredErrorMsg}`)
      .matches(regex.numberValidation, `${identitasDiri.nik.typeErrorMsg}`)
      .min(identitasDiri.nik.minimal, `${identitasDiri.nik.typeErrorMsgMin}`),
    // [identitasDiri.fileKtp.name]: Yup.string().required(
    //   `${identitasDiri.fileKtp.requiredErrorMsg}`
    // ),
    [identitasDiri.tempatLahir.name]: Yup.string()
      .typeError(`${identitasDiri.tempatLahir.requiredErrorMsg}`)
      .required(`${identitasDiri.tempatLahir.requiredErrorMsg}`),
    [identitasDiri.tanggalLahir.name]: Yup.date()
      .typeError(`${identitasDiri.tanggalLahir.typeErrorMsg}`)
      .required(`${identitasDiri.tanggalLahir.requiredErrorMsg}`),
    [identitasDiri.email.name]: Yup.string()
      .email(`${identitasDiri.email.typeErrorMsg}`)
      .required(`${identitasDiri.email.requiredErrorMsg}`),
    [identitasDiri.nomorHandphone.name]: Yup.string()
      .required(`${identitasDiri.nomorHandphone.requiredErrorMsg}`)
      .matches(
        regex.numberValidation,
        `${identitasDiri.nomorHandphone.typeErrorMsg}`
      )
      .min(
        identitasDiri.nomorHandphone.minimal,
        `${identitasDiri.nomorHandphone.typeErrorMsgMin}`
      ),
    [identitasDiri.domisiliKtp.name]: Yup.string()
      .typeError(`${identitasDiri.domisiliKtp.requiredErrorMsg}`)
      .required(`${identitasDiri.domisiliKtp.requiredErrorMsg}`),
    [identitasDiri.alamatKtp.name]: Yup.string()
      .typeError(`${identitasDiri.alamatKtp.requiredErrorMsg}`)
      .required(`${identitasDiri.alamatKtp.requiredErrorMsg}`),
    [identitasDiri.domisiliSekarang.name]: Yup.string()
      .typeError(`${identitasDiri.domisiliSekarang.requiredErrorMsg}`)
      .required(`${identitasDiri.domisiliSekarang.requiredErrorMsg}`),
    [identitasDiri.alamatSekarang.name]: Yup.string()
      .typeError(`${identitasDiri.alamatSekarang.requiredErrorMsg}`)
      .required(`${identitasDiri.alamatSekarang.requiredErrorMsg}`),
    [identitasDiri.nomorKk.name]: Yup.string()
      .typeError(`${identitasDiri.nomorKk.requiredErrorMsg}`)
      .required(`${identitasDiri.nomorKk.requiredErrorMsg}`)
      .matches(regex.nikValidation, `${identitasDiri.nomorKk.typeErrorMsg}`)
      .min(
        identitasDiri.nomorKk.minimal,
        `${identitasDiri.nomorKk.typeErrorMsgMin}`
      ),
    [identitasDiri.npwp.name]: Yup.string()
      .typeError(`${identitasDiri.npwp.requiredErrorMsg}`)
      // .required(`${identitasDiri.npwp.requiredErrorMsg}`)
      .matches(regex.numberValidation, `${identitasDiri.npwp.typeErrorMsg}`)
      .min(identitasDiri.npwp.minimal, `${identitasDiri.npwp.typeErrorMsgMin}`)
      .max(identitasDiri.npwp.maximum, `${identitasDiri.npwp.typeErrorMsgMin}`),
    [identitasDiri.agama.name]: Yup.string()
      .typeError(`${identitasDiri.agama.requiredErrorMsg}`)
      .required(`${identitasDiri.agama.requiredErrorMsg}`),
    [identitasDiri.kewarganegaraan.name]: Yup.string()
      .typeError(`${identitasDiri.kewarganegaraan.requiredErrorMsg}`)
      .required(`${identitasDiri.kewarganegaraan.requiredErrorMsg}`),
    [identitasDiri.suku.name]: Yup.string()
      .typeError(`${identitasDiri.suku.requiredErrorMsg}`)
      .required(`${identitasDiri.suku.requiredErrorMsg}`),
    [identitasDiri.statusKawin.name]: Yup.string()
      .typeError(`${identitasDiri.statusKawin.requiredErrorMsg}`)
      .required(`${identitasDiri.statusKawin.requiredErrorMsg}`),
    [identitasDiri.tinggiBadan.name]: Yup.string()
      .typeError(`${identitasDiri.tinggiBadan.requiredErrorMsg}`)
      .required(`${identitasDiri.tinggiBadan.requiredErrorMsg}`),
    [identitasDiri.beratBadan.name]: Yup.string()
      .typeError(`${identitasDiri.beratBadan.requiredErrorMsg}`)
      .required(`${identitasDiri.beratBadan.requiredErrorMsg}`),
    [identitasDiri.golonganDarah.name]: Yup.string()
      .typeError(`${identitasDiri.golonganDarah.requiredErrorMsg}`)
      .required(`${identitasDiri.golonganDarah.requiredErrorMsg}`),
    [identitasDiri.warnaKulit.name]: Yup.string()
      .typeError(`${identitasDiri.warnaKulit.requiredErrorMsg}`)
      .required(`${identitasDiri.warnaKulit.requiredErrorMsg}`),
    [identitasDiri.bentukMuka.name]: Yup.string()
      .typeError(`${identitasDiri.bentukMuka.requiredErrorMsg}`)
      .required(`${identitasDiri.bentukMuka.requiredErrorMsg}`),
    [identitasDiri.jenisRambut.name]: Yup.string()
      .typeError(`${identitasDiri.jenisRambut.requiredErrorMsg}`)
      .required(`${identitasDiri.jenisRambut.requiredErrorMsg}`),
    [identitasDiri.bisaInggris.name]: Yup.string()
      .typeError(`${identitasDiri.bisaInggris.requiredErrorMsg}`)
      .required(`${identitasDiri.bisaInggris.requiredErrorMsg}`),
    [identitasDiri.bisaQuran.name]: Yup.string()
      .typeError(`${identitasDiri.bisaQuran.requiredErrorMsg}`)
      .required(`${identitasDiri.bisaQuran.requiredErrorMsg}`),
    [identitasDiri.instagram.name]: Yup.string()
      .typeError(`${identitasDiri.instagram.requiredErrorMsg}`)
      .required(`${identitasDiri.instagram.requiredErrorMsg}`),
    [identitasDiri.twitter.name]: Yup.string()
      .typeError(`${identitasDiri.twitter.requiredErrorMsg}`)
      .required(`${identitasDiri.twitter.requiredErrorMsg}`),
    [identitasDiri.facebook.name]: Yup.string()
      .typeError(`${identitasDiri.facebook.requiredErrorMsg}`)
      .required(`${identitasDiri.facebook.requiredErrorMsg}`),
    [identitasDiri.linkedIn.name]: Yup.string()
      .typeError(`${identitasDiri.linkedIn.requiredErrorMsg}`)
      .required(`${identitasDiri.linkedIn.requiredErrorMsg}`),
    [identitasDiri.pasFoto.name]: Yup.string()
      .typeError(`${identitasDiri.pasFoto.requiredErrorMsg}`)
      .required(`${identitasDiri.pasFoto.requiredErrorMsg}`),
    // [identitasDiri.fullFoto.name]: Yup.string()
    //   .typeError(`${identitasDiri.fullFoto.requiredErrorMsg}`)
    //   .required(`${identitasDiri.fullFoto.requiredErrorMsg}`),
    [identitasDiri.selfieFoto.name]: Yup.string()
      .typeError(`${identitasDiri.selfieFoto.requiredErrorMsg}`)
      .required(`${identitasDiri.selfieFoto.requiredErrorMsg}`),
    [identitasDiri.cv.name]: Yup.string()
      .typeError(`${identitasDiri.cv.requiredErrorMsg}`)
      .required(`${identitasDiri.cv.requiredErrorMsg}`),
  }),
  riwayatPendidikan: Yup.object().shape({
    [riwayatPendidikan.nomorIjazah.name]: Yup.string().required(
      `${riwayatPendidikan.nomorIjazah.requiredErrorMsg}`
    ),
    [riwayatPendidikan.tahunMasuk.name]: Yup.date()
      .typeError(`${riwayatPendidikan.tahunMasuk.typeErrorMsg}`)
      .required(`${riwayatPendidikan.tahunMasuk.requiredErrorMsg}`),
    [riwayatPendidikan.tahunLulus.name]: Yup.date()
      .typeError(`${riwayatPendidikan.tahunLulus.typeErrorMsg}`)
      .required(`${riwayatPendidikan.tahunLulus.requiredErrorMsg}`),
    [riwayatPendidikan.semester.name]: Yup.string().required(
      `${riwayatPendidikan.semester.requiredErrorMsg}`
    ),
    [riwayatPendidikan.ipk.name]: Yup.string()
      .required(`${riwayatPendidikan.ipk.requiredErrorMsg}`)
      .matches(regex.gpaValidation, `${riwayatPendidikan.ipk.typeErrorMsg}`),
    [riwayatPendidikan.tanggalIjazah.name]: Yup.date()
      .typeError(`${riwayatPendidikan.tanggalIjazah.typeErrorMsg}`)
      .required(`${riwayatPendidikan.tanggalIjazah.requiredErrorMsg}`),
    [riwayatPendidikan.strata.name]: Yup.string().required(
      `${riwayatPendidikan.strata.requiredErrorMsg}`
    ),
    [riwayatPendidikan.institusi.parentName]: Yup.object()
      .shape({
        [riwayatPendidikan.institusi.childName]: Yup.string()
          .typeError(`${riwayatPendidikan.institusi.requiredErrorMsg}`)
          .required(`${riwayatPendidikan.institusi.requiredErrorMsg}`),
      })
      .nullable(),
    [riwayatPendidikan.fakultas.parentName]: Yup.object()
      .shape({
        [riwayatPendidikan.fakultas.childName]: Yup.string()
          .typeError(`${riwayatPendidikan.fakultas.requiredErrorMsg}`)
          .required(`${riwayatPendidikan.fakultas.requiredErrorMsg}`),
      })
      .nullable(),
    [riwayatPendidikan.jurusan.parentName]: Yup.object()
      .shape({
        [riwayatPendidikan.jurusan.childName]: Yup.string()
          .typeError(`${riwayatPendidikan.jurusan.requiredErrorMsg}`)
          .required(`${riwayatPendidikan.jurusan.requiredErrorMsg}`),
      })
      .nullable(),
  }),
  riwayatOrganisasi: Yup.object().shape({
    [riwayatOrganisasi.namaOrganisasi.name]: Yup.string().required(
      `${riwayatOrganisasi.namaOrganisasi.requiredErrorMsg}`
    ),
    [riwayatOrganisasi.lingkup.name]: Yup.string().required(
      `${riwayatOrganisasi.lingkup.requiredErrorMsg}`
    ),
    [riwayatOrganisasi.jabatan.name]: Yup.string().required(
      `${riwayatOrganisasi.jabatan.requiredErrorMsg}`
    ),
    [riwayatOrganisasi.masaJabatan.name]: Yup.string().required(
      `${riwayatOrganisasi.masaJabatan.requiredErrorMsg}`
    ),
  }),
  riwayatPekerjaan: Yup.object().shape({
    [riwayatPekerjaan.namaPerusahaan.name]: Yup.string().required(
      `${riwayatPekerjaan.namaPerusahaan.requiredErrorMsg}`
    ),
    [riwayatPekerjaan.industri.name]: Yup.string().required(
      `${riwayatPekerjaan.industri.requiredErrorMsg}`
    ),
    [riwayatPekerjaan.status.name]: Yup.string().required(
      `${riwayatPekerjaan.status.requiredErrorMsg}`
    ),
    [riwayatPekerjaan.tahunMasuk.name]: Yup.date()
      .typeError(`${riwayatPekerjaan.tahunMasuk.typeErrorMsg}`)
      .required(`${riwayatPekerjaan.tahunMasuk.requiredErrorMsg}`),
    [riwayatPekerjaan.tahunAkhir.name]: Yup.date()
      .typeError(`${riwayatPekerjaan.tahunAkhir.typeErrorMsg}`)
      .required(`${riwayatPekerjaan.tahunAkhir.requiredErrorMsg}`),
    [riwayatPekerjaan.level.name]: Yup.string().required(
      `${riwayatPekerjaan.level.requiredErrorMsg}`
    ),
    [riwayatPekerjaan.jabatan.name]: Yup.string().required(
      `${riwayatPekerjaan.jabatan.requiredErrorMsg}`
    ),
    [riwayatPekerjaan.gajiTerakhir.name]: Yup.string().required(
      `${riwayatPekerjaan.gajiTerakhir.requiredErrorMsg}`
    ),
    [riwayatPekerjaan.namaAtasan.name]: Yup.string().required(
      `${riwayatPekerjaan.namaAtasan.requiredErrorMsg}`
    ),
    [riwayatPekerjaan.kontakAtasan.name]: Yup.string()
      .required(`${riwayatPekerjaan.kontakAtasan.requiredErrorMsg}`)
      .matches(
        regex.numberValidation,
        `${riwayatPekerjaan.kontakAtasan.typeErrorMsg}`
      )
      .min(
        riwayatPekerjaan.kontakAtasan.minimal,
        `${riwayatPekerjaan.kontakAtasan.typeErrorMsgMin}`
      ),
    [riwayatPekerjaan.kontakPerusahaan.name]: Yup.string()
      .required(`${riwayatPekerjaan.kontakPerusahaan.requiredErrorMsg}`)
      .matches(
        regex.numberValidation,
        `${riwayatPekerjaan.kontakPerusahaan.typeErrorMsg}`
      )
      .min(
        riwayatPekerjaan.kontakPerusahaan.minimal,
        `${riwayatPekerjaan.kontakPerusahaan.typeErrorMsgMin}`
      ),
    [riwayatPekerjaan.totalBekerja.name]: Yup.string().required(
      `${riwayatPekerjaan.totalBekerja.requiredErrorMsg}`
    ),
  }),
  riwayatKursus: Yup.object().shape({
    [riwayatKursus.namaKursus.name]: Yup.string().required(
      `${riwayatKursus.namaKursus.requiredErrorMsg}`
    ),
    [riwayatKursus.penyelenggara.name]: Yup.string().required(
      `${riwayatKursus.penyelenggara.requiredErrorMsg}`
    ),
    [riwayatKursus.tanggalMulai.name]: Yup.date()
      .typeError(`${riwayatKursus.tanggalMulai.typeErrorMsg}`)
      .required(`${riwayatKursus.tanggalMulai.requiredErrorMsg}`),
    [riwayatKursus.tanggalAkhir.name]: Yup.date()
      .typeError(`${riwayatKursus.tanggalAkhir.typeErrorMsg}`)
      .required(`${riwayatKursus.tanggalAkhir.requiredErrorMsg}`)
  }),
  riwayatSertifikasi: Yup.object().shape({
    [riwayatSertifikasi.namaPanjang.name]: Yup.string().required(
      `${riwayatSertifikasi.namaPanjang.requiredErrorMsg}`
    ),
    [riwayatSertifikasi.namaSingkat.name]: Yup.string().required(
      `${riwayatSertifikasi.namaSingkat.requiredErrorMsg}`
    ),
    [riwayatSertifikasi.tanggalSertifikasi.name]: Yup.date()
      .typeError(`${riwayatSertifikasi.tanggalSertifikasi.typeErrorMsg}`)
      .required(`${riwayatSertifikasi.tanggalSertifikasi.requiredErrorMsg}`),
    [riwayatSertifikasi.tanggalAkhir.name]: Yup.date()
      .typeError(`${riwayatSertifikasi.tanggalAkhir.typeErrorMsg}`)
      .required(`${riwayatSertifikasi.tanggalAkhir.requiredErrorMsg}`),
    [riwayatSertifikasi.nomorSertifikasi.name]: Yup.string().required(
      `${riwayatSertifikasi.nomorSertifikasi.requiredErrorMsg}`
    ),
    [riwayatSertifikasi.namaLembaga.name]: Yup.string().required(
      `${riwayatSertifikasi.namaLembaga.requiredErrorMsg}`
    ),
    [riwayatSertifikasi.predikat.name]: Yup.string().required(
      `${riwayatKursus.predikat.requiredErrorMsg}`
    ),
    [riwayatSertifikasi.nilai.name]: Yup.string().required(
      `${riwayatKursus.nilai.requiredErrorMsg}`
    ),
  }),
  informasiLamaran: Yup.object({
    [informasiLamaran.posisiPekerjaan.name]: Yup.string()
      .typeError(`${informasiLamaran.posisiPekerjaan.requiredErrorMsg}`)
      .required(`${informasiLamaran.posisiPekerjaan.requiredErrorMsg}`),
    [informasiLamaran.harapanGaji.name]: Yup.string().required(
      `${informasiLamaran.harapanGaji.requiredErrorMsg}`
    ),
    [informasiLamaran.minatPenempatan.name]: Yup.string().required(
      `${informasiLamaran.minatPenempatan.requiredErrorMsg}`
    ),
    [informasiLamaran.status.name]: Yup.string().required(
      `${informasiLamaran.status.requiredErrorMsg}`
    ),
    [informasiLamaran.setuju.name]: Yup.boolean().oneOf(
      [true],
      `${informasiLamaran.setuju.requiredErrorMsg}`
    ),
  }),
};

export default validationSchema;
