import React from "react";
import {
  Box,
  Card,
  Grid,
  Typography,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import { API } from "../../../service/axios";
import { Toast } from "../../Component/Notification";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5vw 0",
    [theme.breakpoints.down(1025)]: {
      paddingTop: "10vh",
    },
    [theme.breakpoints.down(750)]: {
      paddingTop: "10vh",
    },
    [theme.breakpoints.down(600)]: {
      paddingTop: "4vh",
    },
    [theme.breakpoints.down(500)]: {
      paddingTop: "10vh",
    },
    [theme.breakpoints.down(378)]: {
      paddingTop: "0vh",
    },
    [theme.breakpoints.down(361)]: {
      paddingTop: "6vh",
    },
  },
  content: {
    minHeight: "calc(100% - 35.2vw)",
    paddingTop: "10vw",
    paddingBottom: "5vw",
    background: "#F3F9FE",
  },
  cardHeaderRoot: {
    padding: "2.5vw 0 0 0",
    justifyContent: "center",
    display: "grid",
    [theme.breakpoints.down(750)]: {
      padding: "5vw 0 2.5vw 0",
    },
    [theme.breakpoints.down(500)]: {
      padding: "8vw 0 2.5vw 0",
    }
  },
  cardContentRoot: {
    padding: "2.5vw",
    [theme.breakpoints.down(750)]: {
      padding: "0 6vw 6vw",
    }
  },
  cardActionsRoot: {
    padding: "2.5vw",
    justifyContent: "center",
  },
  labelSpacing: {
    marginBottom: 3,
  },
  buttonLogin: {
    background: "#E21A43",
    height: 50,
  },
  labelSubmit: {
    textTransform: "capitalize",
    color: "white",
  },
  labelCapitalize1: {
    fontSize: "0.8vw",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    [theme.breakpoints.down(750)]: {
      fontSize: "13px",
    }
  },
  labelCapitalize2: {
    fontSize: "0.8vw",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    cursor: "pointer",
    textDecoration: "underline",
    [theme.breakpoints.down(750)]: {
      fontSize: "13px",
    }
  },
  inputFilled: {
    background: "#F3F4F5 !important ",
  },
  forgotTitle: {
    color: theme.palette.primary.main,
    fontSize: "1.875vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "18px",
    }
  },
  containerForgot: {
    maxWidth: "400px",
    margin: "0 auto",
    padding: "20px",
    // [theme.breakpoints.down(750)]: {
    //   // padding: "20%",
    //   maxWidth: "100%",
    //   padding: "5vh",
    // },
    [theme.breakpoints.down(750)]: {
      // padding: "20%",
      maxWidth: "100%",
      padding: "5vh 10vh 0",
    },
    // [theme.breakpoints.down(630)]: {
    //   // padding: "20%",
    //   maxWidth: "100%",
    //   padding: "0 10vh 10vh",
    // },
    // [theme.breakpoints.down(600)]: {
    //   // padding: "20%",
    //   maxWidth: "100%",
    //   padding: "0 5vh",
    // },
    [theme.breakpoints.down(600)]: {
      // padding: "20%",
      maxWidth: "100%",
      padding: "10vh 5vh 0",
    }
  },
}));

const ForgotPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(`Harap isi email!`),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      API(`forgotPassword.sentEmail`, { data: values })
        .then((res) => {
          setSubmitting(false);
          if (res.status === 200 && res.data?.Message) {
            Toast.fire({
              icon: "success",
              title: res.data?.Message,
            });
            history.push("/login");
          }
        })
        .catch((res) => {
          setSubmitting(false);
          Toast.fire({
            icon: "warning",
            title: res.response?.data?.Message || "Gagal mengirim email!",
          });
        });
    },
  });

  return (
    <Box className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid className={classes.containerForgot}>
          <Card>
            <form onSubmit={formik.handleSubmit}>
              <CardHeader
                className={classes.cardHeaderRoot}
                title="Lupa Password"
                titleTypographyProps={{
                  variant: "h4",
                  className: classes.forgotTitle,
                }}
              />
              <CardContent className={classes.cardContentRoot}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.labelSpacing}
                      variant="subtitle1"
                    >
                      Email
                    </Typography>
                    <TextField
                      placeholder="Masukkan Email Terdaftar"
                      variant="filled"
                      hiddenLabel
                      size="medium"
                      fullWidth
                      InputProps={{
                        className: classes.inputFilled,
                        disableUnderline: true,
                      }}
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      error={
                        formik.touched?.email && formik.errors?.email && true
                      }
                      helperText={
                        formik.touched?.email && formik.errors?.email
                          ? formik.errors?.email
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className={classes.buttonLogin}
                      variant="contained"
                      fullWidth
                      color="secondary"
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? (
                        <i className="fas fa-circle-notch fa-spin" />
                      ) : (
                        ""
                      )}
                      <Typography className={classes.labelSubmit}>
                        Submit
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions className={classes.cardActionsRoot}>
                <Typography className={classes.labelCapitalize1}>
                  Kembali
                </Typography>
                <Typography
                  className={classes.labelCapitalize2}
                  onClick={() => history.push("/login")}
                >
                  Login
                </Typography>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
