import formModel from "./formModel";

const {
  identitasDiri,
  riwayatPendidikan,
  riwayatOrganisasi,
  riwayatPekerjaan,
  riwayatKursus,
  riwayatSertifikasi,
  informasiLamaran,
  register,
  gantiPassword,
} = formModel;

const formInitial = {
  identitasDiriDaftar: {
    [identitasDiri.namaLengkap.name]: "",
    [identitasDiri.jenisKelamin.name]: "",
    [identitasDiri.nik.name]: "",
    [identitasDiri.fileKtp.name]: "",
    [identitasDiri.tempatLahir.nameRegister]: null,
    [identitasDiri.tanggalLahir.name]: null,
    [identitasDiri.email.name]: "",
    [identitasDiri.nomorHandphone.name]: "",
    [identitasDiri.password.name]: "",
    [identitasDiri.konfirmasiPassword.name]: "",
    [register.kodeUnik.name]: "",
  },
  identitasDiri: {
    [identitasDiri.namaLengkap.name]: "",
    [identitasDiri.jenisKelamin.name]: "",
    [identitasDiri.nik.name]: "",
    [identitasDiri.fileKtp.name]: "",
    [identitasDiri.tempatLahir.name]: null,
    [identitasDiri.tanggalLahir.name]: null,
    [identitasDiri.email.name]: "",
    [identitasDiri.nomorHandphone.name]: "",
    [identitasDiri.domisiliKtp.name]: null,
    [identitasDiri.alamatKtp.name]: "",
    [identitasDiri.domisiliSekarang.name]: null,
    [identitasDiri.alamatSekarang.name]: "",
    [identitasDiri.nomorKk.name]: "",
    [identitasDiri.npwp.name]: "",
    [identitasDiri.agama.name]: null,
    [identitasDiri.kewarganegaraan.name]: null,
    [identitasDiri.suku.name]: null,
    [identitasDiri.statusKawin.name]: null,
    [identitasDiri.tinggiBadan.name]: "",
    [identitasDiri.beratBadan.name]: "",
    [identitasDiri.golonganDarah.name]: null,
    [identitasDiri.warnaKulit.name]: null,
    [identitasDiri.bentukMuka.name]: null,
    [identitasDiri.jenisRambut.name]: null,
    [identitasDiri.bisaInggris.name]: "",
    [identitasDiri.bisaQuran.name]: "",
    [identitasDiri.instagram.name]: "",
    [identitasDiri.twitter.name]: "",
    [identitasDiri.facebook.name]: "",
    [identitasDiri.linkedIn.name]: "",
    [identitasDiri.pasFoto.name]: "",
    [identitasDiri.fullFoto.name]: "",
    [identitasDiri.selfieFoto.name]: "",
    [identitasDiri.cv.name]: "",
    [identitasDiri.cv.path]: "",
  },
  riwayatPendidikan: {
    [riwayatPendidikan.nomorIjazah.name]: "",
    [riwayatPendidikan.tahunMasuk.name]: null,
    [riwayatPendidikan.tahunLulus.name]: null,
    [riwayatPendidikan.semester.name]: "",
    [riwayatPendidikan.ipk.name]: "",
    [riwayatPendidikan.tanggalIjazah.name]: null,
    [riwayatPendidikan.strata.name]: "",
    [riwayatPendidikan.institusi.parentName]: {
      [riwayatPendidikan.institusi.childName]: null,
    },
    [riwayatPendidikan.fakultas.parentName]: {
      [riwayatPendidikan.fakultas.childName]: null,
    },
    [riwayatPendidikan.jurusan.parentName]: {
      [riwayatPendidikan.jurusan.childName]: null,
    },
  },
  riwayatOrganisasi: {
    [riwayatOrganisasi.namaOrganisasi.name]: "",
    [riwayatOrganisasi.lingkup.name]: "",
    [riwayatOrganisasi.jabatan.name]: "",
    [riwayatOrganisasi.masaJabatan.name]: "",
  },
  riwayatPekerjaan: {
    [riwayatPekerjaan.namaPerusahaan.name]: "",
    [riwayatPekerjaan.industri.name]: "",
    [riwayatPekerjaan.status.name]: "",
    [riwayatPekerjaan.tahunMasuk.name]: null,
    [riwayatPekerjaan.tahunAkhir.name]: null,
    [riwayatPekerjaan.masihBerkerja.name]: false,
    [riwayatPekerjaan.level.name]: "",
    [riwayatPekerjaan.jabatan.name]: "",
    [riwayatPekerjaan.gajiTerakhir.name]: "",
    [riwayatPekerjaan.namaAtasan.name]: "",
    [riwayatPekerjaan.kontakAtasan.name]: "",
    [riwayatPekerjaan.kontakPerusahaan.name]: "",
    [riwayatPekerjaan.totalBekerja.name]: "",
  },
  riwayatKursus: {
    [riwayatKursus.namaKursus.name]: "",
    [riwayatKursus.penyelenggara.name]: "",
    [riwayatKursus.tanggalMulai.name]: null,
    [riwayatKursus.tanggalAkhir.name]: null,
    [riwayatKursus.nomorSertifikat.name]: "",
    [riwayatKursus.tanggalSertifikat.name]: null,
    [riwayatKursus.predikat.name]: "",
    [riwayatKursus.nilai.name]: "",
    [riwayatKursus.isForever.name]: "",
  },
  riwayatSertifikasi: {
    [riwayatSertifikasi.namaPanjang.name]: "",
    [riwayatSertifikasi.namaSingkat.name]: "",
    [riwayatSertifikasi.tanggalSelamanya.name]: null,
    [riwayatSertifikasi.tanggalSertifikasi.name]: null,
    [riwayatSertifikasi.tanggalAkhir.name]: null,
    [riwayatSertifikasi.nomorSertifikasi.name]: "",
    [riwayatSertifikasi.namaLembaga.name]: "",
    [riwayatSertifikasi.predikat.name]: "",
    [riwayatSertifikasi.nilai.name]: "",
    [riwayatSertifikasi.isForever.name]: "",
  },
  informasiLamaran: {
    [informasiLamaran.posisiPekerjaan.name]: null,
    [informasiLamaran.harapanGaji.name]: "",
    [informasiLamaran.minatPenempatan.name]: "",
    [informasiLamaran.status.name]: "",
    [informasiLamaran.setuju.name]: false,
  },
  gantiPassword: {
    [gantiPassword.oldPassword.name]: "",
    [gantiPassword.newPassword.name]: "",
    [gantiPassword.konfirmasiPassword.name]: ""
  },
};

export default formInitial;
