import React, { useState } from "react";
import {
  Box,
  Card,
  Grid,
  Typography,
  makeStyles,
  Divider,
  CardActions,
  CardContent,
} from "@material-ui/core";
import { useHistory } from "react-router";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import moment from "moment";
import { config } from "../../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5vw 0",
  },
  content: {
    minHeight: "calc(100% - 35.2vw)",
    paddingTop: "10vw",
    paddingBottom: "5vw",
    background: "#F3F9FE",
  },
  cardContentRoot: {
    padding: 36,
  },
  lokerTitle: {
    color: theme.palette.primary.main,
    fontSize: "1.5vw",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  boxRoot: {
    alignItems: "center",
    display: "flex",
    marginBottom: 20,
  },
  iconBack: {
    marginRight: 15,
    cursor: "pointer",
  },
  boxContent: {
    marginBottom: 30,
  },
  subtitleLabel: {
    color: "#005A7D",
    marginBottom: 15,
  },
  contentLabel: {
    color: "#011627",
    fontWeight: "bold",
  },
  contentLabel1: {
    color: "#011627",
    fontWeight: "normal",
  },
  logo: {
    height: "8vw",
  },
  titleHeader: {
    color: "#005A7D",
    fontWeight: "bold",
  },
  subtitleHeader: {
    color: "#005A7D",
  },
  rootImg: {
    justifyContent: "center",
    display: "flex",
  },
  boxContentEvent: {
    justifyContent: "space-between",
    display: "flex",
  },
  subTitleContent: {
    color: "black",
    fontWeight: "bold",
  },
  cardActionsRoot: {
    padding: 36,
  },
  
}));

const DetailEventRecruitment = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [dataEvent] = useState(props.location.state.item);
  return (
    <Box className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={8}>
          <Box className={classes.boxRoot}>
            <ArrowBackIosIcon
              color="primary"
              className={classes.iconBack}
              fontSize="small"
              onClick={() => {
                history.goBack();
              }}
            />
            <Typography className={classes.lokerTitle}>
              Detail Event Recruitment
            </Typography>
          </Box>
          <Card>
            <CardContent className={classes.cardContentRoot}>
              <Grid container>
                <Grid item xs={8}>
                  <Box className={classes.boxContent}>
                    <Typography variant="h6" className={classes.titleHeader}>
                      {dataEvent.eventRecruitmentName}
                    </Typography>
                  </Box>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.subtitleHeader}
                      >
                        Tanggal Event
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.subtitleHeader}
                      >
                        Lokasi Event
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.subTitleContent}
                      >
                        {moment(dataEvent.eventRecruitmentDate).format(
                          "DD MMMM YYYY"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.subTitleContent}
                      >
                        {dataEvent.eventRecruitmentLocation}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions className={classes.cardActionsRoot}>
              <Typography variant="subtitle2">
              <Grid item>
                    {dataEvent.eventRecruitmentContent.split("\n").map((word, index) => (
                      <>
                        <div key={index} className={classes.contentLabel1}>
                          {word}
                        </div>
                      </>
                    ))}
                  </Grid>

              </Typography>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailEventRecruitment;
