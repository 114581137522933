import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  InputAdornment,
  FormControl,
  FormHelperText,
  Tooltip,
  withStyles,
  colors,
} from "@material-ui/core";
import { Event } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import GlobalFunction from "../../../GlobalFunction";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";
import { Autocomplete } from "@material-ui/lab";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: "0.9vw",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  header: {
    padding: "3vw",
    borderBottom: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: "1.1vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
    }
  },
  content: {
    padding: 0,
    "&&:last-child": {
      paddingBottom: 0,
    },
  },
  container: {
    padding: "2vw",
    borderBottom: "1px solid #DDDDDD",
  },
  grid: {
    padding: "1vw",
  },
  label: {
    fontSize: "0.8vw",
    textAlign: "left",
    marginBottom: "0.4vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.8rem",
    }
  },
  label1: {
    [theme.breakpoints.down(750)]: {
      fontSize: "0.6rem",
    }
  },
  note:{
    fontSize: "0.8vw",
    textAlign: "left",
    width:"100%",
    color:"red"
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  upload: {
    display: "flex",
    height: "40px",
  },
  buttonUpload: {
    fontSize: "0.9vw",
    fontWeight: "bold",
    height: "100%",
    paddingLeft: "2vw",
    paddingRight: "2vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "2vw",
      padding: "4vw",
      height: "0%",
    }
  },
  textUpload: {
    fontSize: "0.9vw",
    margin: "auto",
    marginLeft: "1vw",
    wordBreak: "break-all",
    [theme.breakpoints.down(750)]: {
      fontSize: "1.5vw",
      
    },
  },
  sizeFormHide1: {
    [theme.breakpoints.down(750)]: {
      display: "none",
    },

  },
  sizeFormHide2: {
    fontSize: "2vw",
    [theme.breakpoints.up(750)]: {
      display: "none",
      
    },
  },
}));

const Form = ({editDisabled, title, formik, identitasDiri, categoryId }) => {
  const classes = useStyles();
  const numberValidation = /^[0-9]*$/;
  const [loading, setLoading] = useState(false);
  const [lovCity, setLovCity] = useState([]);
  const [lovAgama, setLovAgama] = useState([]);
  const [lovKewarganegaraan, setLovKewarganegaraan] = useState([]);
  const [lovSuku, setLovSuku] = useState([]);
  const [lovStatusKawin, setLovStatusKawin] = useState([]);
  const [lovGolonganDarah, setLovGolonganDarah] = useState([]);
  const [lovWarnaKulit, setLovWarnaKulit] = useState([]);
  const [lovBentukMuka, setLovBentukMuka] = useState([]);
  const [lovJenisRambut, setLovJenisRambut] = useState([]);
  const [lovProgram, setLovProgram] = useState([]);
  // const isEdited = JSON.parse(localStorage.getItem("isEdited")) || editDisabled;
  const isEdited = editDisabled;

  useEffect(() => {
    getLovCity();
    getLovAgama();
    getLovKewarganegaraan();
    getLovSuku();
    getLovStatusKawin();
    getLovGolonganDarah();
    getLovWarnaKulit();
    getLovBentukMuka();
    getLovJenisRambut();
    getLovProgram();
  }, []);

  const getLovCity = () => {
    API(`register.getLovCity`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setLovCity(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data kota!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data kota!",
        });
      });
  };

  const getLovAgama = () => {
    API(`formulirKerja.getLovAgama`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setLovAgama(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data agama!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data agama!",
        });
      });
  };

  const getLovKewarganegaraan = () => {
    API(`formulirKerja.getLovKewarganegaraan`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setLovKewarganegaraan(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data kewarganegaraan!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data kewarganegaraan!",
        });
      });
  };

  const getLovSuku = () => {
    API(`formulirKerja.getLovSuku`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setLovSuku(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data suku!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data suku!",
        });
      });
  };

  const getLovStatusKawin = () => {
    API(`formulirKerja.getLovStatusKawin`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setLovStatusKawin(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data status perkawinan!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data status perkawinan!",
        });
      });
  };

  const getLovGolonganDarah = () => {
    API(`formulirKerja.getLovGolonganDarah`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setLovGolonganDarah(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data golongan darah!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data golongan darah!",
        });
      });
  };

  const getLovWarnaKulit = () => {
    API(`formulirKerja.getLovWarnaKulit`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setLovWarnaKulit(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data warna kulit!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data warna kulit!",
        });
      });
  };

  const getLovBentukMuka = () => {
    API(`formulirKerja.getLovBentukMuka`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setLovBentukMuka(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data bentuk muka!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data bentuk muka!",
        });
      });
  };

  const getLovJenisRambut = () => {
    API(`formulirKerja.getLovJenisRambut`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setLovJenisRambut(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data jenis rambut!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data jenis rambut!",
        });
      });
  };

  const getLovProgram = () => {
    if (parseInt(categoryId) === 3) {
      API(`formulirKerja.getLovProgram`)
        .then((res) => {
          if (res.status === 200 && res.data?.Message === "Success") {
            setLovProgram(res.data.Data);
          } else {
            Toast.fire({
              icon: "warning",
              title: "Gagal mendapat data program!",
            });
          }
        })
        .catch((res) => {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data program!",
          });
        });
    }
  };

  const uploadKtp = (dataImage) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", dataImage);
    API(`register.uploadKtp`, { data: formData })
      .then((res) => {
        console.log("ini cek res error");
        console.log(res);
        setLoading(false);
        if (res.status === 200) {
          console.log("masuk200");
          console.log(res);
          if (res.data.Status === "ACCEPTED") {
            formik.setFieldValue(
              `identitasDiri.${identitasDiri.fileKtp.name}`,
              res.data.FilePath
            );
            Toast.fire({
              icon: "success",
              title: res.data?.Message || "Upload KTP berhasil!",
            });
          }else{
            Toast.fire({
              icon: "warning",
              title: res.data?.Message || "Upload KTP gagal!",
            });

          }
        }else{
          Toast.fire({
            icon: "warning",
            title: res.data?.Message || "Upload KTP gagal!",
          });

        }
      })
      .catch((res) => {
        setLoading(false);
        console.log("ini cek cache");
        console.log(res);
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Upload KTP gagal!",
        });
      });
  };

  const uploadFile = (dataFile, type) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", dataFile);
    API(`formulirKerja.uploadFile`, {
      data: formData,
      params: { fileType: type },
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data?.Status === "ACCEPTED") {
            formik.setFieldValue(
              `identitasDiri.${identitasDiri.cv.name}`,
              res.data.FileName
            );
            formik.setFieldValue(
              `identitasDiri.${identitasDiri.cv.path}`,
              res.data.FilePath
            );
            Toast.fire({
              icon: "success",
              title: res.data?.Message || "Upload CV berhasil!",
            });
          }else{
            Toast.fire({
              icon: "warning",
              title: res.data?.Message || "Upload CV gagal!",
            });
          }
        }else{
          Toast.fire({
            icon: "warning",
            title: res.data?.Message || "Upload KTP gagal!",
          });

        }
      })
      .catch((res) => {
        setLoading(false);
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Upload CV gagal!",
        });
      });
  };

  const uploadImage = (dataImage, type) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", dataImage);
    API(`formulirKerja.uploadImage`, {
      data: formData,
      params: { fileType: type },
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data?.Status === "ACCEPTED" || res.data?.FilePath) {
            if (type === "pasfoto") {
              formik.setFieldValue(
                `identitasDiri.${identitasDiri.pasFoto.name}`,
                res.data.FilePath
              );
            } else if (type === "selfie") {
              formik.setFieldValue(
                `identitasDiri.${identitasDiri.selfieFoto.name}`,
                res.data.FilePath
              );
            } else if (type === "fotofullbody") {
              formik.setFieldValue(
                `identitasDiri.${identitasDiri.fullFoto.name}`,
                res.data.FilePath
              );
            }
            Toast.fire({
              icon: "success",
              title: res.data?.Message || "Upload berhasil!",
            });
          }else{
            Toast.fire({
              icon: "warning",
              title: res.data?.Message || "Upload gagal!",
            });
          }
        }else{
          Toast.fire({
            icon: "warning",
            title: res.data?.Message || "Upload KTP gagal!",
          });

        }
      })
      .catch((res) => {
        setLoading(false);
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Upload gagal! ",
        });
      });
  };

  return (
    <Card>
      <CardHeader
        className={classes.header}
        title={<Typography className={classes.title}>{title}</Typography>}
      />
      <CardContent className={classes.content}>
        {parseInt(categoryId) === 3 && (
          <Grid className={classes.container} container>
            <Grid className={classes.grid} item xs={12}>
              <Typography className={classes.label}>
                Program Jalur Khusus
              </Typography>
              <Autocomplete
                options={lovProgram}
                renderInput={(params) => (
                  <TextField
                    className={classes.fontInputan}
                    {...params}
                    variant="filled"
                    fullWidth
                    size="small"
                    name="programKhususApplicantList"
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.programKhususApplicantList &&
                      formik.errors.programKhususApplicantList &&
                      true
                    }
                    helperText={
                      formik.touched.programKhususApplicantList &&
                      formik.errors.programKhususApplicantList
                        ? formik.errors.programKhususApplicantList
                        : ""
                    }
                    label="Pilih Program"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
                getOptionLabel={(option) => option.namaProgram}
                value={
                  lovProgram?.find(
                    (item) =>
                      item.programId === formik.values.programKhususApplicantList
                  ) || null
                }
                onChange={(_event, newOption) => {
                  formik.setFieldValue(
                    'programKhususApplicantList',
                    newOption?.programId || null
                  );
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid className={classes.container} container>
          <Grid className={classes.grid} item xs={12}>
            <Typography className={classes.label}>
              {identitasDiri.namaLengkap.label}
            </Typography>
            <TextField
              className={classes.fontInputan}
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Nama Sesuai KTP"
              disabled={isEdited}
              InputProps={{
                disableUnderline: true,
              }}
              name={`identitasDiri.${identitasDiri.namaLengkap.name}`}
              value={
                formik.values.identitasDiri[`${identitasDiri.namaLengkap.name}`]
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.namaLengkap.name}`
                ] &&
                formik.errors.identitasDiri[
                  `${identitasDiri.namaLengkap.name}`
                ] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[
                  `${identitasDiri.namaLengkap.name}`
                ] &&
                formik.touched.identitasDiri[
                  `${identitasDiri.namaLengkap.name}`
                ]
                  ? formik.errors.identitasDiri[
                      `${identitasDiri.namaLengkap.name}`
                    ]
                  : ""
              }
            />
          </Grid>
          <Grid className={classes.grid} item xs={12}>
            <FormControl
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.jenisKelamin.name}`
                ] &&
                formik.errors.identitasDiri[
                  `${identitasDiri.jenisKelamin.name}`
                ] &&
                true
              }
            >
              <Typography className={classes.label}>
                {identitasDiri.jenisKelamin.label}
              </Typography>
              <RadioGroup
                row
                name={`identitasDiri.${identitasDiri.jenisKelamin.name}`}
                value={
                  formik.values.identitasDiri[
                    `${identitasDiri.jenisKelamin.name}`
                  ]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  disabled={isEdited}
                  value="0"
                  control={<Radio />}
                  label="Laki-Laki"
                />
                <FormControlLabel
                  disabled={isEdited}
                  value="1"
                  control={<Radio />}
                  label="Perempuan"
                />
              </RadioGroup>
              <FormHelperText>
                {formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.errors.identitasDiri[
                  `${identitasDiri.jenisKelamin.name}`
                ] &&
                formik.touched.identitasDiri[
                  `${identitasDiri.jenisKelamin.name}`
                ]
                  ? formik.errors.identitasDiri[
                      `${identitasDiri.jenisKelamin.name}`
                    ]
                  : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.nik.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Nomor Induk Kependudukan"
              // disabled={isEdited}
              disabled
              InputProps={{
                disableUnderline: true,
              }}
              name={`identitasDiri.${identitasDiri.nik.name}`}
              value={formik.values.identitasDiri[`${identitasDiri.nik.name}`]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[`${identitasDiri.nik.name}`] &&
                formik.errors.identitasDiri[`${identitasDiri.nik.name}`] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[`${identitasDiri.nik.name}`] &&
                formik.touched.identitasDiri[`${identitasDiri.nik.name}`]
                  ? formik.errors.identitasDiri[`${identitasDiri.nik.name}`]
                  : ""
              }
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <FormControl
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[`${identitasDiri.fileKtp.name}`] &&
                formik.errors.identitasDiri[`${identitasDiri.fileKtp.name}`] &&
                true
              }
            >
              <Typography className={classes.label}>
                {identitasDiri.fileKtp.label}
              </Typography>
              <Box className={classes.upload}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="upload-file-ktp"
                  type="file"
                  onChange={(val) => uploadKtp(val.target.files[0])}
                  name={`identitasDiri.${identitasDiri.fileKtp.name}`}
                  disabled={loading || isEdited}
                />
                <label htmlFor="upload-file-ktp">
                  <Button
                    className={classes.buttonUpload}
                    variant="outlined"
                    color="secondary"
                    component="span"
                    disabled={loading || localStorage.getItem("userKtpPath")}
                  >
                    {loading ? (
                      <i className="fas fa-circle-notch fa-spin" />
                    ) : (
                      ""
                    )}
                    Upload
                  </Button>
                </label>
                <Typography className={classes.textUpload}>
                  {GlobalFunction.getFileName(
                    formik.values.identitasDiri[`${identitasDiri.fileKtp.name}`]
                  )}
                </Typography>
              </Box>
              <FormHelperText>
                {formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[`${identitasDiri.fileKtp.name}`] &&
                formik.errors.identitasDiri[`${identitasDiri.fileKtp.name}`]
                  ? formik.errors.identitasDiri[`${identitasDiri.fileKtp.name}`]
                  : ""}
              </FormHelperText>
              <FormHelperText error className={classes.sizeFormHide1}>
                Maksimal file 3MB
              </FormHelperText>
              <FormHelperText error className={classes.sizeFormHide2}>
                Max 3MB
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.tempatLahir.label}
            </Typography>
            <Autocomplete
              options={lovCity}
              disabled={isEdited}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // disabled={isEdited}
                  variant="filled"
                  fullWidth
                  size="small"
                  name={`identitasDiri.${identitasDiri.tempatLahir.name}`}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.tempatLahir.name}`
                    ] &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.tempatLahir.name}`
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.tempatLahir.name}`
                    ] &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.tempatLahir.name}`
                    ]
                      ? formik.errors.identitasDiri[
                          `${identitasDiri.tempatLahir.name}`
                        ]
                      : ""
                  }
                  label="Pilih Tempat Lahir"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
              getOptionLabel={(option) => option.cityName}
              value={
                lovCity?.find(
                  (item) =>
                    item.cityName ===
                    formik.values.identitasDiri[
                      `${identitasDiri.tempatLahir.name}`
                    ]
                ) || null
              }
              onChange={(_event, newOption) => {
                formik.setFieldValue(
                  `identitasDiri.${identitasDiri.tempatLahir.name}`,
                  newOption?.cityName || null
                );
              }}
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.tanggalLahir.label}
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                hiddenLabel
                fullWidth
                disableFuture
                size="small"
                variant="dialog"
                inputVariant="filled"
                format="MM/dd/yyyy"
                placeholder="MM/DD/YYYY"
                disabled={isEdited}
                keyboardIcon={<Event className={classes.icon} />}
                InputProps={{
                  style: { padding: 0 },
                  disableUnderline: true,
                }}
                name={`identitasDiri.${identitasDiri.tanggalLahir.name}`}
                value={
                  formik.values.identitasDiri[
                    `${identitasDiri.tanggalLahir.name}`
                  ]
                }
                onChange={(val) =>
                  formik.setFieldValue(
                    `identitasDiri.${identitasDiri.tanggalLahir.name}`,
                    val
                  )
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.identitasDiri &&
                  formik.errors.identitasDiri &&
                  formik.touched.identitasDiri[
                    `${identitasDiri.tanggalLahir.name}`
                  ] &&
                  formik.errors.identitasDiri[
                    `${identitasDiri.tanggalLahir.name}`
                  ] &&
                  true
                }
                helperText={
                  formik.errors.identitasDiri &&
                  formik.touched.identitasDiri &&
                  formik.errors.identitasDiri[
                    `${identitasDiri.tanggalLahir.name}`
                  ] &&
                  formik.touched.identitasDiri[
                    `${identitasDiri.tanggalLahir.name}`
                  ]
                    ? formik.errors.identitasDiri[
                        `${identitasDiri.tanggalLahir.name}`
                      ]
                    : ""
                }
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.email.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Alamat Email"
              disabled
              InputProps={{
                disableUnderline: true,
              }}
              name={`identitasDiri.${identitasDiri.email.name}`}
              value={formik.values.identitasDiri[`${identitasDiri.email.name}`]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[`${identitasDiri.email.name}`] &&
                formik.errors.identitasDiri[`${identitasDiri.email.name}`] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[`${identitasDiri.email.name}`] &&
                formik.touched.identitasDiri[`${identitasDiri.email.name}`]
                  ? formik.errors.identitasDiri[`${identitasDiri.email.name}`]
                  : ""
              }
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.nomorHandphone.label2}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Contoh : 08123456789"
              disabled={isEdited}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                maxLength: identitasDiri.nomorHandphone.maximum,
              }}
              name={`identitasDiri.${identitasDiri.nomorHandphone.name}`}
              value={
                formik.values.identitasDiri[
                  `${identitasDiri.nomorHandphone.name}`
                ]
              }
              onChange={(e) =>
                numberValidation.test(e.target.value) && formik.handleChange(e)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.nomorHandphone.name}`
                ] &&
                formik.errors.identitasDiri[
                  `${identitasDiri.nomorHandphone.name}`
                ] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[
                  `${identitasDiri.nomorHandphone.name}`
                ] &&
                formik.touched.identitasDiri[
                  `${identitasDiri.nomorHandphone.name}`
                ]
                  ? formik.errors.identitasDiri[
                      `${identitasDiri.nomorHandphone.name}`
                    ]
                  : ""
              }
            />
          </Grid>
          {/* <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.password.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              type={"password"}
              placeholder="Masukkan Password"
              InputProps={{
                style: { padding: 0 },
                disableUnderline: true,
              }}
              defaultValue="12345"
              disabled
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.konfirmasiPassword.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              type={"password"}
              placeholder="Masukkan Password"
              InputProps={{
                style: { padding: 0 },
                disableUnderline: true,
              }}
              defaultValue="12345"
              disabled
            />
          </Grid> */}
        </Grid>
        <Grid className={classes.container} container>
          <Grid className={classes.grid} item xs={12}>
            <Typography className={classes.label}>
              {identitasDiri.domisiliKtp.label}
            </Typography>
            <Autocomplete
              options={lovCity}
              renderInput={(params) => (
                <TextField
                // ini kota
   
                  {...params}
                  className={classes.typography}
                  variant="filled"
                  fullWidth
                  size="small"
                  name={`identitasDiri.${identitasDiri.domisiliKtp.name}`}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.domisiliKtp.name}`
                    ] &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.domisiliKtp.name}`
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.domisiliKtp.name}`
                    ] &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.domisiliKtp.name}`
                    ]
                      ? formik.errors.identitasDiri[
                          `${identitasDiri.domisiliKtp.name}`
                        ]
                      : ""
                  }
                  
                  label="Pilih Kota"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
              getOptionLabel={(option) => option.cityName}
              value={
                lovCity?.find(
                  (item) =>
                    item.cityName ===
                    formik.values.identitasDiri[
                      `${identitasDiri.domisiliKtp.name}`
                    ]
                ) || null
              }
              onChange={(_event, newOption) => {
                formik.setFieldValue(
                  `identitasDiri.${identitasDiri.domisiliKtp.name}`,
                  newOption?.cityName || null
                );
              }}
            />
          </Grid>
          <Grid className={classes.grid} item xs={12}>
            <Typography className={classes.label}>
              {identitasDiri.alamatKtp.label}
            </Typography>
            <TextField
            className={classes.fontInputan}
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Masukkan Alamat"
              InputProps={{
                disableUnderline: true,
              }}
              name={`identitasDiri.${identitasDiri.alamatKtp.name}`}
              value={
                formik.values.identitasDiri[`${identitasDiri.alamatKtp.name}`]
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.alamatKtp.name}`
                ] &&
                formik.errors.identitasDiri[
                  `${identitasDiri.alamatKtp.name}`
                ] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[
                  `${identitasDiri.alamatKtp.name}`
                ] &&
                formik.touched.identitasDiri[`${identitasDiri.alamatKtp.name}`]
                  ? formik.errors.identitasDiri[
                      `${identitasDiri.alamatKtp.name}`
                    ]
                  : ""
              }
            />
          </Grid>
          <Grid className={classes.grid} item xs={12}>
            <Typography className={classes.label}>
              {identitasDiri.domisiliSekarang.label}
            </Typography>
            <Autocomplete
              options={lovCity}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  size="small"
                  name={`identitasDiri.${identitasDiri.domisiliSekarang.name}`}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.domisiliSekarang.name}`
                    ] &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.domisiliSekarang.name}`
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.domisiliSekarang.name}`
                    ] &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.domisiliSekarang.name}`
                    ]
                      ? formik.errors.identitasDiri[
                          `${identitasDiri.domisiliSekarang.name}`
                        ]
                      : ""
                  }
                  label="Pilih Kota"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
              getOptionLabel={(option) => option.cityName}
              value={
                lovCity?.find(
                  (item) =>
                    item.cityName ===
                    formik.values.identitasDiri[
                      `${identitasDiri.domisiliSekarang.name}`
                    ]
                ) || null
              }
              onChange={(_event, newOption) => {
                formik.setFieldValue(
                  `identitasDiri.${identitasDiri.domisiliSekarang.name}`,
                  newOption?.cityName || null
                );
              }}
            />
          </Grid>
          <Grid className={classes.grid} item xs={12}>
            <Typography className={classes.label}>
              {identitasDiri.alamatSekarang.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Masukkan Alamat"
              InputProps={{
                disableUnderline: true,
              }}
              name={`identitasDiri.${identitasDiri.alamatSekarang.name}`}
              value={
                formik.values.identitasDiri[
                  `${identitasDiri.alamatSekarang.name}`
                ]
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.alamatSekarang.name}`
                ] &&
                formik.errors.identitasDiri[
                  `${identitasDiri.alamatSekarang.name}`
                ] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[
                  `${identitasDiri.alamatSekarang.name}`
                ] &&
                formik.touched.identitasDiri[
                  `${identitasDiri.alamatSekarang.name}`
                ]
                  ? formik.errors.identitasDiri[
                      `${identitasDiri.alamatSekarang.name}`
                    ]
                  : ""
              }
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.nomorKk.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Masukkan No. KK"
              InputProps={{
                disableUnderline: true,
              }}
              name={`identitasDiri.${identitasDiri.nomorKk.name}`}
              value={
                formik.values.identitasDiri[`${identitasDiri.nomorKk.name}`]
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[`${identitasDiri.nomorKk.name}`] &&
                formik.errors.identitasDiri[`${identitasDiri.nomorKk.name}`] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[`${identitasDiri.nomorKk.name}`] &&
                formik.touched.identitasDiri[`${identitasDiri.nomorKk.name}`]
                  ? formik.errors.identitasDiri[`${identitasDiri.nomorKk.name}`]
                  : ""
              }
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.npwp.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Masukkan NPWP"
              InputProps={{
                disableUnderline: true,
              }}
              name={`identitasDiri.${identitasDiri.npwp.name}`}
              value={formik.values.identitasDiri[`${identitasDiri.npwp.name}`]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[`${identitasDiri.npwp.name}`] &&
                formik.errors.identitasDiri[`${identitasDiri.npwp.name}`] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[`${identitasDiri.npwp.name}`] &&
                formik.touched.identitasDiri[`${identitasDiri.npwp.name}`]
                  ? formik.errors.identitasDiri[`${identitasDiri.npwp.name}`]
                  : ""
              }
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.agama.label}
            </Typography>
            <Autocomplete
              options={lovAgama}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  size="small"
                  name={`identitasDiri.${identitasDiri.agama.name}`}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.agama.name}`
                    ] &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.agama.name}`
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.agama.name}`
                    ] &&
                    formik.touched.identitasDiri[`${identitasDiri.agama.name}`]
                      ? formik.errors.identitasDiri[
                          `${identitasDiri.agama.name}`
                        ]
                      : ""
                  }
                  label="Pilih Agama"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
              getOptionLabel={(option) => option.nama}
              value={
                lovAgama?.find(
                  (item) =>
                    item.nama ===
                    formik.values.identitasDiri[`${identitasDiri.agama.name}`]
                ) || null
              }
              onChange={(_event, newOption) => {
                formik.setFieldValue(
                  `identitasDiri.${identitasDiri.agama.name}`,
                  newOption?.nama || null
                );
              }}
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.kewarganegaraan.label}
            </Typography>
            <Autocomplete
              options={lovKewarganegaraan}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  size="small"
                  name={`identitasDiri.${identitasDiri.kewarganegaraan.name}`}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.kewarganegaraan.name}`
                    ] &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.kewarganegaraan.name}`
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.kewarganegaraan.name}`
                    ] &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.kewarganegaraan.name}`
                    ]
                      ? formik.errors.identitasDiri[
                          `${identitasDiri.kewarganegaraan.name}`
                        ]
                      : ""
                  }
                  label="Pilih Kewarganegaraan"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
              getOptionLabel={(option) => option.nama}
              value={
                lovKewarganegaraan?.find(
                  (item) =>
                    item.nama ===
                    formik.values.identitasDiri[
                      `${identitasDiri.kewarganegaraan.name}`
                    ]
                ) || null
              }
              onChange={(_event, newOption) => {
                formik.setFieldValue(
                  `identitasDiri.${identitasDiri.kewarganegaraan.name}`,
                  newOption?.nama || null
                );
              }}
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.suku.label}
            </Typography>
            <Autocomplete
              options={lovSuku}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  size="small"
                  name={`identitasDiri.${identitasDiri.suku.name}`}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.suku.name}`
                    ] &&
                    formik.errors.identitasDiri[`${identitasDiri.suku.name}`] &&
                    true
                  }
                  helperText={
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri[`${identitasDiri.suku.name}`] &&
                    formik.touched.identitasDiri[`${identitasDiri.suku.name}`]
                      ? formik.errors.identitasDiri[
                          `${identitasDiri.suku.name}`
                        ]
                      : ""
                  }
                  label="Pilih Suku"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
              getOptionLabel={(option) => option.nama}
              value={
                lovSuku?.find(
                  (item) =>
                    item.nama ===
                    formik.values.identitasDiri[`${identitasDiri.suku.name}`]
                ) || null
              }
              onChange={(_event, newOption) => {
                formik.setFieldValue(
                  `identitasDiri.${identitasDiri.suku.name}`,
                  newOption?.nama || null
                );
              }}
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.statusKawin.label}
            </Typography>
            <Autocomplete
              options={lovStatusKawin}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  size="small"
                  name={`identitasDiri.${identitasDiri.statusKawin.name}`}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.statusKawin.name}`
                    ] &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.statusKawin.name}`
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.statusKawin.name}`
                    ] &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.statusKawin.name}`
                    ]
                      ? formik.errors.identitasDiri[
                          `${identitasDiri.statusKawin.name}`
                        ]
                      : ""
                  }
                  label="Pilih Status"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
              getOptionLabel={(option) => option.nama}
              value={
                lovStatusKawin?.find(
                  (item) =>
                    item.nama ===
                    formik.values.identitasDiri[
                      `${identitasDiri.statusKawin.name}`
                    ]
                ) || null
              }
              onChange={(_event, newOption) => {
                formik.setFieldValue(
                  `identitasDiri.${identitasDiri.statusKawin.name}`,
                  newOption?.nama || null
                );
              }}
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.tinggiBadan.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Masukkan Tinggi Badan"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">cm</InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: identitasDiri.tinggiBadan.maximum,
              }}
              name={`identitasDiri.${identitasDiri.tinggiBadan.name}`}
              value={
                formik.values.identitasDiri[`${identitasDiri.tinggiBadan.name}`]
              }
              onChange={(e) =>
                numberValidation.test(e.target.value) && e.target.value !== ""
                  ? formik.setFieldValue(
                      `identitasDiri.${identitasDiri.tinggiBadan.name}`,
                      parseInt(e.target.value)
                    )
                  : formik.setFieldValue(
                      `identitasDiri.${identitasDiri.tinggiBadan.name}`,
                      ""
                    )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.tinggiBadan.name}`
                ] &&
                formik.errors.identitasDiri[
                  `${identitasDiri.tinggiBadan.name}`
                ] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[
                  `${identitasDiri.tinggiBadan.name}`
                ] &&
                formik.touched.identitasDiri[
                  `${identitasDiri.tinggiBadan.name}`
                ]
                  ? formik.errors.identitasDiri[
                      `${identitasDiri.tinggiBadan.name}`
                    ]
                  : ""
              }
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.beratBadan.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Masukkan Berat Badan"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">kg</InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: identitasDiri.beratBadan.maximum,
              }}
              name={`identitasDiri.${identitasDiri.beratBadan.name}`}
              value={
                formik.values.identitasDiri[`${identitasDiri.beratBadan.name}`]
              }
              onChange={(e) =>
                numberValidation.test(e.target.value) && e.target.value !== ""
                  ? formik.setFieldValue(
                      `identitasDiri.${identitasDiri.beratBadan.name}`,
                      parseInt(e.target.value)
                    )
                  : formik.setFieldValue(
                      `identitasDiri.${identitasDiri.beratBadan.name}`,
                      ""
                    )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.beratBadan.name}`
                ] &&
                formik.errors.identitasDiri[
                  `${identitasDiri.beratBadan.name}`
                ] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[
                  `${identitasDiri.beratBadan.name}`
                ] &&
                formik.touched.identitasDiri[`${identitasDiri.beratBadan.name}`]
                  ? formik.errors.identitasDiri[
                      `${identitasDiri.beratBadan.name}`
                    ]
                  : ""
              }
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.golonganDarah.label}
            </Typography>
            <Autocomplete
              options={lovGolonganDarah}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  size="small"
                  name={`identitasDiri.${identitasDiri.golonganDarah.name}`}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.golonganDarah.name}`
                    ] &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.golonganDarah.name}`
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.golonganDarah.name}`
                    ] &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.golonganDarah.name}`
                    ]
                      ? formik.errors.identitasDiri[
                          `${identitasDiri.golonganDarah.name}`
                        ]
                      : ""
                  }
                  label="Pilih Golongan Darah"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
              getOptionLabel={(option) => option.nama}
              value={
                lovGolonganDarah?.find(
                  (item) =>
                    item.nama ===
                    formik.values.identitasDiri[
                      `${identitasDiri.golonganDarah.name}`
                    ]
                ) || null
              }
              onChange={(_event, newOption) => {
                formik.setFieldValue(
                  `identitasDiri.${identitasDiri.golonganDarah.name}`,
                  newOption?.nama || null
                );
              }}
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.warnaKulit.label}
            </Typography>
            <Autocomplete
              options={lovWarnaKulit}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  size="small"
                  name={`identitasDiri.${identitasDiri.warnaKulit.name}`}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.warnaKulit.name}`
                    ] &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.warnaKulit.name}`
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.warnaKulit.name}`
                    ] &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.warnaKulit.name}`
                    ]
                      ? formik.errors.identitasDiri[
                          `${identitasDiri.warnaKulit.name}`
                        ]
                      : ""
                  }
                  label="Pilih Warna Kulit"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
              getOptionLabel={(option) => option.nama}
              value={
                lovWarnaKulit?.find(
                  (item) =>
                    item.nama ===
                    formik.values.identitasDiri[
                      `${identitasDiri.warnaKulit.name}`
                    ]
                ) || null
              }
              onChange={(_event, newOption) => {
                formik.setFieldValue(
                  `identitasDiri.${identitasDiri.warnaKulit.name}`,
                  newOption?.nama || null
                );
              }}
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.bentukMuka.label}
            </Typography>
            <Autocomplete
              options={lovBentukMuka}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  size="small"
                  name={`identitasDiri.${identitasDiri.bentukMuka.name}`}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.bentukMuka.name}`
                    ] &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.bentukMuka.name}`
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.bentukMuka.name}`
                    ] &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.bentukMuka.name}`
                    ]
                      ? formik.errors.identitasDiri[
                          `${identitasDiri.bentukMuka.name}`
                        ]
                      : ""
                  }
                  label="Pilih Bentuk Muka"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
              getOptionLabel={(option) => option.bentukWajahName}
              value={
                lovBentukMuka?.find(
                  (item) =>
                    item.bentukWajahName ===
                    formik.values.identitasDiri[
                      `${identitasDiri.bentukMuka.name}`
                    ]
                ) || null
              }
              onChange={(_event, newOption) => {
                formik.setFieldValue(
                  `identitasDiri.${identitasDiri.bentukMuka.name}`,
                  newOption?.bentukWajahName || null
                );
              }}
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.jenisRambut.label}
            </Typography>
            <Autocomplete
              options={lovJenisRambut}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  size="small"
                  name={`identitasDiri.${identitasDiri.jenisRambut.name}`}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.jenisRambut.name}`
                    ] &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.jenisRambut.name}`
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.identitasDiri &&
                    formik.touched.identitasDiri &&
                    formik.errors.identitasDiri[
                      `${identitasDiri.jenisRambut.name}`
                    ] &&
                    formik.touched.identitasDiri[
                      `${identitasDiri.jenisRambut.name}`
                    ]
                      ? formik.errors.identitasDiri[
                          `${identitasDiri.jenisRambut.name}`
                        ]
                      : ""
                  }
                  label="Pilih Jenis Rambut"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
              getOptionLabel={(option) => option.nama}
              value={
                lovJenisRambut?.find(
                  (item) =>
                    item.nama ===
                    formik.values.identitasDiri[
                      `${identitasDiri.jenisRambut.name}`
                    ]
                ) || null
              }
              onChange={(_event, newOption) => {
                formik.setFieldValue(
                  `identitasDiri.${identitasDiri.jenisRambut.name}`,
                  newOption?.nama || null
                );
              }}
            />
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.bisaInggris.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              select
              size="small"
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              name={`identitasDiri.${identitasDiri.bisaInggris.name}`}
              value={
                formik.values.identitasDiri[`${identitasDiri.bisaInggris.name}`]
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.bisaInggris.name}`
                ] &&
                formik.errors.identitasDiri[
                  `${identitasDiri.bisaInggris.name}`
                ] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[
                  `${identitasDiri.bisaInggris.name}`
                ] &&
                formik.touched.identitasDiri[
                  `${identitasDiri.bisaInggris.name}`
                ]
                  ? formik.errors.identitasDiri[
                      `${identitasDiri.bisaInggris.name}`
                    ]
                  : ""
              }
              SelectProps={{
                displayEmpty: true,
              }}
            >
              <MenuItem disabled value="">
                Pilih Jawaban
              </MenuItem>
              <MenuItem value={true}>Aktif</MenuItem>
              <MenuItem value={false}>Pasif</MenuItem>
            </TextField>
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.bisaQuran.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              select
              size="small"
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              name={`identitasDiri.${identitasDiri.bisaQuran.name}`}
              value={
                formik.values.identitasDiri[`${identitasDiri.bisaQuran.name}`]
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.bisaQuran.name}`
                ] &&
                formik.errors.identitasDiri[
                  `${identitasDiri.bisaQuran.name}`
                ] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[
                  `${identitasDiri.bisaQuran.name}`
                ] &&
                formik.touched.identitasDiri[`${identitasDiri.bisaQuran.name}`]
                  ? formik.errors.identitasDiri[
                      `${identitasDiri.bisaQuran.name}`
                    ]
                  : ""
              }
              SelectProps={{
                displayEmpty: true,
              }}
            >
              <MenuItem disabled value="">
                Pilih Jawaban
              </MenuItem>
              <MenuItem value={true}>Lancar</MenuItem>
              <MenuItem value={false}>Tidak Lancar</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid className={classes.container} container>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.instagram.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Nama / ID Akun"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{ maxLength: identitasDiri.instagram.maximum }}
              name={`identitasDiri.${identitasDiri.instagram.name}`}
              value={
                formik.values.identitasDiri[`${identitasDiri.instagram.name}`]
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.instagram.name}`
                ] &&
                formik.errors.identitasDiri[
                  `${identitasDiri.instagram.name}`
                ] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[
                  `${identitasDiri.instagram.name}`
                ] &&
                formik.touched.identitasDiri[`${identitasDiri.instagram.name}`]
                  ? formik.errors.identitasDiri[
                      `${identitasDiri.instagram.name}`
                    ]
                  : ""
              }
            />
            <FormHelperText error>
              Apabila tidak memiliki akun Instagram harap isi "-" 
            </FormHelperText>
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.twitter.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Nama / ID Akun"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{ maxLength: identitasDiri.twitter.maximum }}
              name={`identitasDiri.${identitasDiri.twitter.name}`}
              value={
                formik.values.identitasDiri[`${identitasDiri.twitter.name}`]
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[`${identitasDiri.twitter.name}`] &&
                formik.errors.identitasDiri[`${identitasDiri.twitter.name}`] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[`${identitasDiri.twitter.name}`] &&
                formik.touched.identitasDiri[`${identitasDiri.twitter.name}`]
                  ? formik.errors.identitasDiri[`${identitasDiri.twitter.name}`]
                  : ""
              }
            />
            <FormHelperText error>
              Apabila tidak memiliki akun Twitter harap isi "-" 
            </FormHelperText>
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.facebook.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Nama / ID Akun"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{ maxLength: identitasDiri.facebook.maximum }}
              name={`identitasDiri.${identitasDiri.facebook.name}`}
              value={
                formik.values.identitasDiri[`${identitasDiri.facebook.name}`]
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.facebook.name}`
                ] &&
                formik.errors.identitasDiri[`${identitasDiri.facebook.name}`] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[`${identitasDiri.facebook.name}`] &&
                formik.touched.identitasDiri[`${identitasDiri.facebook.name}`]
                  ? formik.errors.identitasDiri[
                      `${identitasDiri.facebook.name}`
                    ]
                  : ""
              }
            />
            <FormHelperText error>
              Apabila tidak memiliki akun Facebook harap isi "-" 
            </FormHelperText>
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              {identitasDiri.linkedIn.label}
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Nama / ID Akun"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{ maxLength: identitasDiri.linkedIn.maximum }}
              name={`identitasDiri.${identitasDiri.linkedIn.name}`}
              value={
                formik.values.identitasDiri[`${identitasDiri.linkedIn.name}`]
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.linkedIn.name}`
                ] &&
                formik.errors.identitasDiri[`${identitasDiri.linkedIn.name}`] &&
                true
              }
              helperText={
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri[`${identitasDiri.linkedIn.name}`] &&
                formik.touched.identitasDiri[`${identitasDiri.linkedIn.name}`]
                  ? formik.errors.identitasDiri[
                      `${identitasDiri.linkedIn.name}`
                    ]
                  : ""
              }
            />
            <FormHelperText error>
              Apabila tidak memiliki akun LinkedIn harap isi "-" 
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid className={classes.container} container>
          <Grid className={classes.grid} item xs={6}>
            <FormControl
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[`${identitasDiri.pasFoto.name}`] &&
                formik.errors.identitasDiri[`${identitasDiri.pasFoto.name}`] &&
                true
              }
            >
              <Typography className={classes.label}>
                {identitasDiri.pasFoto.label}
              </Typography>
              <Box className={classes.upload}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="upload-file-pasFoto"
                  type="file"
                  onChange={(val) =>
                    uploadImage(val.target.files[0], "pasfoto")
                  }
                  name={`identitasDiri.${identitasDiri.pasFoto.name}`}
                />
                <label htmlFor="upload-file-pasFoto">
                  <Button
                    className={classes.buttonUpload}
                    variant="outlined"
                    color="secondary"
                    component="span"
                    disabled={loading}
                  >
                    {loading ? (
                      <i className="fas fa-circle-notch fa-spin" />
                    ) : (
                      ""
                    )}
                    Upload
                  </Button>
                </label>
                <Typography className={classes.textUpload}>
                  {GlobalFunction.getFileName(
                    formik.values.identitasDiri[`${identitasDiri.pasFoto.name}`]
                  )}
                </Typography>
              </Box>
              {/* <Typography className={classes.label1}>
                {identitasDiri.pasFoto.label1}
              </Typography> */}
              <FormHelperText>
                {formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[`${identitasDiri.pasFoto.name}`] &&
                formik.errors.identitasDiri[`${identitasDiri.pasFoto.name}`]
                  ? formik.errors.identitasDiri[`${identitasDiri.pasFoto.name}`]
                  : ""}
              </FormHelperText>
              <FormHelperText error className={classes.sizeFormHide1}>
                Maksimal file 3MB
              </FormHelperText>
              <FormHelperText error className={classes.sizeFormHide2}>
              jpg/jpeg/png (max 3MB)
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <FormControl
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.fullFoto.name}`
                ] &&
                formik.errors.identitasDiri[`${identitasDiri.fullFoto.name}`] &&
                true
              }
            >
              <Typography className={classes.label}>
                {identitasDiri.fullFoto.label}
              </Typography>
              <Box className={classes.upload}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="upload-file-fullFoto"
                  type="file"
                  onChange={(val) =>
                    uploadImage(val.target.files[0], "fotofullbody")
                  }
                  name={`identitasDiri.${identitasDiri.fullFoto.name}`}
                />
                <label htmlFor="upload-file-fullFoto">
                  <Button
                    className={classes.buttonUpload}
                    variant="outlined"
                    color="secondary"
                    component="span"
                    disabled={loading}
                  >
                    {loading ? (
                      <i className="fas fa-circle-notch fa-spin" />
                    ) : (
                      ""
                    )}
                    Upload
                  </Button>
                </label>
                <Typography className={classes.textUpload}>
                  {GlobalFunction.getFileName(
                    formik.values.identitasDiri[
                      `${identitasDiri.fullFoto.name}`
                    ]
                  )}
                </Typography>
              </Box>
              {/* <Typography className={classes.label1}>
                {identitasDiri.fullFoto.label1}
              </Typography> */}
              <FormHelperText>
                {formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.fullFoto.name}`
                ] &&
                formik.errors.identitasDiri[`${identitasDiri.fullFoto.name}`]
                  ? formik.errors.identitasDiri[
                      `${identitasDiri.fullFoto.name}`
                    ]
                  : ""}
              </FormHelperText>
              <FormHelperText error className={classes.sizeFormHide1}>
                Maksimal file 3MB
              </FormHelperText>
              <FormHelperText error className={classes.sizeFormHide2}>
              jpg/jpeg/png (max 3MB)
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <FormControl
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.selfieFoto.name}`
                ] &&
                formik.errors.identitasDiri[
                  `${identitasDiri.selfieFoto.name}`
                ] &&
                true
              }
            >
              <Typography className={classes.label}>
                {identitasDiri.selfieFoto.label}
              </Typography>
              <Box className={classes.upload}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="upload-file-selfieFoto"
                  type="file"
                  onChange={(val) => uploadImage(val.target.files[0], "selfie")}
                  name={`identitasDiri.${identitasDiri.selfieFoto.name}`}
                />
                <label htmlFor="upload-file-selfieFoto">
                  <Button
                    className={classes.buttonUpload}
                    variant="outlined"
                    color="secondary"
                    component="span"
                    disabled={loading}
                  >
                    {loading ? (
                      <i className="fas fa-circle-notch fa-spin" />
                    ) : (
                      ""
                    )}
                    Upload
                  </Button>
                </label>
                <Typography className={classes.textUpload}>
                  {GlobalFunction.getFileName(
                    formik.values.identitasDiri[
                      `${identitasDiri.selfieFoto.name}`
                    ]
                  )}
                </Typography>
              </Box>
              {/* <Typography className={classes.label1}>
                {identitasDiri.selfieFoto.label1}
              </Typography> */}
              <FormHelperText>
                {formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[
                  `${identitasDiri.selfieFoto.name}`
                ] &&
                formik.errors.identitasDiri[`${identitasDiri.selfieFoto.name}`]
                  ? formik.errors.identitasDiri[
                      `${identitasDiri.selfieFoto.name}`
                    ]
                  : ""}
              </FormHelperText>
              <FormHelperText error className={classes.sizeFormHide1}>
                Maksimal file 3MB
              </FormHelperText>
              <FormHelperText error className={classes.sizeFormHide2}>
              jpg/jpeg/png (max 3MB)
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <FormControl
              error={
                formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[`${identitasDiri.cv.name}`] &&
                formik.errors.identitasDiri[`${identitasDiri.cv.name}`] &&
                true
              }
            >
              <Typography className={classes.label}>
                {identitasDiri.cv.label}
              </Typography>
              <Box className={classes.upload}>
                <input
                  accept="application/pdf"
                  style={{ display: "none" }}
                  id="upload-file-cv"
                  type="file"
                  onChange={(val) => uploadFile(val.target.files[0], "cv")}
                  name={`identitasDiri.${identitasDiri.cv.name}`}
                />
                <label htmlFor="upload-file-cv">
                  <Button
                    className={classes.buttonUpload}
                    variant="outlined"
                    color="secondary"
                    component="span"
                    disabled={loading}
                  >
                    {loading ? (
                      <i className="fas fa-circle-notch fa-spin" />
                    ) : (
                      ""
                    )}
                    Upload
                  </Button>
                </label>
                <Typography className={classes.textUpload}>
                  {GlobalFunction.getFileName(
                    formik.values.identitasDiri[`${identitasDiri.cv.name}`]
                  )}
                </Typography>
              </Box>
              {/* <Typography className={classes.label}>
                {identitasDiri.cv.label1}
              </Typography> */}
              <FormHelperText>
                {formik.touched.identitasDiri &&
                formik.errors.identitasDiri &&
                formik.touched.identitasDiri[`${identitasDiri.cv.name}`] &&
                formik.errors.identitasDiri[`${identitasDiri.cv.name}`]
                  ? formik.errors.identitasDiri[`${identitasDiri.cv.name}`]
                  : ""}
              </FormHelperText>
              <FormHelperText error className={classes.sizeFormHide1}>
                Maksimal file 3MB
              </FormHelperText>
              <FormHelperText error className={classes.sizeFormHide2}>
                pdf (Max 3MB)
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Form;
