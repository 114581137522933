import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";

// Import Component
import Banner from "./Banner";
import VideoRecruitment from "./VideoRecruitment";
import NilaiNilaiBudaya from "./NilaiNilaiBudaya";
import ProgramSingkat from "./ProgramSingkat";
import LowonganPekerjaan from "./LowonganPekerjaan";
import AlurSeleksi from "./AlurSeleksi";
import EventRecruitment from "./EventRecruitment";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";
import TentangKami from "./TentangKami";
import Footer from "../../Component/Footer";

const Home = ({ innerRef }) => {
  const [loading, setLoading] = useState(true);
  const isLogin = localStorage.getItem("isLogin");
  const [Section, setSection] = useState([
    {
      websiteContentNameSection: "Hero Section",
      section: <Banner innerRef={innerRef} />,
    },
    {
      websiteContentNameSection: "Video Recruitment",
      section: <VideoRecruitment innerRef={innerRef.video} />,
    },
    {
      websiteContentNameSection: "Nilai-Nilai Budaya Section",
      section: <NilaiNilaiBudaya innerRef={innerRef.nilai} />,
    },
    {
      websiteContentNameSection: "Program Singkat",
      section: <ProgramSingkat innerRef={innerRef.program} />,
    },
    {
      websiteContentNameSection: "Lowongan Pekerjaan",
      section: <LowonganPekerjaan innerRef={innerRef.lowongan} />,
    },
    {
      websiteContentNameSection: "Alur Seleksi",
      section: <AlurSeleksi innerRef={innerRef.alur} />,
    },
    {
      websiteContentNameSection: "Event Recruitment",
      section: <EventRecruitment />,
    },
    { websiteContentNameSection: "Tentang Kami", section: <TentangKami /> },
    { websiteContentNameSection: "Footer Section", section: <Footer /> },
  ]);

  useEffect(() => {
    localStorage.setItem("categoryApplicant", "")
    if (isLogin) {
      getSection("secured");
      getSubmitValidation();
    } else {
      getSection("open");
    }

    setSection([]);
  }, [isLogin]);

  const getSubmitValidation = () => {
    API(`validate.getSubmitValidation`, {
      params: { applicantId: localStorage.getItem("applicantId") },
    })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem(
            "submitValidation",
            res?.data?.haveApplicantApplied
          );
        } else {
          Toast.fire({
            icon: "warning",
            title: res?.data?.message || "Gagal mendapat validasi submit!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title:
            res?.response?.data?.message || "Gagal mendapat validasi submit!",
        });
      });
  };

  const getSection = (type) => {
    const url =
      type === "open" ? "home.getSectionOpen" : "home.getSectionSecured";

    setLoading(true);

    API(`${url}`)
      .then((res) => {
        setLoading(false);
        if (res.status === 200 && res.data?.Message === "Success") {
          var newSection = [];
          for (let i = 0; i < res.data.Data.length; i++) {
            for (let n = 0; n < Section.length; n++) {
              if (
                res.data.Data[i].websiteContentNameSection ===
                Section[n].websiteContentNameSection
              ) {
                newSection.push(Section[n]);
                break;
              }
            }
          }

          setSection(newSection);
        }
      })
      .catch((res) => {
        setLoading(false);
      });
  };

  return (
    <Box>
      {loading ? (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div>
          {Section.map((item, i) => (
            <div key={i}>{item.section}</div>
          ))}
        </div>
      )}
    </Box>
  );
};

export default Home;
