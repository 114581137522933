import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  makeStyles,
  Box,
  Grid,
  Card,
  Paper,
  Typography,
  Radio,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import { ArrowBackIosRounded } from "@material-ui/icons";
import IdentitasDiri from "./IdentitasDiri";
import RiwayatPendidikan from "./RiwayatPendidikan";
import RiwayatOrganisasi from "./RiwayatOrganisasi";
import RiwayatPekerjaan from "./RiwayatPekerjaan";
import RiwayatKursus from "./RiwayatKursus";
import RiwayatSertifikasi from "./RiwayatSertifikasi";
import InformasiLamaranKerja from "./InformasiLamaranKerja";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";
import { config } from "../../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5vw 9vw",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.25vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
      alignContent: "center",
      textAlign: "center",
    }
  },
  titleCenter: {
    alignContent: "center"
  },
  subtitle: {
    color: "#011627",
    fontSize: "0.7vw",
    [theme.breakpoints.down(750)]: {
      fontSize:"0.6rem"
    },
  },
  circleIcon: {
    backgroundColor: "#E21A43",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 6,
    borderRadius: "50%",
    width: 28,
    height: 28,
    cursor: "pointer",
  },
  iconBack: {
    color: "#FCFCFC",
    fontSize: 16,
  },
  radioLabel: {
    color: "#011627",
    fontSize: "0.7vw",
    fontWeight: "bold",
  },
  avatar: { width: 96, height: 96 },
  avatar1: { width: 96, height: 96, objectFit: "cover" },
  card: {
    padding: "5vh 2vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
  },
  sizeFormHide1: {
    [theme.breakpoints.down(750)]: {
      display: "none",
    },

  },
  sizeFormHide2: {
    [theme.breakpoints.up(750)]: {
      display: "none",
    },
  },
}));

const DetailProfile = () => {
  const classes = useStyles();
  const history = useHistory();
  const [dataProfile, setDataProfile] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    setLoading(true);
    API(`profile.getDetailProfile`, {
      params: { idUser: localStorage.getItem("usersId") },
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setDataProfile(res.data.data);
        }
      })
      .catch((res) => {
        setLoading(false);
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Gagal mendapat data profile!",
        });
      });
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };
  

  const listIdentitas = [
    "Identitas Diri",
    "Riwayat Pendidikan Terakhir",
    "Riwayat Organisasi",
    "Riwayat Pekerjaan",
    "Riwayat Kursus",
    "Riwayat Sertifikasi",
    "Informasi Lamaran Kerja",
  ];

  return (
    <Box className={classes.root}>
      <Grid spacing={4} container>
        <Grid container spacing={3} item>
          <Grid item>
            <Paper className={classes.circleIcon}>
              <ArrowBackIosRounded
                className={classes.iconBack}
                onClick={() => {
                  history.goBack();
                }}
              />
            </Paper>
          </Grid>
          <Grid item className={classes.titleCenter}>
            <Typography className={classes.title}>Detail Profile</Typography>
          </Grid>
        </Grid>
        {loading ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "30vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container item spacing={4}>
            <Grid container spacing={5} direction="column" item xs={9} className={classes.sizeFormHide1}>
              <Grid item>
                <IdentitasDiri data={dataProfile} />
              </Grid>
              <Grid item>
                <RiwayatPendidikan
                  data={dataProfile?.educationalBackgroundCustomDtos}
                />
              </Grid>
              <Grid item>
                <RiwayatOrganisasi
                  data={dataProfile?.organizationHistoryCustomDtos}
                />
              </Grid>
              <Grid item>
                <RiwayatPekerjaan
                  data={dataProfile?.workExperienceCustomDtos}
                />
              </Grid>
              <Grid item>
                <RiwayatKursus data={dataProfile?.courseHistoryCustomDtos} />
              </Grid>
              <Grid item>
                <RiwayatSertifikasi
                  data={dataProfile?.certificationHistoryCustomDtos}
                />
              </Grid>
              <Grid item>
                <InformasiLamaranKerja
                  data={dataProfile?.submissionFormCustomDto}
                />
              </Grid>
            </Grid>
            <Grid direction="column" container item xs={3} spacing={3} className={classes.sizeFormHide1}>
              <Grid item>
                <Card className={classes.card}>
                {loading ? (
                 
                          <Avatar className={classes.avatar} />
                
                      ) : isEmptyObject(dataProfile) || dataProfile.applicantPassPhotoPath == null ? (
                    
                        <Avatar className={classes.avatar} />
                    
                      ) : (
                        <img
                          className={classes.avatar1}
                          alt="pas photo"
                          src={`${config.BASE_URL}${dataProfile?.applicantPassPhotoPath}`}
                        />
                      )}
                  
                  <Grid
                    justifyContent="center"
                    direction="column"
                    alignItems="center"
                    container
                  >
                    <Grid item>
                      <Typography className={classes.title}>
                        {dataProfile?.applicantName || "-"}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.subtitle}>
                        {dataProfile?.applicantEmail || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid container direction="column" item>
                {listIdentitas.map((item, index) => (
                  <Grid
                    key={`radio-${index}`}
                    container
                    alignItems="center"
                    spacing={1}
                    item
                  >
                    <Grid item>
                      <Radio checked value={item} />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.radioLabel}>
                        {item}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid direction="column" container item spacing={3} className={classes.sizeFormHide2}>
              <Grid item>
                <Card className={classes.card}>
                {loading ? (
                    
                    <Avatar className={classes.avatar} />
          
                ) : isEmptyObject(dataProfile) || dataProfile.applicantPassPhotoPath == null ? (
              
                  <Avatar className={classes.avatar} />
              
                ) : (
                  <img
                    className={classes.avatar1}
                    alt="pas photo"
                    src={`${config.BASE_URL}${dataProfile?.applicantPassPhotoPath}`}
                  />
                )}
                  <Grid
                    justifyContent="center"
                    direction="column"
                    alignItems="center"
                    container
                  >
                    <Grid item>
                      <Typography className={classes.title}>
                        {dataProfile?.applicantName || "-"}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.subtitle}>
                        {dataProfile?.applicantEmail || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              {/* <Grid container direction="column" item>
                {listIdentitas.map((item, index) => (
                  <Grid
                    key={`radio-${index}`}
                    container
                    alignItems="center"
                    spacing={1}
                    item
                  >
                    <Grid item>
                      <Radio checked value={item} />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.radioLabel}>
                        {item}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid> */}
            </Grid>
            <Grid container spacing={5} direction="column" item className={classes.sizeFormHide2}>
              <Grid item>
                <IdentitasDiri data={dataProfile} />
              </Grid>
              <Grid item>
                <RiwayatPendidikan
                  data={dataProfile?.educationalBackgroundCustomDtos}
                />
              </Grid>
              <Grid item>
                <RiwayatOrganisasi
                  data={dataProfile?.organizationHistoryCustomDtos}
                />
              </Grid>
              <Grid item>
                <RiwayatPekerjaan
                  data={dataProfile?.workExperienceCustomDtos}
                />
              </Grid>
              <Grid item>
                <RiwayatKursus data={dataProfile?.courseHistoryCustomDtos} />
              </Grid>
              <Grid item>
                <RiwayatSertifikasi
                  data={dataProfile?.certificationHistoryCustomDtos}
                />
              </Grid>
              <Grid item>
                <InformasiLamaranKerja
                  data={dataProfile?.submissionFormCustomDto}
                />
              </Grid>
            </Grid>
            
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default DetailProfile;
