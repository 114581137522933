import React from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Event, Close } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import formInitialValues from "../../../GlobalComponent/FormModel/formInitialValues";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: "2vw",
  },
  header: {
    padding: "3vw",
    borderBottom: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
  },
  headerIsClose: {
    padding: "2.175vw 3vw",
    borderBottom: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
  },
  headerArea: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "1.1vw",
    fontWeight: "bold",
    margin: "auto",
    marginLeft: 0,
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
    }
  },
  content: {
    padding: 0,
    "&&:last-child": {
      paddingBottom: 0,
    },
  },
  container: {
    padding: "2vw",
    borderBottom: "1px solid #DDDDDD",
  },
  grid: {
    padding: "1vw",
  },
  label: {
    fontSize: "0.8vw",
    textAlign: "left",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.8rem",
    }
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  buttonAdd: {
    fontSize: "0.9vw",
    fontWeight: "bold",
    paddingTop: "1vw",
    paddingBottom: "1vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "2vw",
      padding: "2%",
      marginTop: "4%",
    }
  },
  close: {
    textAlign: "right",
  },
  iconButtom: {
    padding: "0.8vw",
  },
  closeIcon: {
    fontSize: "1.7vw",
  },
}));

const Form = ({ title, formik, riwayatSertifikasi }) => {
  const classes = useStyles();
  const numberValidation = /^[0-9]*$/;

  const onAddForm = () => {
    const tempForm = [...formik.values.riwayatSertifikasi];
    tempForm.push({ ...formInitialValues.riwayatSertifikasi });
    formik.setValues({ ...formik.values, riwayatSertifikasi: tempForm });
  };

  const onRemoveForm = (i) => {
    const formTemp = [...formik.values?.riwayatSertifikasi];
    const touchTemp = [...formik.touched?.riwayatSertifikasi];

    formTemp?.splice(i, 1);
    touchTemp?.splice(i, 1);
    formik.setValues({ ...formik.values, riwayatSertifikasi: formTemp });
    formik.setTouched({ ...formik.touched, riwayatSertifikasi: touchTemp });

    if (formik.errors?.hasOwnProperty("riwayatSertifikasi")) {
      const errorTemp = [...formik.errors?.riwayatSertifikasi];
      errorTemp?.splice(i, 1);
      formik.setErrors({ ...formik.errors, riwayatSertifikasi: errorTemp });
    }
  };

  return (
    <Box>
      {formik.values.riwayatSertifikasi?.map((e, i) => (
        <Card className={classes.card} key={i}>
          <CardHeader
            className={i === 0 ? classes.header : classes.headerIsClose}
            title={
              <Box className={classes.headerArea}>
                <Typography className={classes.title}>
                  {title + " - " + (i + 1)}
                </Typography>

                <IconButton
                  className={classes.iconButtom}
                  onClick={() => onRemoveForm(i)}
                >
                  <Close className={classes.closeIcon} />
                </IconButton>
              </Box>
            }
          />
          <CardContent className={classes.content}>
            <Grid className={classes.container} container>
              <Grid className={classes.grid} item xs={12}>
                <Typography className={classes.label}>
                  {riwayatSertifikasi.namaPanjang.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Nama Sertifikat"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatSertifikasi.${i}.${riwayatSertifikasi.namaPanjang.name}`}
                  value={
                    formik.values.riwayatSertifikasi[i][
                      riwayatSertifikasi.namaPanjang.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatSertifikasi &&
                    formik.touched.riwayatSertifikasi &&
                    formik.touched?.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.namaPanjang.name
                    ] &&
                    formik.errors?.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.namaPanjang.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatSertifikasi &&
                    formik.touched.riwayatSertifikasi &&
                    formik.errors.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.namaPanjang.name
                    ] &&
                    formik.touched.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.namaPanjang.name
                    ]
                      ? formik.errors.riwayatSertifikasi[i]?.[
                          riwayatSertifikasi.namaPanjang.name
                        ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={12}>
                <Typography className={classes.label}>
                  {riwayatSertifikasi.namaSingkat.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Nama Singkat"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: riwayatSertifikasi.namaSingkat.maximum,
                  }}
                  name={`riwayatSertifikasi.${i}.${riwayatSertifikasi.namaSingkat.name}`}
                  value={
                    formik.values.riwayatSertifikasi[i][
                      riwayatSertifikasi.namaSingkat.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatSertifikasi &&
                    formik.touched.riwayatSertifikasi &&
                    formik.touched?.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.namaSingkat.name
                    ] &&
                    formik.errors?.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.namaSingkat.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatSertifikasi &&
                    formik.touched.riwayatSertifikasi &&
                    formik.errors.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.namaSingkat.name
                    ] &&
                    formik.touched.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.namaSingkat.name
                    ]
                      ? formik.errors.riwayatSertifikasi[i]?.[
                          riwayatSertifikasi.namaSingkat.name
                        ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatSertifikasi.tanggalSertifikasi.label}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    hiddenLabel
                    fullWidth
                    size="small"
                    variant="dialog"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    keyboardIcon={<Event className={classes.icon} />}
                    InputProps={{
                      style: { padding: 0 },
                      disableUnderline: true,
                    }}
                    onChange={(val) => {
                      // formik.setFieldValue(
                      //   `riwayatSertifikasi.${i}.${riwayatSertifikasi.tanggalSelamanya.name}`,
                      //   null
                      // );
                      formik.setFieldValue(
                        `riwayatSertifikasi.${i}.${riwayatSertifikasi.tanggalAkhir.name}`,
                        null
                      );
                      formik.setFieldValue(
                        `riwayatSertifikasi.${i}.${riwayatSertifikasi.tanggalSertifikasi.name}`,
                        val
                      );
                    }}
                    name={`riwayatSertifikasi.${i}.${riwayatSertifikasi.tanggalSertifikasi.name}`}
                    value={
                      formik.values.riwayatSertifikasi[i][
                        riwayatSertifikasi.tanggalSertifikasi.name
                      ]
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.riwayatSertifikasi &&
                      formik.touched.riwayatSertifikasi &&
                      formik.touched?.riwayatSertifikasi[i]?.[
                        riwayatSertifikasi.tanggalSertifikasi.name
                      ] &&
                      formik.errors?.riwayatSertifikasi[i]?.[
                        riwayatSertifikasi.tanggalSertifikasi.name
                      ] &&
                      true
                    }
                    helperText={
                      formik.errors.riwayatSertifikasi &&
                      formik.touched.riwayatSertifikasi &&
                      formik.errors.riwayatSertifikasi[i]?.[
                        riwayatSertifikasi.tanggalSertifikasi.name
                      ] &&
                      formik.touched.riwayatSertifikasi[i]?.[
                        riwayatSertifikasi.tanggalSertifikasi.name
                      ]
                        ? formik.errors.riwayatSertifikasi[i]?.[
                            riwayatSertifikasi.tanggalSertifikasi.name
                          ]
                        : ""
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatSertifikasi.tanggalAkhir.label}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    hiddenLabel
                    fullWidth
                    // disableFuture
                    size="small"
                    variant="dialog"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    keyboardIcon={<Event className={classes.icon} />}
                    InputProps={{
                      style: { padding: 0 },
                      disableUnderline: true,
                    }}
                    minDate={
                      formik.values.riwayatSertifikasi[i][
                        riwayatSertifikasi.tanggalSertifikasi.name
                      ] !== null &&
                      formik.values.riwayatSertifikasi[i][
                        riwayatSertifikasi.tanggalSertifikasi.name
                      ]
                    }
                    disabled={
                      formik.values.riwayatSertifikasi[i][
                        riwayatSertifikasi.tanggalSertifikasi.name
                      ] === null ||
                      formik.values.riwayatSertifikasi[i][
                        riwayatSertifikasi.tanggalSelamanya.name
                      ]
                    }
                    onChange={(val) => {
                      formik.setFieldValue(
                        `riwayatSertifikasi.${i}.${riwayatSertifikasi.tanggalSelamanya.name}`,
                        null
                      );
                      formik.setFieldValue(
                        `riwayatSertifikasi.${i}.${riwayatSertifikasi.tanggalAkhir.name}`,
                        val
                      );
                    }}
                    name={`riwayatSertifikasi.${i}.${riwayatSertifikasi.tanggalAkhir.name}`}
                    value={
                      formik.values.riwayatSertifikasi[i][
                        riwayatSertifikasi.tanggalAkhir.name
                      ]
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.riwayatSertifikasi &&
                      formik.touched.riwayatSertifikasi &&
                      formik.touched?.riwayatSertifikasi[i]?.[
                        riwayatSertifikasi.tanggalAkhir.name
                      ] &&
                      formik.errors?.riwayatSertifikasi[i]?.[
                        riwayatSertifikasi.tanggalAkhir.name
                      ] &&
                      true
                    }
                    helperText={
                      formik.errors.riwayatSertifikasi &&
                      formik.touched.riwayatSertifikasi &&
                      formik.errors.riwayatSertifikasi[i]?.[
                        riwayatSertifikasi.tanggalAkhir.name
                      ] &&
                      formik.touched.riwayatSertifikasi[i]?.[
                        riwayatSertifikasi.tanggalAkhir.name
                      ]
                        ? formik.errors.riwayatSertifikasi[i]?.[
                            riwayatSertifikasi.tanggalAkhir.name
                          ]
                        : ""
                    }
                  />
                </MuiPickersUtilsProvider>
                <FormControlLabel
                  onChange={(val) => {
                    const isChecked = val.target.checked;
                    formik.setFieldValue(
                      `riwayatSertifikasi.${i}.${riwayatSertifikasi.isForever.name}`,
                      isChecked
                    );
                    if (isChecked) {
                      formik.setFieldValue(
                        `riwayatSertifikasi.${i}.${riwayatSertifikasi.tanggalSelamanya.name}`,
                        new Date("01/01/9999")
                      );
                      formik.setFieldValue(
                        `riwayatSertifikasi.${i}.${riwayatSertifikasi.tanggalAkhir.name}`,
                        new Date("01/01/9999")
                      );
                    } else {
                      formik.setFieldValue(
                        `riwayatSertifikasi.${i}.${riwayatSertifikasi.tanggalSelamanya.name}`,
                        null
                      );
                      formik.setFieldValue(
                        `riwayatSertifikasi.${i}.${riwayatSertifikasi.tanggalAkhir.name}`,
                        null
                      );
                    }
                  }}
                  disabled={
                    formik.values.riwayatSertifikasi[i][
                      riwayatSertifikasi.tanggalSertifikasi.name
                    ] === null
                  }
                  control={
                    <Checkbox
                      checked={
                        formik.values.riwayatSertifikasi[i][
                          riwayatSertifikasi.isForever.name
                        ] ||
                        parseInt(
                          moment(
                            formik.values.riwayatSertifikasi[i][
                              riwayatSertifikasi.tanggalAkhir.name
                            ]
                          ).format("YYYY")
                        ) > parseInt(moment().toDate().getFullYear())
                      }
                      color="secondary"
                    />
                  }
                  label="Berlaku Selamanya"
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatSertifikasi.nomorSertifikasi.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan No. Sertifikat"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: riwayatSertifikasi.nomorSertifikasi.maximum,
                  }}
                  name={`riwayatSertifikasi.${i}.${riwayatSertifikasi.nomorSertifikasi.name}`}
                  value={
                    formik.values.riwayatSertifikasi[i][
                      riwayatSertifikasi.nomorSertifikasi.name
                    ]
                  }
                  onChange={(e) => formik.handleChange(e)}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatSertifikasi &&
                    formik.touched.riwayatSertifikasi &&
                    formik.touched?.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.nomorSertifikasi.name
                    ] &&
                    formik.errors?.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.nomorSertifikasi.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatSertifikasi &&
                    formik.touched.riwayatSertifikasi &&
                    formik.errors.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.nomorSertifikasi.name
                    ] &&
                    formik.touched.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.nomorSertifikasi.name
                    ]
                      ? formik.errors.riwayatSertifikasi[i]?.[
                          riwayatSertifikasi.nomorSertifikasi.name
                        ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatSertifikasi.namaLembaga.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Nama Lembaga"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: riwayatSertifikasi.namaLembaga.maximum,
                  }}
                  name={`riwayatSertifikasi.${i}.${riwayatSertifikasi.namaLembaga.name}`}
                  value={
                    formik.values.riwayatSertifikasi[i][
                      riwayatSertifikasi.namaLembaga.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatSertifikasi &&
                    formik.touched.riwayatSertifikasi &&
                    formik.touched?.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.namaLembaga.name
                    ] &&
                    formik.errors?.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.namaLembaga.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatSertifikasi &&
                    formik.touched.riwayatSertifikasi &&
                    formik.errors.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.namaLembaga.name
                    ] &&
                    formik.touched.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.namaLembaga.name
                    ]
                      ? formik.errors.riwayatSertifikasi[i]?.[
                          riwayatSertifikasi.namaLembaga.name
                        ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatSertifikasi.predikat.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Predikat"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: riwayatSertifikasi.predikat.maximum,
                  }}
                  name={`riwayatSertifikasi.${i}.${riwayatSertifikasi.predikat.name}`}
                  value={
                    formik.values.riwayatSertifikasi[i][riwayatSertifikasi.predikat.name]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatSertifikasi &&
                    formik.touched.riwayatSertifikasi &&
                    formik.touched?.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.predikat.name
                    ] &&
                    formik.errors?.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.predikat.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatSertifikasi &&
                    formik.touched.riwayatSertifikasi &&
                    formik.errors.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.predikat.name
                    ] &&
                    formik.touched.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.predikat.name
                    ]
                      ? formik.errors.riwayatSertifikasi[i]?.[
                          riwayatSertifikasi.predikat.name
                        ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatSertifikasi.nilai.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan Nilai"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: riwayatSertifikasi.nilai.maximum,
                  }}
                  name={`riwayatSertifikasi.${i}.${riwayatSertifikasi.nilai.name}`}
                  value={
                    formik.values.riwayatSertifikasi[i][riwayatSertifikasi.nilai.name]
                  }
                  onChange={(e) =>
                    numberValidation.test(e.target.value) &&
                    formik.handleChange(e)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatSertifikasi &&
                    formik.touched.riwayatSertifikasi &&
                    formik.touched?.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.nilai.name
                    ] &&
                    formik.errors?.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.nilai.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatSertifikasi &&
                    formik.touched.riwayatSertifikasi &&
                    formik.errors.riwayatSertifikasi[i]?.[
                      riwayatSertifikasi.nilai.name
                    ] &&
                    formik.touched.riwayatSertifikasi[i]?.[riwayatSertifikasi.nilai.name]
                      ? formik.errors.riwayatSertifikasi[i]?.[
                          riwayatSertifikasi.nilai.name
                        ]
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
      {formik.values.riwayatSertifikasi.length < 1 && (
        <Typography
          align="center"
          className={classes.buttonAdd}
          color="secondary"
        >
          Dapat Dilewati Apabila Tidak Relevan
        </Typography>
      )}
      <Button
        className={classes.buttonAdd}
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={() => {
          onAddForm();
        }}
      >
        + Tambah {title}
      </Button>
    </Box>
  );
};

export default Form;
