import React, { useEffect, useState } from "react";
import { makeStyles, Box, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { API } from "../../../service/axios";
import { Toast } from "../../Component/Notification";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const useStyles = makeStyles((theme) => ({
  top: {
    background: theme.palette.primary.main,
    color: "#fff",
  },
  footerLeft: {
    position: "absolute",
    left: 0,
    height: "24.6vw",
  },
  footerRight: {
    position: "absolute",
    right: 0,
    height: "24.6vw",
  },
  topPadding: {
    padding: "7vw 14vw",
  },
  logoWhite: {
    width: "90%",
    height:"fit-content",
    [theme.breakpoints.down(750)]: {
      width: "50%",
    },
  },
  footerTitle: {
    fontSize: "1vw",
    fontWeight: "bold",
    marginBottom: "1vw",
    [theme.breakpoints.down(750)]: {
      fontSize:"10px",
    },
  },
  footerContent: {
    fontSize: "0.8vw",
    textAlign: "justify",
    [theme.breakpoints.down(750)]: {
      fontSize:"10px",
    },
  },
  footerContentAction: {
    fontSize: "1vw",
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize:"10px",
    },
  },
  bottom: {
    background: theme.palette.primary.dark,
    color: "#fff",
    textAlign: "center",
    fontSize: "0.8vw",
    padding: "1vw 0",
  },
  subtitleLabel:{
    paddingBottom: "10px",
  },
  subtitleLabel1:{
    paddingBottom: "10px",
    zIndex: "1",
  },
  sizeFormHide1: {
    [theme.breakpoints.down(750)]: {
      display: "none",
      paddingBottom:"100px"
    },

  },
  sizeFormHide2: {
    [theme.breakpoints.up(750)]: {
      display: "none",
    },
  },

}));

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();
  const [dataFooter, setDataFooter] = useState({});
  const isLogin = localStorage.getItem("isLogin");
  const [message, setMessage] = useState("Success");
  const date = new Date();
  

  useEffect(() => {
    if (isLogin) {
      getFooter("secured");
    } else {
      getFooter("open");
    }
  }, [isLogin]);

  const getFooter = (type) => {
    const url =
      type === "open" ? "footer.getFooterOpen" : "footer.getFooterSecured";

    API(`${url}`)
      .then((res) => {
        setMessage(res.data?.Message);
        if (res.status === 200 && res.data?.Message === "Success" || res.data?.Message === "Not Found") {
          setDataFooter(res.data.Data);
        } else {
          Toast.fire({
            icon: "info",
            title: res?.data?.Message || `Gagal mendapat data footer! url (${url})`,
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || `Gagal mendapat data footer, url cannot be accessed! ${url}`,
        });
      });
  };

  return (
    <div>
      {message.toLowerCase() !== "not found" && (
        <Box className={classes.footerFix}>
          <Box className={classes.top}>
            <img
              className={classes.footerLeft}
              alt="logo"
              src={process.env.PUBLIC_URL + "/images/footer-left.png"}
            />
            <img
              className={classes.footerRight}
              alt="logo"
              src={process.env.PUBLIC_URL + "/images/footer-right.png"}
            />
            <Grid className={classes.topPadding} container>
              {/* <Grid item xs={2} style={{ zIndex: 1 }}>
                <img
                  className={classes.logoWhite}
                  alt="logo"
                  src={process.env.PUBLIC_URL + "/images/logo-white.png"}
                />
              </Grid>
              <Grid item xs={4} style={{ zIndex: 1, padding: "0 2vw 0 1vw" }}>
                <Typography className={classes.footerTitle}>
                  Tentang Kami
                </Typography>
                <Typography className={classes.footerContent}>
                  {dataFooter.footerSectionAboutUs}
                </Typography>
              </Grid>
              <Grid item xs={3} style={{ zIndex: 1, padding: "0 2vw 0 0" }}>
                <Typography className={classes.footerTitle}>
                  Kontak Kami
                </Typography>
                <Typography className={classes.footerContent}>
                  Telp: {dataFooter.footerSectionTelp}
                </Typography>
                <Typography className={classes.footerContent}>
                  Faks: {dataFooter.footerSectionFax}
                </Typography>
                <Typography className={classes.footerContent}>
                  {dataFooter.footerSectionEmail}
                </Typography>
                <Box style={{ marginTop: "1vw" }}>
                  <Typography className={classes.footerContent}>
                    {dataFooter.footerSectionAddress}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} style={{ zIndex: 1, padding: "0 2vw 0 0" }}>
                <Typography className={classes.footerTitle}>
                  Butuh Bantuan
                </Typography>
            
                <Typography
                  className={classes.footerContentAction}
                  onClick={() => history.push("/faq")}
                >
                  Frequently Asked Questions
                </Typography>
              </Grid> */}
              <Container>
                <Row className="mt-3">
                  <Row>
                    <Col
                      xs={12} // Full width on mobile
                      md={3} // 3 columns wide on medium and above
                      className={`d-flex justify-content-center ${classes.subtitleLabel1} `}
                    >
                      <img
                        className={classes.logoWhite}
                        alt="logo"
                        src={process.env.PUBLIC_URL + "/images/logo-white.png"}
                      />
                    </Col>

                    <Col
                      xs={12}
                      md={3}
                      className={`d-flex flex-column ${classes.subtitleLabel}`}
                    >
                      <Typography className={classes.footerTitle}>
                        Tentang Kami
                      </Typography>
                      <Typography className={classes.footerContent}>
                        {dataFooter.footerSectionAboutUs}
                      </Typography>
                    </Col>

                    <Col
                      xs={7}
                      md={3}
                      className={`d-flex flex-column ${classes.subtitleLabel}`}
                    >
                      <Typography className={classes.footerTitle}>
                        Kontak Kami
                      </Typography>
                      <Typography className={classes.footerContent}>
                        Telp: {dataFooter.footerSectionTelp}
                      </Typography>
                      <Typography className={classes.footerContent}>
                        Faks: {dataFooter.footerSectionFax}
                      </Typography>
                      <Typography className={classes.footerContent}>
                        {dataFooter.footerSectionEmail}
                      </Typography>
                      <Box style={{ marginTop: "1vw" }} className={classes.sizeFormHide1 }>
                        <Typography className={classes.footerContent}>
                          {dataFooter.footerSectionAddress}
                        </Typography>
                      </Box>
                    </Col>
                    <Col
                      xs={5}
                      md={3}
                      className={`flex-column ${classes.subtitleLabel} ${classes.sizeFormHide2} `}
                    >
                      <Box style={{ marginTop: "1vw" }}>
                        <Typography className={classes.footerContent}>
                          {dataFooter.footerSectionAddress}
                        </Typography>
                      </Box>
                    </Col>
                    <Col
                      xs={6}
                      md={3}
                      className={`flex-column ${classes.subtitleLabel} ${classes.sizeFormHide1} `}
                    >
                      <Typography className={classes.footerTitle}>
                        Butuh Bantuan
                      </Typography>
                      <Typography
                        className={classes.footerContentAction}
                        onClick={() => history.push("/faq")}
                      >
                        Frequently Asked Questions
                      </Typography>
                    </Col>
                    <Col
                      xs={12}
                      md={3}
                      className={`flex-column text-md-start ${classes.subtitleLabel} ${classes.sizeFormHide2}`}
                    >
                      <Typography variant="h6" className={classes.footerTitle}>
                        Butuh Bantuan
                      </Typography>
                      <Typography
                        className={classes.footerContentAction}
                        style={{ cursor: "pointer" }} // To show a pointer on hover
                        onClick={() => history.push("/faq")}
                      >
                        Frequently Asked Questions
                      </Typography>
                    </Col>
                  </Row>
                </Row>
              </Container>

            </Grid>
          </Box>
          <Box className={classes.bottom}>
            Copyright ©{date.getFullYear()}. BJB Syariah
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Footer;
