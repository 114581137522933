import React, { useCallback, useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Divider,
  Paper,
  TextField,
  InputAdornment,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@material-ui/core";
import { Search, BusinessCenter, LocationOn } from "@material-ui/icons";
import DetailLoker from "../ModalDetailLoker";
import Pagination from "../../../GlobalComponent/Pagination";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";

const useStyles = makeStyles((theme) => ({
  lowonganPekerjaan: {
    padding: "7vw 0",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "2vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "3vw",
    }
  },
  desc: {
    color: theme.palette.primary.main,
    fontSize: "1vw",
    marginTop: "1.5vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "2.3vw",
    }
  },
  divider: {
    width: "2.5vw",
    margin: "1vw auto",
    background: theme.palette.secondary.main,
    height: "0.3vw",
  },
  lokerPadding: {
    padding: "2vw 14vw 0 14vw",
  },
  filter: {
    padding: "2vw",
  },
  grid: {
    padding: "1vw",
  },
  label: {
    fontSize: "0.8vw",
    textAlign: "left",
    [theme.breakpoints.down(750)]: {
      fontSize: '2.5vw'
    },
  },
  adorment: {
    color: "#808A93",
    [theme.breakpoints.down(750)]: {
      fontSize: '2.5vw'
    },
  },
  buttonCari: {
    fontSize: "0.9vw",
    height: "40px",
    [theme.breakpoints.down(750)]: {
      fontSize:"2vw",
    },
  },
  table: {
    marginTop: "2vw",
  },
  th: {
    padding: "2vw",
    fontSize: "0.9vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: '2vw'
    },
  },
  td: {
    padding: "2vw",
    fontSize: "0.9vw",
    [theme.breakpoints.down(750)]: {
      fontSize: '2vw'
    },
  },
  buttonDetail: {
    fontSize: "0.9vw",
    width: "100%",
    padding: "1vw",
    [theme.breakpoints.down(750)]: {
      fontSize: '1.5vw'
    },
  },
  resetButton: {
    marginTop: "1.6vh",
    color: "#E21A43",
    fontSize: "0.7vw",
    fontWeight: 600,
    padding: 0,
    [theme.breakpoints.down(750)]: {
      fontSize: "0.6rem",
    },
  },

  sizeFormHide1: {
    [theme.breakpoints.down(750)]: {
      display: "none",
    },

  },
  sizeFormHide2: {
    [theme.breakpoints.up(750)]: {
      display: "none",
    },
  },
}));

const LowonganPekerjaan = ({ innerRef }) => {
  const classes = useStyles();
  const initialParams = {
    categoryId: "",
    locationId: "",
    search: "",
    size: 10,
    page: 0,
    sort: "",
  };
  const [ scrollPosition, setScrollPosition ] = useState(0);
  const [searchPosition, setSearchPosition] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [modalDetail, setModalDetail] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const isLogin = localStorage.getItem("isLogin");
  const categoryApplicant = localStorage.getItem("categoryApplicant");
  const [dataJobSection, setDataJobSection] = useState({});
  const [dataAllJobVacancy, setDataAllJobVacancy] = useState({ Data: [] });
  const [dataLocation, setDataLocation] = useState([]);
  const [params, setParams] = useState(initialParams);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Success");
  const [dataCategory, setDataCategory] = useState([]);

  const getLovLocation = (type) => {
    const url =
      type === "open"
        ? "home.getLovLocationOpen"
        : "home.getLovLocationSecured";

    API(`${url}`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataLocation(res.data?.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data lokasi!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data lokasi!",
        });
      });
  };

  const getJobSection = (type) => {
    const url =
      type === "open" ? "home.getJobSectionOpen" : "home.getJobSectionSecured";

    API(`${url}`)
      .then((res) => {
        setMessage(res.data?.Message);
        if (res.status === 200 && res.data?.Message === "Success" || res.data?.Message === "Not Found") {
          setDataJobSection(res.data?.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data Job!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data Job!",
        });
      });
  };

  const getDataCategory = (type) => {
    const url =
      type === "open"
        ? "home.getLovCategoryOpen"
        : "home.getLovCategorySecured";

    API(`${url}`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataCategory(res.data?.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: res?.data?.message || "Gagal mendapat data kategori!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title:
            res?.response?.data?.message || "Gagal mendapat data kategori!",
        });
      });
  };

  const getAllJobVacancy = useCallback(
    (type) => {
      setLoading(true);
      const url =
        type === "open"
          ? "home.getAllJobVacancyOpen"
          : "home.getAllJobVacancySecured";

      API(`${url}`, {
        params,
      })
        .then((res) => {
          setLoading(false);
          if (res.status === 200 || res.data?.Message === "Success") {
            setDataAllJobVacancy(res.data);
          }
        })
        .catch((res) => {
          setLoading(false);
          Toast.fire({
            icon: "warning",
            title:
              res.response?.data?.Message || "Gagal mendapat data lowongan!",
          });
        });
    },
    [params]
  );

  const handleSearch = (e) => {
    e.preventDefault();
    setParams({
      categoryId: selectedCategory,
      locationId: selectedLocation,
      search: searchPosition,
      size: 10,
      page: 0,
      sort: "",
    });
  };

  const handlePagination = (val) => {
    setParams({
      ...params,
      size: val.size,
      page: val.page - 1,
    });
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > innerRef.current.offsetTop-100) {
      setSelectedCategory(localStorage.getItem("categoryApplicant"))
    }
  };

  const handleChangeSelectedCategory = (event) => {
    localStorage.setItem("categoryApplicant", event.target.value)
    setSelectedCategory(event.target.value)
  }

  useEffect(() => {
    if (categoryApplicant != params.categoryId) {
      setParams({
        ...params,
        categoryId: categoryApplicant ? categoryApplicant : ""
      });
    }
  }, [categoryApplicant]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }; 
  }, [scrollPosition]);

  useEffect(() => {
    if (isLogin) {
      getJobSection("secured");
      getLovLocation("secured");
      getDataCategory("secured");
    } else {
      getJobSection("open");
      getLovLocation("open");
      getDataCategory("open");
    }
  }, [isLogin]);

  useEffect(() => {
    if (isLogin) {
      getAllJobVacancy("secured");
    } else {
      getAllJobVacancy("open");
    }
  }, [getAllJobVacancy, isLogin]);

  return (
    <div>
      {message.toLowerCase() !== "not found" && (
        <Box ref={innerRef} className={classes.lowonganPekerjaan}>
          <Typography align="center" className={classes.title}>
            {dataJobSection.jobVacancySectionTitle || "Lowongan Pekerjaan"}
          </Typography>
          <Typography align="center" className={classes.desc}>
            {dataJobSection.jobVacancySectionSubtitle ||
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
          </Typography>
          <Divider className={classes.divider} />
          <Box className={classes.lokerPadding}>
            <Paper className={classes.filter} elevation={1}>
              <form onSubmit={handleSearch}>
                <Grid container className={classes.sizeFormHide1}>
                  <Grid className={classes.grid} item xs={4}>
                    <Typography className={classes.label}>
                      Pencarian Posisi Kerjaaa
                    </Typography>
                    <TextField
                      fullWidth
                      hiddenLabel
                      size="small"
                      variant="filled"
                      placeholder="Nama Posisi Kerja"
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search className={classes.adorment} />
                          </InputAdornment>
                        ),
                      }}
                      value={searchPosition}
                      onChange={(e) => setSearchPosition(e.target.value)}
                    />
                    <Typography align="left">
                      <Button
                        variant="text"
                        onClick={() => {
                          setParams(initialParams);
                          setSearchPosition("");
                          setSelectedLocation("");
                          setSelectedCategory("");
                          localStorage.setItem("categoryApplicant", "")
                        }}
                        className={classes.resetButton}
                      >
                        Reset Filter
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid className={classes.grid} item xs={3}>
                    <Typography className={classes.label}>
                      Kategori Lowongan
                    </Typography>
                    <TextField
                      fullWidth
                      hiddenLabel
                      select
                      size="small"
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <BusinessCenter className={classes.adorment} />
                          </InputAdornment>
                        ),
                      }}
                      value={selectedCategory}
                      onChange={(e) => handleChangeSelectedCategory(e)}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem key="default-category" value="">
                        Pilih Kategori
                      </MenuItem>
                      {dataCategory.map((item, i) => (
                        <MenuItem key={i} value={item.categoryId}>
                          {item.categoryName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid className={classes.grid} item xs={3}>
                    <Typography className={classes.label}>Lokasi</Typography>
                    <TextField
                      fullWidth
                      hiddenLabel
                      select
                      size="small"
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOn className={classes.adorment} />
                          </InputAdornment>
                        ),
                      }}
                      value={selectedLocation}
                      onChange={(e) => setSelectedLocation(e.target.value)}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem key="default-location" value="">
                        Pilih Lokasi
                      </MenuItem>
                      {dataLocation.map((item, i) => (
                        <MenuItem key={i} value={item.locationId}>
                          {item.locationName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid className={classes.grid} item xs={2}>
                    <Typography className={classes.label}>&nbsp;</Typography>
                    <Button
                      className={classes.buttonCari}
                      fullWidth
                      variant="contained"
                      color="secondary"
                      type="submit"
                    // onClick={() => handleSearch()}
                    >
                      Cari
                    </Button>
                  </Grid>
                </Grid>
                <Grid container className={classes.sizeFormHide2}>
                  <Grid className={classes.grid} item xs={12}>
                    <Typography className={classes.label}>
                      Pencarian Posisi Kerja
                    </Typography>
                    <TextField
                      fullWidth
                      hiddenLabel
                      size="small"
                      variant="filled"
                      placeholder="Nama Posisi Kerja"
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search className={classes.adorment} />
                          </InputAdornment>
                        ),
                      }}
                      value={searchPosition}
                      onChange={(e) => setSearchPosition(e.target.value)}
                    />
              
                  </Grid>
                  <Grid className={classes.grid} item xs={5}>
                    <Typography className={classes.label}>
                      Kategori Lowongan
                    </Typography>
                    <TextField
                      fullWidth
                      hiddenLabel
                      select
                      size="small"
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      value={selectedCategory}
                      onChange={(e) => handleChangeSelectedCategory(e)}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem key="default-category" value="">
                        Kategori
                      </MenuItem>
                      {dataCategory.map((item, i) => (
                        <MenuItem key={i} value={item.categoryId}>
                          {item.categoryName}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography align="left">
                      <Button
                        variant="text"
                        onClick={() => {
                          setParams(initialParams);
                          setSearchPosition("");
                          setSelectedLocation("");
                          setSelectedCategory("");
                          localStorage.setItem("categoryApplicant", "")
                        }}
                        className={classes.resetButton}
                      >
                        Reset Filter
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid className={classes.grid} item xs={5}>
                    <Typography className={classes.label}>Lokasi</Typography>
                    <TextField
                      fullWidth
                      hiddenLabel
                      select
                      size="small"
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      value={selectedLocation}
                      onChange={(e) => setSelectedLocation(e.target.value)}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem key="default-location" value="">
                        Lokasi
                      </MenuItem>
                      {dataLocation.map((item, i) => (
                        <MenuItem key={i} value={item.locationId}>
                          {item.locationName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid className={classes.grid} item xs={2}>
                    <Typography className={classes.label}>&nbsp;</Typography>
                    <Button
                      className={classes.buttonCari}
                      fullWidth
                      variant="contained"
                      color="secondary"
                      type="submit"
                    // onClick={() => handleSearch()}
                    >
                      Cari
                    </Button>
                    
                  </Grid>
                </Grid>
              </form>
            </Paper>
            <TableContainer className={classes.table} component={Paper}>
              {loading ? (
                <Box style={{ textAlign: "center" }}>
                  <CircularProgress style={{ margin: "5vw" }} />
                </Box>
              ) : (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.th}>
                          Posisi Kerja
                        </TableCell>
                        <TableCell className={classes.th}>
                          Kategori Lowongan
                        </TableCell>
                        <TableCell className={classes.th}>Lokasi</TableCell>
                        <TableCell className={classes.th}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataAllJobVacancy?.Data?.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell className={classes.td}>
                            {row.position?.positionName}
                          </TableCell>
                          <TableCell className={classes.td}>
                            {row.category?.categoryName}
                          </TableCell>
                          <TableCell className={classes.td}>
                            {row.location?.locationName}
                          </TableCell>
                          <TableCell className={classes.td}>
                            <Button
                              className={classes.buttonDetail}
                              variant="outlined"
                              color="secondary"
                              onClick={() => {
                                setModalDetail(true);
                                setSelectedData(row);
                              }}
                            >
                              Lihat Detail
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {dataAllJobVacancy?.Data?.length === 0 && (
                    <Typography
                      className={classes.td}
                      align="center"
                      style={{ margin: "2vw" }}
                    >
                      Data not found
                    </Typography>
                  )}
                  <Pagination
                    totalItem={
                      dataAllJobVacancy?.TotalData
                        ? dataAllJobVacancy?.TotalData
                        : 0
                    }
                    pages={params.page}
                    size={params.size}
                    onChange={(val) => handlePagination(val)}
                  />
                </>
              )}
            </TableContainer>
            <DetailLoker
              dataLoker={selectedData}
              modalVisible={modalDetail}
              handleClose={() => {
                setModalDetail(false);
                setSelectedData([]);
              }}
            />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default LowonganPekerjaan;
