import React from "react";
import {
  makeStyles,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Box,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.1vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
    }
  },
  content: {
    padding: "3vh 2.5vw",
    [theme.breakpoints.down(550)]: {
      padding: "3vh",
    },
  },
  accordion: {
    borderRadius: 8,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
  },
  subtitleLabel: {
    color: "#005A7D",
    marginBottom: 8,
    // fontSize: "0.625vw",
    fontSize: "0.825vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.7rem",
    }
  },
  contentLabel: {
    color: "#011627",
    fontWeight: "bold",
    // fontSize: "0.7vw",
    fontSize: "0.9vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.7rem",
    }
  },
  sizeFormHide1: {
    [theme.breakpoints.down(550)]: {
      display: "none",
    },

  },
  sizeFormHide2: {
    [theme.breakpoints.up(550)]: {
      display: "none",
    },
  },
}));

const RiwayatOrganisasi = ({ data }) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        id="detail-riwayat-organisasi"
        className={classes.content}
      >
        <Typography className={classes.title}>
          Riwayat Organisasi ({data?.length})
        </Typography>
      </AccordionSummary>
      <Grid direction="column" container>
        {data?.map((item, index) => (
          <Box key={`riwayat-organisasi-${index}`}>
            <Divider />
            <AccordionDetails className={classes.content}>
              <Grid container spacing={5} className={classes.sizeFormHide1}>
                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    Nama Organisasi
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.contentLabel}
                  >
                    {item.organizationHistoryName || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    Lingkup
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.contentLabel}
                  >
                    {item.organizationHistoryScope || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    Jabatan
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.contentLabel}
                  >
                    {item.organizationHistoryPosition || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    Masa Jabatan
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.contentLabel}
                  >
                    {item.organizationHistoryPeriod
                      ? `${item.organizationHistoryPeriod} Tahun`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={5} className={classes.sizeFormHide2}>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    Nama Organisasi
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.contentLabel}
                  >
                    {item.organizationHistoryName || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    Lingkup
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.contentLabel}
                  >
                    {item.organizationHistoryScope || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    Jabatan
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.contentLabel}
                  >
                    {item.organizationHistoryPosition || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    Masa Jabatan
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.contentLabel}
                  >
                    {item.organizationHistoryPeriod
                      ? `${item.organizationHistoryPeriod} Tahun`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Box>
        ))}
      </Grid>
    </Accordion>
  );
};

export default RiwayatOrganisasi;
