import React, { useEffect, useState }  from "react";
import { useHistory } from "react-router";
import {
  makeStyles,
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Fab,
  Popper,
  Paper,
  List,
  ListItem,
  ClickAwayListener,
  Zoom,
  useScrollTrigger,
  Grid,
  Divider,
  
} from "@material-ui/core";
import { Person, KeyboardArrowUp,Dehaze } from "@material-ui/icons";
import shadows from "@material-ui/core/styles/shadows";
import ModalImage from "react-modal-image";
import { config } from "../../../config";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";
import { isEmptyArray } from "formik";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between",
    padding: "0.5vw 10vw",
    [theme.breakpoints.up(750)]: {
      padding: "0.5vw 15vw",
      minHeight: 0,
    },
  },
  logo: {
    height: "4vw",
    [theme.breakpoints.down(750)]: {
      height: "10vw",
    },
  },
  logo1: {
    height: "4vw",
    width: "4vw",
    objectFit: "cover",
    borderRadius:"50%",
    [theme.breakpoints.down(750)]: {
      height: "8vw",
      width: "8vw",
      objectFit: "cover",
    },
  },
  menu: {
    display: "flex",
  },
  textMenu: {
    fontSize: "0.9vw",
    fontWeight: "600",
    lineHeight: "3vw",
    marginRight: "1.5vw",
    cursor: "pointer",
  },
  iconButton: {
    fontSize: "1vw",
    padding: "0.5vw 0.7vw",
    marginRight: "1vw",
  },
  iconSearch: {
    fontSize: "1.5vw",
  },
  buttonLogin: {
    fontSize: "0.9vw",
    padding: "1vw 2.5vw",
    marginRight: "1vw",
  },
  buttonDaftar: {
    fontSize: "0.9vw",
    padding: "1vw 2.5vw",
  },
  profile: {
    width: "3vw",
    height: "3vw",
    minHeight: 0,
    cursor: "pointer",
    boxShadow: "none",
    "&&:active": {
      boxShadow: "none",
    },
    [theme.breakpoints.down(750)]: {
      width: "9vw",
      height: "9vw",
      // background:"transparent",
    },
  },
  profile1: {
    width: "3vw",
    height: "3vw",
    minHeight: 0,
    cursor: "pointer",
    boxShadow: "none",
    placeSelf: "center",
    "&&:active": {
      boxShadow: "none",
    },
    [theme.breakpoints.down(750)]: {
      width: "5vw",
      height: "5vw",
      placeSelf: "center",
      
      // background:"transparent",
    },
    [theme.breakpoints.down(400)]: {
      width: "7vw",
      height: "7vw",
      placeSelf: "center",
      
      // background:"transparent",
    },
  },
  
  MenuHamburger: {
    [theme.breakpoints.down(750)]: {
      width: "7vw",
      height: "7vw",
      background: "transparent",
      boxShadow:"none",
    },
  },
  HamburgerIcon: {
    [theme.breakpoints.down(750)]: {
      width: "7vw",
      height: "7vw",
      color: "black",
    },
  },
  profileIcon: {
    fontSize: "2vw",
    color: theme.palette.background.paper,
    [theme.breakpoints.down(750)]: {
      fontSize: "5vw",
    },
  },
  profileIcon2: {
    fontSize: "2vw",
    color: "black",
    [theme.breakpoints.down(750)]: {
      fontSize: "5vw",
    },
  },
  profileIcon1: {
    fontSize: "2vw",
    color: theme.palette.background.paper,
    [theme.breakpoints.down(750)]: {
      fontSize: "4vw",
      
      // background:"transparent",
    },
  },
  info: {
    padding: "1vw",
    display: "flex",
    borderBottom: "1px solid #DDDDDD",
    [theme.breakpoints.down(750)]: {
      padding: "1vw 2vw",
    },
  },
  infoName: {
    fontSize: "0.9rem",
    lineHeight: 3,
    marginLeft: "1vw",
    minWidth: "8vw",
    [theme.breakpoints.down(750)]: {
      // fontSize: "0.6rem",
      marginLeft: "2vw",
    },
  },
  action: {
    padding: "0.5vw 0",
  },
  item: {
    padding: "0.5vw 1.5vw",
    [theme.breakpoints.down(750)]: {
      padding: "0vw 1.5vw",
    },
    // borderTop:"1px solid black",
  },
  actionText: {
    fontSize: "0.9rem",
    lineHeight: 3,
    [theme.breakpoints.down(750)]: {
      lineHeight: 2,
      padding:"0 2vw",
    },
  },
  arrow: {
    position: "fixed",
    fontSize: "1vw",
    bottom: "2vw",
    right: "2vw",
    width: "4vw",
    height: "4vw",
    minHeight: 0,
    [theme.breakpoints.down(750)]: {
      width: "6vw",
      height: "6vw",
  
    },
  },
  arrowIcon: {
    fontSize: "2vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "4vw",
    },
  },
  hiddenMenu: {
    [theme.breakpoints.down(750)]: {
      display: "none",
    }
  },
  hiddenHamburger: {
    [theme.breakpoints.up(750)]: {
      display: "none",
    }
  },
}));

const Header = ({ innerRef }) => {
  const classes = useStyles();
  const history = useHistory();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1000,
  });

  const isLogin = localStorage.getItem("isLogin");
  const menu = ["Beranda", "Program Perusahaan", "Lowongan Pekerjaan"];
  const menuHam = ["Login", "Register"];
  const [anchorEl, setAnchorEl] = useState({
    search: null,
    profile: null,
    TambahanMenu: null,
  });
  const [open, setOpen] = useState({
    search: false,
    profile: false,
    TambahanMenu: false,
  });

  const [dataProfile, setDataProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const isSecured = JSON.parse(localStorage.getItem("userisSecured")) == true;

  useEffect(() => {
    if (isLogin) {
      getProfile();
    }
  
    const unlisten = history.listen(() => {
      if (isLogin) {
        getProfile();
      }
    });
  
    return () => {
      unlisten();
    };
  }, [isLogin, history]);

  const getProfile = () => {
    setLoading(true);
    API(`profile.getDetailProfile`, {
      params: { idUser: localStorage.getItem("usersId") },
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setDataProfile(res.data.data);
        }
      })
      .catch((res) => {
        setLoading(false);
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Gagal mendapat data profile!",
        });
      });
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };
  
  
  return (
    <AppBar position="fixed" color="inherit">
      <Toolbar className={classes.toolbar} disableGutters>
        <img
          className={classes.logo}
          alt="logo"
          src={process.env.PUBLIC_URL + "/images/logo.png"}
        />
        <Box className={classes.menu}>
          {(!isLogin || isSecured) && menu.map((item, i) => (
            
          <Grid className={classes.hiddenMenu}>
            <Typography
              key={i}
              className={classes.textMenu}
              onClick={() => {
                history.push("/");
                if (i !== 0) {
                  setTimeout(() => {
                    innerRef[
                      item.split(" ")[0].toLowerCase()
                    ].current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }, 1000);
                } else {
                  localStorage.setItem("categoryApplicant","")
                  window.scrollTo(0, 0);
                }
              }}
            >
              {item}
            </Typography>

          </Grid>
          ))}
          
          {/* <IconButton
            className={classes.iconButton}
            color="secondary"
            onClick={(e) => {
              setAnchorEl({
                search: e.currentTarget,
                profile: anchorEl.profile,
              });
              setOpen({ search: true, profile: open.profile });
            }}
          >
            <Search className={classes.iconSearch} />
          </IconButton>
          {open.search && (
            <ClickAwayListener
              onClickAway={() => {
                setOpen({ search: false, profile: open.profile });
              }}
            >
              <Popper
                style={{ zIndex: 10000 }}
                disablePortal
                placement="bottom-end"
                open={open.search}
                anchorEl={anchorEl.search}
              >
                <Paper className={classes.search}>
                  <TextField
                    fullWidth
                    hiddenLabel
                    size="small"
                    variant="outlined"
                    placeholder="Pencarian"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Paper>
              </Popper>
            </ClickAwayListener>
          )} */}
          {open.profile && (
            <ClickAwayListener
              onClickAway={() => {
                setOpen({ search: open.search, profile: false });
              }}
            >
              <Popper
                style={{ zIndex: 10000 }}
                disablePortal
                placement="bottom-end"
                open={open.profile}
                anchorEl={anchorEl.profile}
              >
                
                <Paper>
                  <Box className={classes.info}>
                  <Fab
                    style ={{placeSelf: "center", backgroundColor:"transparent" }}
                      className={classes.profile}
                      onClick={(e) => {
                        setAnchorEl({
                          search: anchorEl.search,
                          profile: e.currentTarget,
                        });
                        setOpen({ search: open.search, profile: true });
                      }}
                  >
                    { isEmptyObject(dataProfile) || dataProfile.applicantPassPhotoPath == null ? (
                    
                          <Person className={classes.profileIcon2} /> 
                    
                      ) : (
                        <img
                          className={classes.logo1}
                          alt="pas photo"
                          src={`${config.BASE_URL}${dataProfile?.applicantPassPhotoPath}`}
                        />
                      )}
                  </Fab>
       
      
                    {/* <Fab className={classes.profile1} disabled>
                      <Person className={classes.profileIcon1} />
                    </Fab> */}
                    <Typography className={classes.infoName}>
                      {dataProfile.applicantName}
                    </Typography>

                  </Box>

                  {isSecured ? (
                    <List className={classes.action}>
                      <ListItem
                        className={classes.item}
                        button
                        onClick={() => {
                          setOpen({ search: open.search, profile: false });
                          history.push("/");
                        }}
                      >
                        <Typography className={classes.actionText}>Beranda</Typography>
                      </ListItem>
                      <ListItem
                        className={classes.item}
                        button
                        onClick={() => {
                          setOpen({ search: open.search, profile: false });
                          history.push("/lamaran-pekerjaan");
                        }}
                      >
                        <Typography className={classes.actionText}>Lamaran Pekerjaan</Typography>
                      </ListItem>
                      <ListItem
                        className={classes.item}
                        button
                        onClick={() => {
                          setOpen({ search: open.search, profile: false });
                          history.push("/detail-profile");
                        }}
                      >
                        <Typography className={classes.actionText}>Profile Saya</Typography>
                      </ListItem>
                      <ListItem
                        className={classes.item}
                        button
                        onClick={() => {
                          setOpen({ search: open.search, profile: false });
                          history.push("/edit-profile");
                        }}
                      >
                        <Typography className={classes.actionText}>Edit Profile</Typography>
                      </ListItem>
                      <ListItem
                        className={classes.item}
                        button
                        onClick={() => {
                          setOpen({ search: open.search, profile: false });
                          history.push("/ganti-password");
                        }}
                      >
                        <Typography className={classes.actionText}>Ganti Password</Typography>
                      </ListItem>
                      <ListItem
                        className={classes.item}
                        button
                        onClick={() => {
                          // localStorage.removeItem("isLogin");
                          localStorage.clear();
                          setOpen({ search: open.search, profile: false });
                          history.push("/");
                        }}
                      >
                        <Typography className={classes.actionText}>Logout</Typography>
                      </ListItem>
                    </List>
                  ) : (
                    <List className={classes.action}>
                      <ListItem
                        className={classes.item}
                        button
                        onClick={() => {
                          setOpen({ search: open.search, profile: false });
                          history.push("/ganti-password");
                        }}
                      >
                        <Typography className={classes.actionText}>Ganti Password</Typography>
                      </ListItem>
                      <ListItem
                        className={classes.item}
                        button
                        onClick={() => {
                          // localStorage.removeItem("isLogin");
                          localStorage.clear();
                          setOpen({ search: open.search, profile: false });
                          history.push("/");
                        }}
                      >
                        <Typography className={classes.actionText}>Logout</Typography>
                      </ListItem>
                    </List>
                  )}

                </Paper>
              </Popper>
            </ClickAwayListener>
          )}
          {open.TambahanMenu && (
            <ClickAwayListener
              onClickAway={() => {
                setOpen({ search: open.search, TambahanMenu: false });
              }}
            >
              <Popper
                style={{ zIndex: 10000 }}
                disablePortal
                placement="bottom-end"
                open={open.TambahanMenu}
                anchorEl={anchorEl.TambahanMenu}
              >
                
                <Paper>
                  
                  <List className={classes.action}>
                    {menu.map((item, i) => (
                    <ListItem className={classes.hiddenHamburger}>
                    {/* <Grid className={classes.borderCoba}> */}
                    <Typography
                      key={i}
                      className={classes.item}
                      onClick={() => {
                        history.push("/");

                        if (i !== 0) {
                          const refKey = item.split(" ")[0].toLowerCase();
                          const ref = innerRef[refKey];

                          if (ref && ref.current) {
                            setTimeout(() => {
                              if (ref.current) {
                                ref.current.scrollIntoView({
                                  behavior: "smooth",
                                });
                              } else {
                                console.warn(`Reference for key "${refKey}" is null.`);
                              }
                            }, 1000);
                          } else {
                            console.warn(`Reference for key "${refKey}" not found.`);
                          }
                        } else {
                          localStorage.setItem("categoryApplicant", "");
                          window.scrollTo(0, 0);
                        }
                      }}
                    >
                      {item}
                    </Typography>

                      {/* </Grid> */}
                      
                      </ListItem>
                      
                      
                    ))}

                  </List>
                  <List className={classes.action}>
                    {menuHam.map((item, i) => (
                    
                    <ListItem className={classes.hiddenHamburger}>
                    {/* <Grid className={classes.borderCoba}> */}
                    <Typography
                      key={i}
                      className={classes.item}
                      onClick={() => {
                        history.push("/" + item);

                        if (i !== 0) {
                          const refKey = item.split(" ")[0].toLowerCase();
                          const ref = innerRef[refKey];

                          if (ref && ref.current) {
                            setTimeout(() => {
                              ref.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            }, 1000);
                          } else {
                            console.warn(`Reference for key "${refKey}" not found.`);
                          }
                        } else {
                          localStorage.setItem("categoryApplicant", "");
                          window.scrollTo(0, 0);
                        }
                      }}
                    >
                      {item}
                    </Typography>

                      {/* </Grid> */}
                      
                      </ListItem>
                      
                      
                    ))}

                  </List>

                </Paper>
              </Popper>
            </ClickAwayListener>
          )}
          {isLogin ? (
            <Fab
              className={classes.profile}
              onClick={(e) => {
                setAnchorEl({
                  search: anchorEl.search,
                  profile: e.currentTarget,
                });
                setOpen({ search: open.search, profile: true });
              }}
            >
              { isEmptyObject(dataProfile) || dataProfile.applicantPassPhotoPath == null ? (
                    
                    <Person className={classes.profileIcon2} /> 
              
                ) : (
                  <img
                    className={classes.logo1}
                    alt="pas photo"
                    src={`${config.BASE_URL}${dataProfile?.applicantPassPhotoPath}`}
                  />
                )}
            </Fab>
       
            ) : (
            
            <Box className={classes.menu}>
              <Box className={classes.hiddenMenu}>
                <Button
                  className={classes.buttonLogin}
                  variant="outlined"
                  color="secondary"
                  onClick={() => history.push("/login")}
                >
                  Login
                </Button>
                <Button
                  className={classes.buttonDaftar}
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push("/register")}
                >
                  Daftar
                </Button>
              </Box>
              <Box className={classes.hiddenHamburger}>
                <Fab
                  className={classes.MenuHamburger}
                  onClick={(e) => {
                    setAnchorEl({
                      search: anchorEl.search,
                      TambahanMenu: e.currentTarget,
                    });
                    setOpen({ search: open.search, TambahanMenu: true });
                  }}
                >
                  <Dehaze className={classes.HamburgerIcon} />
                  
                </Fab>
              </Box>
            </Box>
          )}
        </Box>
      </Toolbar>
      <Zoom in={trigger}>
        <Fab
          className={classes.arrow}
          color="secondary"
          onClick={() =>
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
        >
          <KeyboardArrowUp className={classes.arrowIcon} />
        </Fab>
      </Zoom>
    </AppBar>
  );
};

export default Header;
