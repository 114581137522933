const formModel = {
  register: {
    kodeUnik: {
      name: "applicantUniqueCode",
      label: "Kode Unik",
      requiredErrorMsg: "Validasi e-mail untuk mendapat kode unik!",
    },
  },
  identitasDiri: {
    namaLengkap: {
      name: "applicantName",
      label: "Nama Lengkap",
      requiredErrorMsg: "Harap isi nama lengkap!",
    },
    jenisKelamin: {
      name: "applicantGender",
      label: "Jenis Kelamin",
      requiredErrorMsg: "Harap pilih jenis kelamin!",
    },
    nik: {
      name: "applicantNik",
      label: "NIK",
      requiredErrorMsg: "Harap isi NIK!",
      typeErrorMsg: "Format NIK tidak sesuai!",
      typeErrorMsgMin: "Masukkan minimal 16 digit angka!",
      minimal: 16,
    },
    fileKtp: {
      name: "applicantUploadKtpPath",
      label: "Upload KTP (jpg/jpeg/png)",
      requiredErrorMsg: "Harap upload file KTP!",
    },
    tempatLahir: {
      name: "applicantBirthPlace",
      nameRegister: "applicantBirthPlaceId",
      label: "Tempat Lahir",
      requiredErrorMsg: "Harap pilih tempat lahir!",
    },
    tanggalLahir: {
      name: "applicantBirthDate",
      label: "Tanggal Lahir",
      requiredErrorMsg: "Harap isi tanggal lahir!",
      typeErrorMsg: "Tanggal tidak sesuai!",
    },
    email: {
      name: "applicantEmail",
      label: "E-mail",
      requiredErrorMsg: "Harap isi e-mail!",
      typeErrorMsg: "Email tidak sesuai!",
    },
    nomorHandphone: {
      name: "applicantPhoneNumber",
      label1: "Nomor Handphone",
      label2: "No. Handphone",
      requiredErrorMsg: "Harap isi nomor handphone!",
      typeErrorMsg: "Masukkan dalam bentuk angka!",
      typeErrorMsgMin: "Masukkan minimal 10 digit angka!",
      typeErrorMsgMax: "Maximum 15 digit angka!",
      minimal: 10,
      maximum: 15,
    },
    password: {
      name: "applicantPassword",
      label: "Password",
      requiredErrorMsg: "Harap isi password!",
    },
    konfirmasiPassword: {
      name: "applicantConfirmationPassword",
      label: "Konfirmasi Password",
      requiredErrorMsg: "Harap isi konfirmasi password!",
      typeErrorMsg: "Tidak sesuai dengan password!",
    },
    domisiliKtp: {
      name: "applicantDomicileKtp",
      label: "Domisili Sesuai KTP",
      requiredErrorMsg: "Harap pilih domisili sesuai KTP!",
    },
    alamatKtp: {
      name: "applicantAddressKtp",
      label: "Alamat Sesuai KTP",
      requiredErrorMsg: "Harap isi alamat sesuai KTP!",
    },
    domisiliSekarang: {
      name: "applicantDomicileCurrent",
      label: "Domisili Saat Ini",
      requiredErrorMsg: "Harap pilih domisili saat ini!",
    },
    alamatSekarang: {
      name: "applicantAddressCurrent",
      label: "Alamat Saat Ini",
      requiredErrorMsg: "Harap isi alamat saat ini!",
    },
    nomorKk: {
      name: "applicantNumberKk",
      label: "Nomor KK",
      requiredErrorMsg: "Harap isi nomor KK!",
      typeErrorMsg: "Format KK tidak sesuai!",
      typeErrorMsgMin: "Masukkan minimal 16 digit angka!",
      minimal: 16,
    },
    npwp: {
      name: "applicantNpwp",
      label: "NPWP",
      requiredErrorMsg: "Harap isi NPWP!",
      typeErrorMsg: "Masukkan dalam bentuk angka!",
      typeErrorMsgMin: "Masukkan 15/16 digit angka!",
      minimal: 15,
      maximum: 16,
    },
    agama: {
      name: "applicantReligion",
      label: "Agama",
      requiredErrorMsg: "Harap pilih agama!",
    },
    kewarganegaraan: {
      name: "applicantCitizen",
      label: "Kewarganegaraan",
      requiredErrorMsg: "Harap pilih kewarganegaraan!",
    },
    suku: {
      name: "applicantEthnic",
      label: "Suku",
      requiredErrorMsg: "Harap pilih suku!",
    },
    statusKawin: {
      name: "applicantMaritalStatus",
      label: "Status Perkawinan",
      requiredErrorMsg: "Harap pilih status perkawinan!",
    },
    tinggiBadan: {
      name: "applicantHeight",
      label: "Tinggi Badan",
      requiredErrorMsg: "Harap isi tinggi badan!",
      maximum: 3,
    },
    beratBadan: {
      name: "applicantWeight",
      label: "Berat Badan",
      requiredErrorMsg: "Harap isi berat badan!",
      maximum: 3,
    },
    golonganDarah: {
      name: "applicantBloodType",
      label: "Golongan Darah",
      requiredErrorMsg: "Harap pilih golongan darah!",
    },
    warnaKulit: {
      name: "applicantSkinColor",
      label: "Warna Kulit",
      requiredErrorMsg: "Harap pilih warna kulit!",
    },
    bentukMuka: {
      name: "applicantFaceShape",
      label: "Bentuk Muka",
      requiredErrorMsg: "Harap pilih bentuk muka!",
    },
    jenisRambut: {
      name: "applicantHairType",
      label: "Jenis Rambut",
      requiredErrorMsg: "Harap pilih jenis rambut!",
    },
    bisaInggris: {
      name: "isEnglish",
      label: "Bisa Berbahasa Inggris",
      requiredErrorMsg: "Harap pilih bisa berbahasa inggris!",
    },
    bisaQuran: {
      name: "isReadQuran",
      label: "Bisa Membaca Al-Qur'an",
      requiredErrorMsg: "Harap pilih bisa membaca al-qur'an!",
    },
    instagram: {
      name: "applicantIg",
      label: "Akun Instagram",
      requiredErrorMsg: "Harap isi akun instagram!",
      maximum: 25,
    },
    twitter: {
      name: "applicantTwitter",
      label: "Akun Twitter",
      requiredErrorMsg: "Harap isi akun twitter!",
      maximum: 25,
    },
    facebook: {
      name: "applicantFb",
      label: "Akun Facebook",
      requiredErrorMsg: "Harap isi akun facebook!",
      maximum: 50,
    },
    linkedIn: {
      name: "applicantLinkedin",
      label: "Akun LinkedIn",
      requiredErrorMsg: "Harap isi akun LinkedIn!",
      maximum: 50,
    },
    pasFoto: {
      name: "applicantPassPhotoPath",
      label: "Upload Pas Foto",
      label1: "(jpg/jpeg/png)",
      requiredErrorMsg: "Harap upload pas foto!",
    },
    fullFoto: {
      name: "applicantFullBodyPath",
      label: "Upload Foto Seluruh Badan",
      label1: "(jpg/jpeg/png)",
      requiredErrorMsg: "Harap upload foto seluruh badan!",
    },
    selfieFoto: {
      name: "applicantSelfiePath",
      label: "Upload Foto Selfie",
      label1: "(jpg/jpeg/png)",
      requiredErrorMsg: "Harap upload foto selfie!",
    },
    cv: {
      name: "applicantCvName",
      path: "applicantCvPath",
      label: "Upload CV",
      label1: "pdf",
      requiredErrorMsg: "Harap upload CV!",
    },
  },
  riwayatPendidikan: {
    nomorIjazah: {
      name: "eduBgDiplomaNumber",
      label: "Nomor Ijazah/SKL",
      requiredErrorMsg: "Harap isi nomor ijazah!",
      maximum: 20,
    },
    tahunMasuk: {
      name: "eduBgYearOfEntry",
      label: "Tahun Masuk",
      requiredErrorMsg: "Harap pilih tahun masuk!",
      typeErrorMsg: "Tahun tidak sesuai!",
    },
    tahunLulus: {
      name: "eduBgGraduationYear",
      label: "Tahun Lulus",
      requiredErrorMsg: "Harap pilih tahun lulus!",
      typeErrorMsg: "Tahun tidak sesuai!",
    },
    semester: {
      name: "eduBgStudyPeriod",
      label: "Masa Studi (semester)",
      requiredErrorMsg: "Harap pilih masa studi!",
    },
    ipk: {
      name: "eduBgIpk",
      label: "IPK",
      requiredErrorMsg: "Harap isi IPK!",
      typeErrorMsg: "Format tidak sesuai!",
    },
    tanggalIjazah: {
      name: "eduBgDiplomaDate",
      label: "Tanggal Ijazah/SKL",
      requiredErrorMsg: "Harap isi tanggal ijazah!",
      typeErrorMsg: "Tanggal tidak sesuai!",
    },
    strata: {
      name: "eduBgLevelOfEdu",
      label: "Strata",
      requiredErrorMsg: "Harap pilih strata!",
    },
    institusi: {
      parentName: "institution",
      childName: "institutionId",
      label: "Nama Lembaga Pendidikan",
      requiredErrorMsg: "Harap pilih lembaga pendidikan!",
    },
    fakultas: {
      parentName: "faculty",
      childName: "facultyId",
      label: "Fakultas",
      requiredErrorMsg: "Harap pilih fakultas!",
    },
    jurusan: {
      parentName: "major",
      childName: "majorId",
      label: "Jurusan",
      requiredErrorMsg: "Harap pilih jurusan!",
    },
  },
  riwayatOrganisasi: {
    namaOrganisasi: {
      name: "organizationHistoryName",
      label: "Nama Organisasi",
      requiredErrorMsg: "Harap isi nama organisasi!",
    },
    lingkup: {
      name: "organizationHistoryScope",
      label: "Lingkup",
      requiredErrorMsg: "Harap isi lingkup organisasi!",
    },
    jabatan: {
      name: "organizationHistoryPosition",
      label: "Jabatan",
      requiredErrorMsg: "Harap isi jabatan organisasi!",
    },
    masaJabatan: {
      name: "organizationHistoryPeriod",
      label: "Masa Jabatan (tahun)",
      requiredErrorMsg: "Harap isi masa jabatan!",
      maximum: 2,
    },
  },
  riwayatPekerjaan: {
    namaPerusahaan: {
      name: "workExpCompanyName",
      label: "Nama Perusahaan",
      requiredErrorMsg: "Harap isi nama perusahaan!",
    },
    industri: {
      name: "workExpIndustry",
      label: "Industri",
      requiredErrorMsg: "Harap pilih industri!",
    },
    industriKeterangan: {
      name: "workExpIndustryKeterangan",
      label: "Keterangan",
      requiredErrorMsg: "Harap isi industri!",
    },
    status: {
      name: "workExpJobStatus",
      label: "Status Pekerjaan",
      requiredErrorMsg: "Harap pilih status pekerjaan!",
    },
    tahunMasuk: {
      name: "workExpStartYear",
      label: "Tahun Masuk",
      requiredErrorMsg: "Harap pilih tahun masuk!",
      typeErrorMsg: "Tahun tidak sesuai!",
    },
    tahunAkhir: {
      name: "workExpEndYear",
      label: "Tahun Akhir",
      requiredErrorMsg: "Harap pilih tahun akhir!",
      typeErrorMsg: "Tahun tidak sesuai!",
    },
    masihBerkerja: {
      name: "isCurrent",
      label: "Hingga saat ini",
    },
    level: {
      name: "workExpLevel",
      label: "Level",
      requiredErrorMsg: "Harap pilih level!",
    },
    jabatan: {
      name: "workExpLastPosition",
      label: "Jabatan",
      requiredErrorMsg: "Harap isi jabatan pekerjaan!",
    },
    gajiTerakhir: {
      name: "workExpLastSalary",
      label: "Gaji Terakhir",
      requiredErrorMsg: "Harap isi gaji terakhir!",
    },
    namaAtasan: {
      name: "workExpSuperiorName",
      label: "Nama Atasan",
      requiredErrorMsg: "Harap isi nama atasan!",
    },
    kontakAtasan: {
      name: "workExpSuperiorContact",
      label: "Kontak Atasan",
      requiredErrorMsg: "Harap isi kontak atasan!",
      typeErrorMsgMin: "Masukkan minimal 10 digit angka!",
      typeErrorMsgMax: "Maximum 15 digit angka!",
      minimal: 10,
      maximum: 15,
    },
    kontakPerusahaan: {
      name: "workExpCompanyContact",
      label: "Kontak Perusahaan",
      requiredErrorMsg: "Harap isi kontak perusahaan!",
      typeErrorMsgMin: "Masukkan minimal 10 digit angka!",
      typeErrorMsgMax: "Maximum 15 digit angka!",
      minimal: 10,
      maximum: 15,
    },
    totalBekerja: {
      name: "workExpTotalWorkingTime",
      label: "Total Lama Bekerja (tahun)",
      requiredErrorMsg: "Harap isi total lama bekerja!",
    },
  },
  riwayatKursus: {
    namaKursus: {
      name: "courseName",
      label: "Riwayat Kursus",
      requiredErrorMsg: "Harap isi riwayat kursus!",
    },
    penyelenggara: {
      name: "courseOrganize",
      label: "Jenis Kursus",
      requiredErrorMsg: "Harap isi jenis kursus!",
    },
    tanggalMulai: {
      name: "courseDateStart",
      label: "Tanggal Mulai",
      requiredErrorMsg: "Harap isi tanggal mulai!",
      typeErrorMsg: "Tanggal tidak sesuai!",
    },
    tanggalAkhir: {
      name: "courseDateEnd",
      label: "Tanggal Berakhir",
      requiredErrorMsg: "Harap isi tanggal berakhir!",
      typeErrorMsg: "Tanggal tidak sesuai!",
    },
    nomorSertifikat: {
      name: "courseNumberCertificate",
      label: "Nomor Sertifikat",
      requiredErrorMsg: "Harap isi nomor sertifikat!",
    },
    tanggalSertifikat: {
      name: "courseDateCertificate",
      label: "Tanggal Sertifikat",
      requiredErrorMsg: "Harap isi tanggal sertifikat!",
      typeErrorMsg: "Tanggal tidak sesuai!",
    },
    predikat: {
      name: "coursePredicate",
      label: "Predikat",
      requiredErrorMsg: "Harap isi predikat!",
      maximum: 3,
    },
    nilai: {
      name: "courseScore",
      label: "Nilai",
      requiredErrorMsg: "Harap isi nilai!",
      maximum: 3,
    },
    isForever: {
      name: "isForever",
      label: "Berlaku selamanya"
    },
  },
  riwayatSertifikasi: {
    namaPanjang: {
      name: "certificationLongName",
      label: "Nama Sertifikat",
      requiredErrorMsg: "Harap isi nama sertifikat!",
    },
    namaSingkat: {
      name: "certificationAbbreviation",
      label: "Singkatan",
      requiredErrorMsg: "Harap isi singkatan sertifikasi!",
      maximum: 10,
    },
    tanggalSertifikasi: {
      name: "certificationDate",
      label: "Tanggal Sertifikasi",
      requiredErrorMsg: "Harap isi tanggal sertifikasi!",
      typeErrorMsg: "Tanggal tidak sesuai!",
    },
    tanggalAkhir: {
      name: "certificationExpiredDate",
      label: "Tanggal Akhir Berlaku",
      requiredErrorMsg: "Harap isi tanggal akhir berlaku!",
      typeErrorMsg: "Tanggal tidak sesuai!",
    },
    tanggalSelamanya: {
      name: "dateCertificate",
      label: "Tanggal Sertifikat",
      requiredErrorMsg: "Harap isi tanggal sertifikat!",
      typeErrorMsg: "Tanggal tidak sesuai!",
    },
    isForever: {
      name: "isForever",
      label: "Berlaku selamanya"
    },
    nomorSertifikasi: {
      name: "certificationNumber",
      label: "Nomor Sertifikat",
      requiredErrorMsg: "Harap isi nomor sertifikat!",
      maximum: 20,
    },
    namaLembaga: {
      name: "certificationInstitutionName",
      label: "Nama Lembaga",
      requiredErrorMsg: "Harap isi nama lembaga!",
      maximum: 50,
    },
    predikat: {
      name: "certificationPredicate",
      label: "Predikat",
      requiredErrorMsg: "Harap isi predikat!",
      maximum: 3,
    },
    nilai: {
      name: "certificationScore",
      label: "Nilai",
      requiredErrorMsg: "Harap isi nilai!",
      maximum: 3,
    },
  },
  informasiLamaran: {
    posisiPekerjaan: {
      name: "positionId",
      label: "Posisi Pekerjaan",
      requiredErrorMsg: "Harap pilih posisi pekerjaan!",
    },
    namaPosisiPekerjaan: {
      name: "positionName",
      label: "Posisi Pekerjaan",
      requiredErrorMsg: "Harap pilih posisi pekerjaan!",
    },
    harapanGaji: {
      name: "othersExpectSalary",
      label: "Harapan Gaji",
      requiredErrorMsg: "Harap isi harapan gaji!",
    },
    minatPenempatan: {
      name: "othersPreferredPlacement",
      label: "Penempatan yang Diminati",
      requiredErrorMsg: "Harap isi penempatan yang diminati!",
    },
    status: {
      name: "othersJobSeekerStatus",
      label: "Status Pencari Kerja",
      requiredErrorMsg: "Harap pilih status pencari kerja!",
    },
    setuju: {
      name: "confIsCorrectStatement",
      label: "Saya setuju dengan persetujuan pelamar yang berlaku",
      requiredErrorMsg: "Harap check untuk setuju!",
    },
  },
  gantiPassword: {
    oldPassword: {
      name: "oldPassword",
      label: "Kata Sandi Lama",
      Karakter: {
        requiredErrorMsg: "Minimal 8 Karakter",
        maximum: 20,
      },
      Symbol: {
        requiredErrorMsg: "Belum Menggunakan Simbol",
        maximum: 20,
      },
      HurufBesar: {
        requiredErrorMsg: "Belum Menggunakan Huruf Besar",
        maximum: 20,
      },
    },
    newPassword: {
      name: "newPassword",
      label: "Kata Sandi Baru",
      Karakter: {
        requiredErrorMsg: "Minimal 8 Karakter",
        maximum: 20,
      },
      Symbol: {
        requiredErrorMsg: "Belum Menggunakan Simbol",
        maximum: 20,
      },
      HurufBesar: {
        requiredErrorMsg: "Belum Menggunakan Huruf Besar",
        maximum: 20,
      },
    },
    konfirmasiPassword: {
      name: "konfirmasiPassword",
      label: "Konfirmasi Kata Sandi",
      Karakter: {
        requiredErrorMsg: "Minimal 8 Karakter",
        maximum: 20,
      },
      Symbol: {
        requiredErrorMsg: "Belum Menggunakan Simbol",
        maximum: 20,
      },
      HurufBesar: {
        requiredErrorMsg: "Belum Menggunakan Huruf Besar",
        maximum: 20,
      },
    },
  },

};

export default formModel;
