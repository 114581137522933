import React from "react";
import {
  makeStyles,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Box,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import GlobalFunction from "../../../GlobalFunction";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.1vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
    }
  },
  content: {
    padding: "3vh 2.5vw",
    [theme.breakpoints.down(550)]: {
      padding: "3vh",
    },
  },
  accordion: {
    borderRadius: 8,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
  },
  subtitleLabel: {
    color: "#005A7D",
    marginBottom: 8,
    // fontSize: "0.625vw",
    fontSize: "0.825vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.7rem",
    }
  },
  contentLabel: {
    color: "#011627",
    fontWeight: "bold",
    // fontSize: "0.7vw",
    fontSize: "0.9vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.7rem",
    }
  },
  sizeFormHide1: {
    [theme.breakpoints.down(550)]: {
      display: "none",
    },

  },
  sizeFormHide2: {
    [theme.breakpoints.up(550)]: {
      display: "none",
    },
  },
}));

const RiwayatPekerjaan = ({ data }) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        id="detail-riwayat-pekerjaan"
        className={classes.content}
      >
        <Typography className={classes.title}>
          Riwayat Pekerjaan ({data?.length})
        </Typography>
      </AccordionSummary>
      {data?.map((item, index) => (
        // console.log(item.workExpIndustry === 'Lainnya' ? item.workExpIndustryKeterangan : item.workExpIndustry || "-"),
        <Box key={`riwayat-pekerjaan-${index}`}>
          <Divider />
          <AccordionDetails className={classes.content}>
            <Grid container spacing={5} className={classes.sizeFormHide1}>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Nama Perusahaan
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpCompanyName || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Industri
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpIndustry === 'Lainnya' ? item.workExpIndustryKeterangan : item.workExpIndustry || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Status Pekerjaan
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpJobStatus || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Tahun Masuk - Akhir
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpStartYear && item.workExpEndYear
                    ? `${item.workExpStartYear} - ${item.workExpEndYear}`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Level
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpLevel || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Jabatan
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpLastPosition || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Gaji Terakhir
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpLastSalary
                    ? `Rp ${GlobalFunction.numberWithDot(
                        item.workExpLastSalary
                      )}`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Nama Atasan
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpSuperiorName || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Kontak Atasan
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpSuperiorContact
                    ? `0${item.workExpSuperiorContact}`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Kontak Perusahaan
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpCompanyContact
                    ? GlobalFunction.phoneFormat(item.workExpCompanyContact)
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Total Lama Bekerja
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpTotalWorkingTime
                    ? `${item.workExpTotalWorkingTime} Tahun`
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={5} className={classes.sizeFormHide2}>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Nama Perusahaan
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpCompanyName || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Industri
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpIndustry === 'Lainnya' ? item.workExpIndustryKeterangan : item.workExpIndustry || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Status Pekerjaan
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpJobStatus || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Tahun Masuk - Akhir
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpStartYear && item.workExpEndYear
                    ? `${item.workExpStartYear} - ${item.workExpEndYear}`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Level
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpLevel || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Jabatan
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpLastPosition || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Gaji Terakhir
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpLastSalary
                    ? `Rp ${GlobalFunction.numberWithDot(
                        item.workExpLastSalary
                      )}`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Nama Atasan
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpSuperiorName || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Kontak Atasan
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpSuperiorContact
                    ? `0${item.workExpSuperiorContact}`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Kontak Perusahaan
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpCompanyContact
                    ? GlobalFunction.phoneFormat(item.workExpCompanyContact)
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Total Lama Bekerja
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.workExpTotalWorkingTime
                    ? `${item.workExpTotalWorkingTime} Tahun`
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Box>
      ))}
    </Accordion>
  );
};

export default RiwayatPekerjaan;
