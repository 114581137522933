const endPoints = {
  validate: {
    getSubmitValidation: {
      method: "get",
      url: "/secured/home/applicantSubmissionValidation/validate",
    },
    getJalurKhusus: {
      method: "get",
      url: "/secured/home/program-khusus/check",
    },
  },
  footer: {
    getFooterOpen: {
      method: "get",
      url: "/open/home/footer-section/show",
    },
    getFooterSecured: {
      method: "get",
      url: "/secured/home/footer-section/show",
    },
    getFaqOpen: {
      method: "get",
      url: "/open/home/faq/show-all",
    },
    getFaqSecured: {
      method: "get",
      url: "/secured/home/faq/show-all",
    },
  },
  forgotPassword: {
    sentEmail: {
      method: "post",
      url: "/open/users/forgotPassword",
    },
  },
  register: {
    createRegister: {
      method: "post",
      url: "/open/users/registerApplicant",
    },
    validateEmail: {
      method: "post",
      url: "/open/users/validateEmail",
    },
    getLovCity: { method: "get", url: "/open/city/find-all" },
    uploadKtp: { method: "post", url: "/open/uploadKtp" },
  },
  profile: {
    getDetailProfile: {
      method: "get",
      url: "/secured/home/profile",
    },
    getDetailProfile1: {
      method: "get",
      url: "/secured/home/profile?idUser=:id",
    },
    getDefaultData: {
      method: "get",
      url: "/secured/users/detailApplicant/:id",
    },
    editProfile: {
      method: "put",
      url: "/secured/users/updateApplicant/:id",
    },
  },
  lamaranKerja: {
    getLamaranKerja: {
      method: "get",
      url: "/secured/submission-form/find-all",
    },
    getDetailLamaranKerja: {
      method: "get",
      url: "/secured/submission-form/details/:id",
    },
    downloadKartu: {
      method: "get",
      url: "/secured/submission-form/download-kartu",
    },
    downloadDokumen: {
      method: "get",
      url: "/secured/submission-form/download-dokumen",
    },
  },
  formulirKerja: {
    submitForm: {
      method: "post",
      url: "/secured/applicant/submitForm",
    },
    getLovFakultas: {
      method: "get",
      url: "/open/applicant/faculty/find-all",
    },
    getLovJurusan: {
      method: "get",
      url: "/open/applicant/major/find-all",
    },
    getLovInstitusi: {
      method: "get",
      url: "/open/applicant/institution/find-all",
    },
    getLovStrata: {
      method: "get",
      url: "/secured/lov/strata",
    },
    getLovJabatan: {
      method: "get",
      url: "/secured/lov/jabatan",
    },
    getLovIndustri: {
      method: "get",
      url: "/secured/lov/industri",
    },
    getLovLevel: {
      method: "get",
      url: "/secured/lov/level",
    },
    uploadImage: {
      method: "post",
      url: "/secured/uploadImage",
    },
    uploadFile: {
      method: "post",
      url: "/secured/uploadDoc",
    },
    getLovAgama: { method: "get", url: "/open/lov/agama/find-all" },
    getLovKewarganegaraan: {
      method: "get",
      url: "/open/lov/kewarganegaraan/find-all",
    },
    getLovSuku: {
      method: "get",
      url: "/open/lov/suku/find-all",
    },
    getLovStatusKawin: {
      method: "get",
      url: "/open/lov/status-perkawinan/find-all",
    },
    getLovGolonganDarah: {
      method: "get",
      url: "/open/lov/golongan-darah/find-all",
    },
    getLovWarnaKulit: {
      method: "get",
      url: "/open/lov/warna-kulit/find-all",
    },
    getLovBentukMuka: {
      method: "get",
      url: "/secured/lov/bentuk-wajah",
    },
    getLovJenisRambut: {
      method: "get",
      url: "/open/lov/jenis-rambut/find-all",
    },
    getLovPekerjaan: {
      method: "get",
      url: "/open/lov/position/find-all",
    },
    getLovProgram: {
      method: "get",
      url: "/secured/program-khusus/lov",
    },
  },
  home: {
    getSectionOpen: { method: "get", url: "/open/home/urutan/show" },
    getSectionSecured: { method: "get", url: "/secured/home/urutan/show" },
    getHeroOpen: {
      method: "get",
      url: "/open/home/hero-section/show",
    },
    getHeroSecured: {
      method: "get",
      url: "/secured/home/hero-section/show",
    },
    getVideoRecruitmentOpen: {
      method: "get",
      url: "/open/home/vr-section/show",
    },
    getVideoRecruitmentSecured: {
      method: "get",
      url: "/secured/home/vr-section/show",
    },
    getAllCultureOpen: {
      method: "get",
      url: "/open/home/culture-value-section/show-all",
    },
    getAllCultureSecured: {
      method: "get",
      url: "/secured/home/culture-value-section/show-all",
    },
    getShortProgramOpen: {
      method: "get",
      url: "/open/home/short-program-section/show-all",
    },
    getShortProgramSecured: {
      method: "get",
      url: "/secured/home/short-program-section/show-all",
    },
    getSelectionSectionOpen: {
      method: "get",
      url: "/open/home/selection-flow-section/show",
    },
    getSelectionSectionSecured: {
      method: "get",
      url: "/secured/home/selection-flow-section/show",
    },
    getAllSelectionFlowOpen: {
      method: "get",
      url: "/open/home/step-selection/find-all",
    },
    getAllSelectionFlowSecured: {
      method: "get",
      url: "/secured/home/step-selection/find-all",
    },
    getEventSectionOpen: {
      method: "get",
      url: "/open/home/event-recruitment-section/show",
    },
    getEventSectionSecured: {
      method: "get",
      url: "/secured/home/event-recruitment-section/show",
    },
    getAllEventRecruitmentOpen: {
      method: "get",
      url: "/open/home/event-recruitment/find-all",
    },
    getAllEventRecruitmentSecured: {
      method: "get",
      url: "/secured/home/event-recruitment/find-all",
    },
    getJobSectionOpen: {
      method: "get",
      url: "/open/home/job-vacancy-section/show",
    },
    getJobSectionSecured: {
      method: "get",
      url: "/secured/home/job-vacancy-section/show",
    },
    getAllJobVacancyOpen: {
      method: "get",
      url: "/open/home/job-vacancy/find-all",
    },
    getAllJobVacancySecured: {
      method: "get",
      url: "/secured/home/job-vacancy/find-all",
    },
    getLovCategoryOpen: {
      method: "get",
      url: "/open/home/category/find-all",
    },
    getLovCategorySecured: {
      method: "get",
      url: "/secured/home/category/find-all",
    },
    getLovLocationOpen: {
      method: "get",
      url: "/open/home/location/find-all",
    },
    getLovLocationSecured: {
      method: "get",
      url: "/secured/home/location/find-all",
    },
    getAboutUsOpen: {
      method: "get",
      url: "/open/home/about-us-section/show",
    },
    getAboutUsSecured: {
      method: "get",
      url: "/secured/home/about-us-section/show",
    },
    getSetting: {
      method: "get",
      url: '/secured/settings/category/find-all'
    }
  },
  GantiPassword: {
    submitPassword: {
      method: "post",
      url: "/api/v1/applicant/update-password",
    },
  }
};

export default endPoints;
