import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Divider,
  Paper,
  Avatar,
  Fab,
} from "@material-ui/core";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";
import { config } from "../../../config";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import GlobalFunction from "../../../GlobalFunction";

const useStyles = makeStyles((theme) => ({
  alurSeleksi: {
    background: "#fff",
    textAlign: "center",
    padding: "7vw 0",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "2vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "3vw",
    }
  },
  desc: {
    color: theme.palette.primary.main,
    fontSize: "1vw",
    marginTop: "1.5vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "2.3vw",
    }
  },
  divider: {
    width: "2.5vw",
    margin: "1vw auto",
    background: theme.palette.secondary.main,
    height: "0.3vw",
  },
  alurPadding: {
    paddingTop: "3vw",
  },
  grid: {
    padding: "1vw",
  },
  paper: {
    padding: "2vw",
    height: "15vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "12vw",
    [theme.breakpoints.down(750)]: {
      width: "19vw",
      height: "20vw",
    }
  },
  image: {
    width: "100%",
    marginBottom: "0.5vw",
  },
  step: {
    display: "flex",
  },
  no: {
    background: theme.palette.secondary.main,
    width: "1vw",
    height: "1vw",
    fontSize: "0.7vw",
    [theme.breakpoints.down(750)]: {
      width: "2vw",
      height: "2vw",
      fontSize: "1.7vw",
    }
  },
  text: {
    fontSize: "0.8vw",
    fontWeight: "bold",
    textAlign: "left",
    lineHeight: "1.4",
    marginLeft: "0.35vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "1.7vw",
    }
  },
  arrowPadding: {
    width: "4.5vw",
    margin: "auto",
  },
  arrow: {
    background: theme.palette.background.paper,
    width: "3vw",
    height: "3vw",
    minHeight: 0,
    cursor: "pointer",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    "&&:active": {
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
  },
}));

const AlurSeleksi = ({ innerRef }) => {
  const classes = useStyles();
  const isLogin = localStorage.getItem("isLogin");
  const [dataSelectionSection, setDataSelectionSection] = useState({});
  const [dataAllSelection, setDataAllSelection] = useState([]);
  const [message, setMessage] = useState("Success");

  useEffect(() => {
    if (isLogin) {
      getSelectionSection("secured");
      getAllSelectionFlow("secured");
    } else {
      getSelectionSection("open");
      getAllSelectionFlow("open");
    }
  }, [isLogin]);

  const getSelectionSection = (type) => {
    const url =
      type === "open"
        ? "home.getSelectionSectionOpen"
        : "home.getSelectionSectionSecured";

    API(`${url}`)
      .then((res) => {
        setMessage(res.data?.Message);
        if (res.status === 200 && res.data?.Message === "Success" || res.data?.Message === "Not Found") {
          setDataSelectionSection(res.data?.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data Seleksi!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data Seleksi!",
        });
      });
  };

  const getAllSelectionFlow = (type) => {
    const url =
      type === "open"
        ? "home.getAllSelectionFlowOpen"
        : "home.getAllSelectionFlowSecured";

    API(`${url}`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataAllSelection(res.data?.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data Alur Seleksi!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data Alur Seleksi!",
        });
      });
  };

  const [pageCard, setPageCard] = useState(0);

  const handleClickPageCard = (type) => {
    if (type === "next") {
      setPageCard(pageCard + 1);
    } else {
      setPageCard(pageCard - 1);
    }
  };

  return (
    <div>
      {message.toLowerCase() !== "not found" && (
        <Box ref={innerRef} className={classes.alurSeleksi}>
          <Typography className={classes.title}>
            {dataSelectionSection.selectionFlowSectionTitle || "Alur Seleksi"}
          </Typography>
          <Typography className={classes.desc}>
            {dataSelectionSection.selectionFlowSectionSubtitle ||
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
          </Typography>
          <Divider className={classes.divider} />
          <Box className={classes.alurPadding}>
            {dataAllSelection.length > 0 && (
              <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                  <Box className={classes.arrowPadding}>
                    <Fab
                      className={classes.arrow}
                      onClick={() => handleClickPageCard("previous")}
                      disabled={pageCard === 0}
                    >
                      <ArrowLeft className={classes.icon} />
                    </Fab>
                  </Box>
                </Grid>
                {dataAllSelection.map((item, i) => (
                  <div key={i}>
                    {i >= pageCard && i <= pageCard + 3 && (
                      <Grid className={classes.grid} item>
                        <Paper className={classes.paper} elevation={1}>
                          <img
                            className={classes.image}
                            alt={item.stepSelectionPhotoName || `logo-${i}`}
                            src={
                              item.stepSelectionPhotoPath
                                ? `${config.BASE_URL}${item.stepSelectionPhotoPath}`
                                : `${process.env.PUBLIC_URL}/images/home/alur-${
                                    i + 1
                                  }.png`
                            }
                            style={{ maxHeight: "15vw", width: "auto" }}
                            onError={GlobalFunction.handleImageError}
                          />
                          <Box className={classes.step}>
                            <Avatar className={classes.no}>
                              {item.urutan}
                            </Avatar>
                            <Typography className={classes.text}>
                              {item.stepSelectionName}
                            </Typography>
                          </Box>
                        </Paper>
                      </Grid>
                    )}
                  </div>
                ))}
                <Grid item>
                  <Box className={classes.arrowPadding}>
                    <Fab
                      className={classes.arrow}
                      onClick={() => handleClickPageCard("next")}
                      disabled={dataAllSelection.length - 1 <= pageCard + 3}
                    >
                      <ArrowRight className={classes.icon} />
                    </Fab>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default AlurSeleksi;
