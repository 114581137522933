import React from "react";
import {
  Box,
  Card,
  Grid,
  Typography,
  makeStyles,
//   Modal,
  Divider,
//   Button,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { useHistory } from "react-router";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

const useStyles = makeStyles((theme) => ({
  root: {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    // width: "34vw",
    width: "50%",
  },
  lokerTitle: {
    color: theme.palette.primary.main,
    fontSize: "1.5vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.875rem",
    },
  },
  subtitleLabel: {
    color: "#005A7D",
    // marginBottom: 15,
    [theme.breakpoints.down(750)]: {
      fontSize: "3vw",
    },
  },
  spasi: {
    marginTop: "3rem !important",
    // marginBottom: 15,
    [theme.breakpoints.down(750)]: {
      marginTop: "1rem !important",
      
    },
  },
  contentLabel: {
    color: "#011627",
    fontWeight: "bold",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down(750)]: {
      fontWeight: "700",
      fontSize: "3vw",
    },
  },

  contentLabel1: {
    color: "#011627",
    // fontWeight: "bold",
    whiteSpace: "pre-wrap",
    fontWeight: "400",
    [theme.breakpoints.down(750)]: {
      fontSize: "3vw",
    },
  },
  boxContent: {
    marginBottom: 30,
    // marginBottom: "20%",
  },

  boxContentBawah: {
    // marginBottom: 30,
    marginBottom: "10%",
  },
  OVERLAY_STYLE: {
    position: "absolute",
    // display: "flex",
    justifyContent: "center",
    // top: "0",
    left: "0",
    width: "80%",
    // height: "100%",
    height: "inherit",
    // height: "-webkit-fill-available",
    // backgroundColor: "rgba(0,0,0, .8)",
    zIndex: "1000",
    overflowY: "auto",
    backgroundColor:"white",
  },
  OVERLAY_STYLE1: {
    position: "absolute",
    // display: "flex",
    justifyContent: "center",
    top: "0",
    bottom: "0",
    left: "0",
    width: "70%",
    // height: "100%",
    height: "80%",
    // backgroundColor: "rgba(0,0,0, .8)",
    zIndex: "1000",
    // overflowY: "auto",
    backgroundColor:"white",
    right: 0,
    margin: "auto",
    // marginLeft: "auto",
    // marginRight: "auto",
  },
  modalContent: {
    position: "absolute",
    maxHeight: "80%",
  },
  sizeFormHide1: {
    [theme.breakpoints.down(538)]: {
      display: "none",
    },

  },
  sizeFormHide2: {
    [theme.breakpoints.up(538)]: {
      display: "none",
    },
  },
}));

const DetailLoker = ({ dataLoker, modalVisible, handleClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const isLogin = localStorage.getItem("isLogin");

  const getDefaultData = () => {
    
    API(`profile.getDefaultData`, {
      query: { id: localStorage.getItem("applicantId") },
    })
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          var temp = { ...res.data.Data };
          temp.applicant.applicantGender =
            temp.applicant.applicantGender.toString();


        } else {
          Toast.fire({
            icon: "info",
            title: res.data.Message || "Gagal mendapat data default!",
          });
          history.push("/");
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Gagal mendapat data default!",
        });
        history.push("/");
      });
  };

  const submitForm = (data) => {
    API(`formulirKerja.submitForm`, { data })
      .then((res) => {
        if (res.data.status === 200 || res.data.Message === "Success") {
          Toast.fire({
            icon: "success",
            title: 
              "Terima Kasih, Lamaran anda sudah diterima, Mohon tunggu proses selanjutnya",
          });
          history.push("/");
        }else{
          Toast.fire({
            icon: "warning",
            title: res.data.Message || "Formulir gagal dikirim!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Formulir gagal dikirim!",
        });
      });
  };

  return (
    // <Modal open={modalVisible} onClose={handleClose}>
    //   <Box className={classes.root}>
    //     <Card style={{ borderRadius: 8 }}
    //           className={classes.OVERLAY_STYLE1}>
    //       <Grid
    //         container
    //         direction="row"
    //         justifyContent="space-between"
    //         alignItems="center"
    //         style={{ padding: 32 }}
    //       >
    //         <Typography className={classes.lokerTitle}>
    //           Detail Lowongan
    //         </Typography>
    //         <CloseOutlined
    //           style={{ cursor: "pointer", color: "#011627" }}
    //           onClick={handleClose}
    //         />
    //       </Grid>
    //       <Divider />
    //       <Box style={{ padding: "3% 10%" }}
    //             className={classes.OVERLAY_STYLE}>
    //         <Grid
    //           container
    //           direction="row"
    //           justifyContent="space-between"
    //           alignItems="center"
    //           className={classes.boxContent}
    //           spacing={2}
    //         >
    //           <Grid item>
    //             <Typography
    //               variant="subtitle2"
    //               className={classes.subtitleLabel}
    //             >
    //               Posisi Kerja
    //             </Typography>
    //             <Typography
    //               variant="subtitle1"
    //               className={classes.contentLabel}
    //             >
    //               {dataLoker?.position?.positionName}
    //             </Typography>
    //           </Grid>
    //           <Grid item>
    //             <Typography
    //               variant="subtitle2"
    //               className={classes.subtitleLabel}
    //             >
    //               Kategori Lowongan
    //             </Typography>
    //             <Typography
    //               variant="subtitle1"
    //               className={classes.contentLabel}
    //             >
    //               {dataLoker?.category?.categoryName}
    //             </Typography>
    //           </Grid>
    //           <Grid item>
    //             <Typography
    //               variant="subtitle2"
    //               className={classes.subtitleLabel}
    //               >
    //               Lokasi
    //             </Typography>
    //             <Typography
    //               variant="subtitle1"
    //               className={classes.contentLabel}
    //             >
    //               {dataLoker?.location?.locationName}
    //             </Typography>
    //           </Grid>
    //         </Grid>
    //         <Box>
    //           <Typography variant="subtitle2" className={classes.subtitleLabel}>
    //             Persyaratan dan Ketentuan
    //           </Typography>
    //           <Box style={{ paddingLeft: 20 }}>
    //             <Grid
    //               container
    //               direction="row"
    //               justifyContent="space-between"
    //               alignItems="center"
    //               className={classes.boxContent}
    //               spacing={2}
    //               >
    //                 <Grid item>
    //                 {dataLoker?.jobVacancyTnc?.split("\\n").map((word, index) => (
    //                   <>
    //                     <div key={index} className={classes.contentLabel}>
    //                       {word}
    //                     </div>
    //                   </>
    //                 ))}
    //               </Grid>
    //               <Grid item className={classes.boxContentBawah}>
    //                 <Button
    //                   className={classes.buttonKategori}
    //                   variant="contained"
    //                   color="secondary"
    //                   onClick={() => {
    //                     handleClose();
    //                     if(isLogin!=null){
    //                       API(`profile.getDefaultData`, {
    //                         query: { id: localStorage.getItem("applicantId") },
    //                       })
    //                         .then((res) => {
    //                           if(isLogin){
    //                             if(localStorage.getItem("submitValidation") === "Progress"){
    //                               Swal.fire({
    //                                 title: 'Apakah kamu yakin ingin mendaftar pekerjaan ini?!',
    //                                 // text: "Apakah kamu yakin ingin mendaftar pekerjaan ini?!",
    //                                 icon: 'warning',
    //                                 showCancelButton: true,
    //                                 confirmButtonColor: '#3085d6',
    //                                 cancelButtonColor: '#d33',
    //                                 confirmButtonText: 'Ya, Daftar!',
    //                                 cancelButtonText: 'Tidak jadi',
    //                               }).then((result) => {
    //                                 if (result.isConfirmed) {
    //                                   var temp = { ...res.data.Data };
                           
    //                                   temp.applicant.applicantGender =
    //                                   temp.applicant.applicantGender.toString();
  
    //                                   delete temp.applicant.users;
  
    //                                   temp.certificationHistoriesList?.forEach((item, i, arr) => {
    //                                     delete arr[i].certificationHistoryId;
    //                                   });
    //                                   temp.courseHistoriesList?.forEach((item, i, arr) => {
    //                                     delete arr[i].courseHistoryId;
    //                                   });
    //                                   temp.educationalBackgroundsList?.forEach((item, i, arr) => {
    //                                     delete arr[i].educationalBackgroundId;
    //                                   });
    //                                   temp.organizationHistoriesList?.forEach((item, i, arr) => {
    //                                     delete arr[i].organizationHistoryId;
    //                                   });
    //                                   temp.workExperiencesList?.forEach((item, i, arr) => {
    //                                     delete arr[i].workExperienceId;
    //                                     arr[i].workExpLastSalary = parseInt(arr[i].workExpLastSalary);
    //                                   });
    //                                   API('profile.getDetailProfile1', {
    //                                     query: { id: localStorage.getItem("usersId") },
    //                                   }).then((res1) => {
    //                                   var detailLamaran = res1?.data?.data.submissionFormCustomDto;
                                    
    //                                   const dataSubmit = {
    //                                     applicant: temp.applicant,
    //                                     educationalBackgroundsList: [...temp.educationalBackgroundsList],
    //                                     organizationHistoriesList: [...temp.organizationHistoriesList],
    //                                     workExperiencesList: [...temp.workExperiencesList],
    //                                     courseHistoriessList: [...temp.courseHistoriesList],
    //                                     certificationHistoriesList: [...temp.certificationHistoriesList],
    //                                     submissionForm: {
    //                                       othersExpectSalary: detailLamaran[0].othersExpectSalary,
    //                                       othersJobSeekerStatus : detailLamaran[0].othersJobSeekerStatus,
    //                                       othersPreferredPlacement:  detailLamaran[0].othersPreferredPlacement,
    //                                       category: {
    //                                         categoryId: parseInt(dataLoker.category.categoryId),
    //                                       },
    //                                       applicant: {
    //                                         applicantId: parseInt(localStorage.getItem("applicantId")),
    //                                       },
    //                                     },
    //                                     positionId: dataLoker.position.positionId,
    //                                     programKhususApplicant: null,
    //                                 }
    //                                 submitForm(dataSubmit);
    //                               })
    //                               }
    //                             })
    //                             }else if(localStorage.getItem("submitValidation") === "Approved" || localStorage.getItem("submitValidation") === "Rejected" ){
    //                               Toast.fire({
    //                                 icon: "info",
    //                                 title:
    //                                   "Mohon Maaf, Anda hanya bisa melamar 1 kali dalam 1 tahun",
    //                               });
    //                             }else{                               
    //                               history.push({
    //                                 pathname: `/formulir-kerja/${dataLoker?.category?.categoryId}`,
    //                                 state: { data: dataLoker },
    //                               });
    //                             }
    //                           }else{
    //                               history.push(`/register`);
    //                           }
  
    //                         // console.log(initialValues);
    //                         }
    //                       )
    //                     }else{
    //                       Swal.fire({
    //                         // position: 'top-end',
    //                         icon: 'warning',
    //                         title: 'Harap melakukan login terlebih dahulu',
    //                         showConfirmButton: false,
    //                         timer: 1000
    //                       }).then(() => {
    //                         // Redirect to login menu
    //                         window.location = "/login";
    //                       });

    //                     }
                  
                        
    //                   }
    //                   }
    //                 >
    //                   Apply
    //                 </Button>
    //               </Grid>
    //             </Grid>

    //           </Box>

    //         </Box>
    //       </Box>
    //     </Card>
    //   </Box>
    // </Modal>
    <Modal
      show={modalVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
        <style>{"\
            .modal-content{\
                max-height:80% !important;\
                position: absolute !important;\
            }\
            "}
        </style>
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title id="contained-modal-title-vcenter" className={classes.lokerTitle}>
            Detail Lowongan
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
            <Row className={classes.sizeFormHide1}>
              <Row>
                  <Col xs={4} className={classes.subtitleLabel}>
                      Posisi Kerja
                  </Col>
                  <Col xs={4} className={classes.subtitleLabel}>
                      Kategori Lowongan
                  </Col>
                  <Col xs={4} className={classes.subtitleLabel}>
                      Lokasi
                  </Col>
              </Row>
              <Row className="mt-3">
                  <Col xs={4} className={classes.contentLabel}>
                      {dataLoker?.position?.positionName}
                  </Col>
                  <Col xs={4} className={classes.contentLabel}>
                      {dataLoker?.category?.categoryName}
                  </Col>
                  <Col xs={4} className={classes.contentLabel}>
                      {dataLoker?.location?.locationName}
                  </Col>
              </Row>
            </Row>
            <Row className={classes.sizeFormHide2}>
              <Row>
                  <Row className="mt-2">
                  <Col xs={6} className={classes.subtitleLabel}>
                      Posisi Kerja
                  </Col>
                    <Col xs={6} className={classes.subtitleLabel}>
                        Kategori Lowongan
                    </Col>
                  </Row>
                  <Row className="mt-2">
                  <Col xs={6} className={classes.contentLabel}>
                      {dataLoker?.position?.positionName}
                  </Col>
                    <Col xs={6} className={classes.contentLabel}>
                        {dataLoker?.category?.categoryName}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12} className={classes.subtitleLabel}>
                        Lokasi
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs={12} className={classes.contentLabel}>
                        {dataLoker?.location?.locationName}
                    </Col>
                  </Row>
              </Row>
           
            </Row>
            <Row className={classes.spasi}>
                <Col className={classes.subtitleLabel}>
                    Persyaratan dan Ketentuan
                </Col>
            </Row>
            <Row className="mt-3" style={{paddingLeft: 20}}>
                <Col>
                {dataLoker?.jobVacancyTnc?.split("\\n").map((word, index) => (
                <>
                    <div key={index} className={classes.contentLabel1}>
                    {word}
                    </div>
                </>
                ))}
                </Col>
            </Row>
            <Row>
                <Col>
                
                </Col>
            </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
            className="btn-danger"
            variant="contained"
            onClick={() => {
            handleClose();
            if(isLogin!=null){
                API(`profile.getDefaultData`, {
                query: { id: localStorage.getItem("applicantId") },
                })
                .then((res) => {
                    if(isLogin){
                    if(localStorage.getItem("submitValidation") === "Progress"){
                        Swal.fire({
                        title: 'Apakah kamu yakin ingin mendaftar pekerjaan ini?!',
                        // text: "Apakah kamu yakin ingin mendaftar pekerjaan ini?!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ya, Daftar!',
                        cancelButtonText: 'Tidak jadi',
                        }).then((result) => {
                        if (result.isConfirmed) {
                            var temp = { ...res.data.Data };
                
                            temp.applicant.applicantGender =
                            temp.applicant.applicantGender.toString();

                            delete temp.applicant.users;

                            temp.certificationHistoriesList?.forEach((item, i, arr) => {
                            delete arr[i].certificationHistoryId;
                            });
                            temp.courseHistoriesList?.forEach((item, i, arr) => {
                            delete arr[i].courseHistoryId;
                            });
                            temp.educationalBackgroundsList?.forEach((item, i, arr) => {
                            delete arr[i].educationalBackgroundId;
                            });
                            temp.organizationHistoriesList?.forEach((item, i, arr) => {
                            delete arr[i].organizationHistoryId;
                            });
                            temp.workExperiencesList?.forEach((item, i, arr) => {
                            delete arr[i].workExperienceId;
                            arr[i].workExpLastSalary = parseInt(arr[i].workExpLastSalary);
                            });
                            API('profile.getDetailProfile1', {
                            query: { id: localStorage.getItem("usersId") },
                            }).then((res1) => {
                            var detailLamaran = res1?.data?.data.submissionFormCustomDto;
                        
                            const dataSubmit = {
                            applicant: temp.applicant,
                            educationalBackgroundsList: [...temp.educationalBackgroundsList],
                            organizationHistoriesList: [...temp.organizationHistoriesList],
                            workExperiencesList: [...temp.workExperiencesList],
                            courseHistoriessList: [...temp.courseHistoriesList],
                            certificationHistoriesList: [...temp.certificationHistoriesList],
                            submissionForm: {
                                othersExpectSalary: detailLamaran[0].othersExpectSalary,
                                othersJobSeekerStatus : detailLamaran[0].othersJobSeekerStatus,
                                othersPreferredPlacement:  detailLamaran[0].othersPreferredPlacement,
                                category: {
                                categoryId: parseInt(dataLoker.category.categoryId),
                                },
                                applicant: {
                                applicantId: parseInt(localStorage.getItem("applicantId")),
                                },
                            },
                            positionId: dataLoker.position.positionId,
                            programKhususApplicant: null,
                        }
                        submitForm(dataSubmit);
                        })
                        }
                    })
                    }else if(localStorage.getItem("submitValidation") === "Approved" || localStorage.getItem("submitValidation") === "Rejected" ){
                        Toast.fire({
                        icon: "info",
                        title:
                            "Mohon Maaf, Anda hanya bisa melamar 1 kali dalam 1 tahun",
                        });
                    }else{                               
                        history.push({
                        pathname: `/formulir-kerja/${dataLoker?.category?.categoryId}`,
                        state: { data: dataLoker },
                        });
                    }
                    }else{
                        history.push(`/register`);
                    }

                // console.log(initialValues);
                }
                )
            }else{
                Swal.fire({
                // position: 'top-end',
                icon: 'warning',
                title: 'Harap melakukan login terlebih dahulu',
                showConfirmButton: false,
                timer: 1000
                }).then(() => {
                // Redirect to login menu
                window.location = "/login";
                });

            }
        
            
            }
            }
        >
            Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DetailLoker;
