import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Divider,
  Paper,
  Avatar,
  Button,
} from "@material-ui/core";
import { BusinessCenter } from "@material-ui/icons";
import { useHistory } from "react-router";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";
import { config } from "../../../config";
import GlobalFunction from "../../../GlobalFunction";

const useStyles = makeStyles((theme) => ({
  programSingkat: {
    background: "#fff",
    textAlign: "center",
    padding: "7vw 0",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "2vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "3vw",
    }
  },
  desc: {
    color: theme.palette.primary.main,
    fontSize: "1vw",
    marginTop: "1.5vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "2.3vw",
    }
  },
  divider: {
    width: "2.5vw",
    margin: "1vw auto",
    background: theme.palette.secondary.main,
    height: "0.3vw",
  },
  programPadding: {
    padding: "3vw 14vw 0 14vw",
  },
  grid: {
    padding: "1vw",
  },
  paper: {
    padding: "2vw",
  },
  step: {
    display: "flex",
  },
  avatar: {
    width: "4vw",
    height: "4vw",
    background: theme.palette.grey[200],
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: "2vw",
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: "1vw",
    textAlign: "left",
    margin: "auto",
    marginLeft: "1vw",
    height: "100%",
  },
  paperImg: {
    height: "89.5%",
    padding: "2vw",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  button: {
    fontSize: "0.9vw",
    padding: "1vw 1.5vw",
  },
}));

const ProgramSingkat = ({ innerRef }) => {
  const classes = useStyles();
  const history = useHistory();
  const isLogin = localStorage.getItem("isLogin");
  const [dataShortProgram, setDataShortProgram] = useState([]);
  const [message, setMessage] = useState("Success");

  useEffect(() => {
    if (isLogin) {
      getShortProgram("secured");
    } else {
      getShortProgram("open");
    }
  }, [isLogin]);

  const getShortProgram = (type) => {
    const url =
      type === "open"
        ? "home.getShortProgramOpen"
        : "home.getShortProgramSecured";

    API(`${url}`)
      .then((res) => {
        setMessage(res.data?.Message);
        if (res.status === 200 && res.data?.Message === "Success" || res.data?.Message === "Not Found") {
          setDataShortProgram(res.data?.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data Program!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data Program!",
        });
      });
  };

  return (
    <div>
      {message.toLowerCase() !== "not found" && (
        <Box ref={innerRef} className={classes.programSingkat}>
          <Typography className={classes.title}>
            {dataShortProgram[0]?.shortProgramSectionTitle || "Program Singkat"}
          </Typography>
          <Typography className={classes.desc}>
            {dataShortProgram[0]?.shortProgramSectionSubtitle ||
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
          </Typography>
          <Divider className={classes.divider} />
          <Box className={classes.programPadding}>
            <Grid container>
              <Grid item xs={6}>
                <Grid container>
                  {dataShortProgram.map((item, i) => (
                    <Grid className={classes.grid} key={i} item xs={12}>
                      <Paper className={classes.paper} elevation={1}>
                        <Box className={classes.step}>
                          <Avatar className={classes.avatar}>
                            <BusinessCenter className={classes.icon} />
                          </Avatar>
                          <Typography className={classes.text}>
                            {item.shortProgramSectionDesc}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Paper className={classes.paperImg} elevation={1}>
                  <img
                    className={classes.image}
                    alt="logo"
                    src={
                      dataShortProgram.length > 0 &&
                      dataShortProgram[0].shortProgramSectionImg
                        ? `${config.BASE_URL}${dataShortProgram[0].shortProgramSectionImg}`
                        : `${process.env.PUBLIC_URL}/images/home/program-singkat.png`
                    }
                    onError={GlobalFunction.handleImageError}
                  />
                </Paper>
              </Grid>
            </Grid>
            {/* <Grid
              container
              justifyContent="center"
              style={{ marginTop: "3vw" }}
            >
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => history.push("/register")}
              >
                Awali Karirmu
              </Button>
            </Grid> */}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default ProgramSingkat;
