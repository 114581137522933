import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  makeStyles,
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepButton,
  Button,
} from "@material-ui/core";
import { CheckCircleOutline, Adjust } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Toast } from "../../Component/Notification";

// Import Form
import Form1 from "../FormulirKerja/Form1";
import Form2 from "../FormulirKerja/Form2";
import Form3 from "../FormulirKerja/Form3";
import Form4 from "../FormulirKerja/Form4";
import Form5 from "../FormulirKerja/Form5";
import Form6 from "../FormulirKerja/Form6";
import formInitialValues from "../../../GlobalComponent/FormModel/formInitialValues";
import formValidationSchema from "../../../GlobalComponent/FormModel/validationSchema";
import formModel from "../../../GlobalComponent/FormModel/formModel";
import { API } from "../../../service/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5vw 14vw",
    [theme.breakpoints.down(750)]: {
      padding: "5vw",
    },
  },
  spacing: {
    padding: "0 1vw",
    width:"100%",
  },
  spacing1: {
    padding: "6% 1vw 0",
    width:"100%",
  },
  header: {
    padding: "3vw",
    borderBottom: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: "1.1vw",
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
    }
  },
  inputText: {
    fontSize: "1px",
  },
  content: {
    padding: "2vw 3vw",
  },
  stepIcon: {
    fontSize: "1.5vw",
  },
  stepLabel: {
    fontSize: "0.9vw",
    textAlign: "left",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.6rem",
      // fontSize: "0.9vw",
    },
  },
  stepLabelActive: {
    color: theme.palette.secondary.main,
    fontSize: "0.9vw",
    textAlign: "left",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.6rem",
    },
  },
  action: {
    padding: "2vw",
    borderTop: "1px solid #DDDDDD",
  },
  buttonLeft: {
    fontSize: "0.9vw",
    paddingTop: "1vw",
    paddingBottom: "1vw",
    marginRight: "1vw",
    [theme.breakpoints.down(750)]: {
      fontSize:"2.5vw",
    },
  },
  buttonRight: {
    fontSize: "0.9vw",
    paddingTop: "1vw",
    paddingBottom: "1vw",
    [theme.breakpoints.down(750)]: {
      fontSize:"2.5vw",
    },
  },
  sizeFormHide1: {
    [theme.breakpoints.down(750)]: {
      display: "none",
    },

  },
  sizeFormHide2: {
    [theme.breakpoints.up(750)]: {
      display: "none",
    },
  },
}));

const EditProfile = () => {
  const classes = useStyles();
  const history = useHistory();
  const [active, setActive] = useState(0);
  const [isSent, setIsSent] = useState(false);
  const [visited, setVisited] = useState({
    identitasDiri: true,
    riwayatPendidikan: false,
    riwayatOrganisasi: false,
    riwayatPekerjaan: false,
    riwayatKursus: false,
    riwayatSertifikasi: false,
  });

  useEffect(() => {
    getDefaultData();
  }, []);

  const submitForm = (data) => {
    API(`profile.editProfile`, {
      query: { id: localStorage.getItem("applicantId") },
      data,
    })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("isEdited",true)
          Toast.fire({
            icon: "success",
            title: res.data?.Message || "Profile Berhasil di Update!",
          });
          history.push("/");
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Profile Gagal di Update",
        });
      });
  };

  const stepIcon = (props) => {
    return props.completed ? (
      <CheckCircleOutline className={classes.stepIcon} color="secondary" />
    ) : (
      <Adjust
        className={classes.stepIcon}
        color={props.active ? "secondary" : "disabled"}
      />
    );
  };

  const initialValues = {
    identitasDiri: {
      ...formInitialValues.identitasDiri,
      [formModel.identitasDiri.namaLengkap.name]:
        localStorage.getItem("userName") || "",
      [formModel.identitasDiri.jenisKelamin.name]:
        localStorage.getItem("userGender") || "",
      [formModel.identitasDiri.nik.name]: localStorage.getItem("userNik") || "",
      [formModel.identitasDiri.fileKtp.name]:
        localStorage.getItem("userKtpPath") || "",
      [formModel.identitasDiri.tempatLahir.name]:
        localStorage.getItem("userBirthPlace") || null,
      [formModel.identitasDiri.tanggalLahir.name]: localStorage.getItem(
        "userBirthDate"
      )
        ? new Date(localStorage.getItem("userBirthDate"))
        : null,
      [formModel.identitasDiri.email.name]:
        localStorage.getItem("userEmail") || "",
      [formModel.identitasDiri.nomorHandphone.name]:
        localStorage.getItem("userPhoneNumber") || "",
    },
    riwayatPendidikan: [],
    riwayatOrganisasi: [],
    riwayatPekerjaan: [],
    riwayatKursus: [],
    riwayatSertifikasi: [],
  };

  const validationSchema = Yup.object().shape({
    identitasDiri: formValidationSchema.identitasDiri,
    riwayatPendidikan: Yup.array().of(formValidationSchema.riwayatPendidikan),
    riwayatOrganisasi: Yup.array().of(formValidationSchema.riwayatOrganisasi),
    riwayatPekerjaan: Yup.array().of(formValidationSchema.riwayatPekerjaan),
    riwayatKursus: Yup.array().of(formValidationSchema.riwayatKursus),
    riwayatSertifikasi: Yup.array().of(formValidationSchema.riwayatSertifikasi),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setTouched, setValues }) => {
      if (
        active === step.length - 1 &&
        step[active].name === "riwayatSertifikasi" &&
        isSent
      ) {
        const dataSubmit = {
          ...values.identitasDiri,
          educationalBackgroundsList: values.riwayatPendidikan,
          organizationHistoriesList: values.riwayatOrganisasi,
          workExperiencesList: values.riwayatPekerjaan,
          courseHistoriesList: values.riwayatKursus,
          certificationHistoriesList: values.riwayatSertifikasi,
        };
        submitForm(dataSubmit);
        setTouched({});
        setSubmitting(false);
      } else {
        setSubmitting(false);
        handleNext(values, setValues);
      }
    },
  });

  const getDefaultData = () => {
    API(`profile.getDefaultData`, {
      query: { id: localStorage.getItem("applicantId") },
    })
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          var temp = { ...res.data.Data };
          delete temp.applicant.applicantId;
          delete temp.applicant.users;
          temp.applicant.applicantGender =
            temp.applicant.applicantGender.toString();
          formik.setValues({
            identitasDiri: {
              ...formInitialValues.identitasDiri,
              ...temp.applicant,
              applicantNpwp:
                temp.applicant.applicantNpwp !== null
                  ? temp.applicant.applicantNpwp
                  : "",
            },
            riwayatPendidikan: [...temp.educationalBackgroundsList],
            riwayatOrganisasi: [...temp.organizationHistoriesList],
            riwayatPekerjaan: [...temp.workExperiencesList],
            riwayatKursus: [...temp.courseHistoriesList],
            riwayatSertifikasi: [...temp.certificationHistoriesList],
          });
        } else {
          Toast.fire({
            icon: "info",
            title: res.data.Message || "Gagal mendapat data default!",
          });
          history.push("/");
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Gagal mendapat data default!",
        });
        history.push("/");
      });
  };

  const handleNext = (values, setValues) => {
    if (step[active].name === "identitasDiri" && !visited.riwayatPendidikan) {
      setVisited({ ...visited, riwayatPendidikan: true });
      setActive(active + 1);
    } else if (
      values.riwayatPendidikan.length !== 0 &&
      step[active].name === "riwayatPendidikan" &&
      !visited.riwayatOrganisasi
    ) {
      setVisited({ ...visited, riwayatOrganisasi: true });
      setActive(active + 1);
    } else if (
      step[active].name === "riwayatOrganisasi" &&
      !visited.riwayatPekerjaan
    ) {
      setVisited({ ...visited, riwayatPekerjaan: true });
      setActive(active + 1);
    } else if (
      step[active].name === "riwayatPekerjaan" &&
      !visited.riwayatKursus
    ) {
      setVisited({ ...visited, riwayatKursus: true });
      setActive(active + 1);
    } else if (
      step[active].name === "riwayatKursus" &&
      !visited.riwayatSertifikasi
    ) {
      setVisited({ ...visited, riwayatSertifikasi: true });
      setActive(active + 1);
    }
  };

  const step = [
    {
      label: "Identitas Diri",
      name: "identitasDiri",
      component: (
        <Form1
          editDisabled={false}
          formik={formik}
          identitasDiri={formModel.identitasDiri}
          title="Identitas Diri"
          categoryId={1}
        />
      ),
    },
    {
      label: "Riwayat Pendidikan Terakhir",
      name: "riwayatPendidikan",
      component: (
        <Form2
          formik={formik}
          riwayatPendidikan={formModel.riwayatPendidikan}
          title="Riwayat Pendidikan Terakhir"
        />
      ),
    },
    {
      label: "Riwayat Organisasi",
      name: "riwayatOrganisasi",
      component: (
        <Form3
          formik={formik}
          riwayatOrganisasi={formModel.riwayatOrganisasi}
          title="Riwayat Organisasi"
        />
      ),
    },
    {
      label: "Riwayat Pekerjaan",
      name: "riwayatPekerjaan",
      component: (
        <Form4
          formik={formik}
          riwayatPekerjaan={formModel.riwayatPekerjaan}
          title="Riwayat Pekerjaan"
        />
      ),
    },
    {
      label: "Riwayat Kursus",
      name: "riwayatKursus",
      component: (
        <Form5
          title="Riwayat Kursus"
          formik={formik}
          riwayatKursus={formModel.riwayatKursus}
        />
      ),
    },
    {
      label: "Riwayat Sertifikasi",
      name: "riwayatSertifikasi",
      component: (
        <Form6
          title="Riwayat Sertifikasi"
          formik={formik}
          riwayatSertifikasi={formModel.riwayatSertifikasi}
        />
      ),
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className={classes.root}>
        <Grid container className={classes.sizeFormHide1}>
          <Grid className={classes.spacing} item xs={8}>
            {step[active].component}
          </Grid>
          <Grid className={classes.spacing} item xs={4}>
            <Card>
              <CardHeader
                className={classes.header}
                title={
                  <Typography className={classes.title}>
                    Edit Profile
                  </Typography>
                }
              />
              <CardContent className={classes.content}>
                <Stepper
                  style={{ padding: 0 }}
                  orientation="vertical"
                  activeStep={active}
                >
                  {step.map((item, i) => (
                    <Step key={i} expanded>
                      <StepButton completed={i < active ? true : false}>
                        <StepLabel StepIconComponent={stepIcon}>
                          <Typography
                            className={
                              i <= active
                                ? classes.stepLabelActive
                                : classes.stepLabel
                            }
                          >
                            {item.label}
                          </Typography>
                        </StepLabel>
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </CardContent>
              <CardActions className={classes.action}>
                <Button
                  className={classes.buttonLeft}
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  disabled={formik.isSubmitting && active === step.length - 1}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    active === 0 ? history.push("/") : setActive(active - 1);
                    setIsSent(false);
                  }}
                >
                  {active === 0 ? "Batal" : "Kembali"}
                </Button>
                <Button
                  className={classes.buttonRight}
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={formik.isSubmitting && active === step.length - 1}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    if (active !== step.length - 1) {
                      visited?.[`${step[active + 1].name}`] &&
                        setActive(active + 1);
                    } else {
                      setIsSent(true);
                    }
                  }}
                >
                  {formik.isSubmitting && active === step.length - 1 ? (
                    <i className="fas fa-circle-notch fa-spin" />
                  ) : (
                    ""
                  )}
                  {active === step.length - 1 ? "Kirim" : "Lanjut"}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <Grid container className={classes.sizeFormHide2}>
          <Grid className={classes.spacing} item>
            {step[active].component}
          </Grid>
          <Grid className={classes.spacing1} item>
            <Card>
              <CardHeader
                className={classes.header}
                title={
                  <Typography className={classes.title}>
                    Edit Profile
                  </Typography>
                }
              />
              <CardContent className={classes.content}>
                <Stepper
                  style={{ padding: 0 }}
                  orientation="vertical"
                  activeStep={active}
                >
                  {step.map((item, i) => (
                    <Step key={i} expanded>
                      <StepButton completed={i < active ? true : false}>
                        <StepLabel StepIconComponent={stepIcon}>
                          <Typography
                            className={
                              i <= active
                                ? classes.stepLabelActive
                                : classes.stepLabel
                            }
                          >
                            {item.label}
                          </Typography>
                        </StepLabel>
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </CardContent>
              <CardActions className={classes.action}>
                <Button
                  className={classes.buttonLeft}
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  disabled={formik.isSubmitting && active === step.length - 1}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    active === 0 ? history.push("/") : setActive(active - 1);
                    setIsSent(false);
                  }}
                >
                  {active === 0 ? "Batal" : "Kembali"}
                </Button>
                <Button
                  className={classes.buttonRight}
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={formik.isSubmitting && active === step.length - 1}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    if (active !== step.length - 1) {
                      visited?.[`${step[active + 1].name}`] &&
                        setActive(active + 1);
                    } else {
                      setIsSent(true);
                    }
                  }}
                >
                  {formik.isSubmitting && active === step.length - 1 ? (
                    <i className="fas fa-circle-notch fa-spin" />
                  ) : (
                    ""
                  )}
                  {active === step.length - 1 ? "Kirim" : "Lanjut"}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default EditProfile;
