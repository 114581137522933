import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
  IconButton,
} from "@material-ui/core";
import { Event, Close, ArrowDropDown } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import formInitialValues from "../../../GlobalComponent/FormModel/formInitialValues";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: "2vw",
  },
  header: {
    padding: "3vw",
    borderBottom: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
  },
  headerIsClose: {
    padding: "2.175vw 3vw",
    borderBottom: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
  },
  headerArea: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "1.1vw",
    fontWeight: "bold",
    margin: "auto",
    marginLeft: 0,
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
    }
  },
  content: {
    padding: 0,
    "&&:last-child": {
      paddingBottom: 0,
    },
  },
  container: {
    padding: "2vw",
    borderBottom: "1px solid #DDDDDD",
  },
  grid: {
    padding: "1vw",
  },
  label: {
    fontSize: "0.8vw",
    textAlign: "left",
    marginBottom: "0.4vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.8rem",
    }
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  buttonAdd: {
    fontSize: "0.9vw",
    fontWeight: "bold",
    paddingTop: "1vw",
    paddingBottom: "1vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "2vw",
      padding: "2%",
      marginTop: "4%",
    }
  },
  close: {
    textAlign: "right",
  },
  iconButtom: {
    padding: "0.8vw",
  },
  closeIcon: {
    fontSize: "1.7vw",
  },
}));

const Form = ({ title, formik, riwayatPendidikan }) => {
  const classes = useStyles();
  const ipkValidation = /^[0-9]*\.?[0-9]*$/;
  const [dataStrata, setDataStrata] = useState([]);
  const [dataInstitusi, setDataInstitusi] = useState([]);
  const [dataFakultas, setDataFakultas] = useState([]);
  const [dataJurusan, setDataJurusan] = useState([]);

  useEffect(() => {
    getDataStrata();
    getDataInstitusi();
    getDataFakultas();
    getDataJurusan();
  }, []);

  const getDataStrata = () => {
    API(`formulirKerja.getLovStrata`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataStrata(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: res.data?.Message || "Gagal mendapat data strata!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Gagal mendapat data strata!",
        });
      });
  };

  const getDataInstitusi = () => {
    API(`formulirKerja.getLovInstitusi`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataInstitusi(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title:
              res.data?.Message || "Gagal mendapat data lembaga pendidikan!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title:
            res.response?.data?.Message ||
            "Gagal mendapat data lembaga pendidikan!",
        });
      });
  };

  const getDataFakultas = () => {
    API(`formulirKerja.getLovFakultas`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataFakultas(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: res.data?.Message || "Gagal mendapat data fakultas!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Gagal mendapat data fakultas!",
        });
      });
  };

  const getDataJurusan = () => {
    API(`formulirKerja.getLovJurusan`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataJurusan(res.data.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: res.data?.Message || "Gagal mendapat data jurusan!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Gagal mendapat data jurusan!",
        });
      });
  };

  const countSemester = (index) => {
    let semester = 1;
    let menu = [1];

    if (
      formik.values.riwayatPendidikan[index][
        riwayatPendidikan.tahunMasuk.name
      ] &&
      formik.values.riwayatPendidikan[index][riwayatPendidikan.tahunLulus.name]
    ) {
      semester =
        (formik.values.riwayatPendidikan[index][
          riwayatPendidikan.tahunLulus.name
        ] -
          formik.values.riwayatPendidikan[index][
            riwayatPendidikan.tahunMasuk.name
          ]) *
        2;
    }

    for (let count = 1; count <= semester - 1; count++) {
      menu.push(count + 1);
    }

    return menu;
  };

  const onAddForm = () => {
    const tempForm = [...formik.values.riwayatPendidikan];
    tempForm.push({ ...formInitialValues.riwayatPendidikan });
    formik.setValues({ ...formik.values, riwayatPendidikan: tempForm });
  };

  const onRemoveForm = (i) => {
    const formTemp = [...formik.values?.riwayatPendidikan];
    const touchTemp = [...formik.touched?.riwayatPendidikan];

    formTemp?.splice(i, 1);
    touchTemp?.splice(i, 1);
    formik.setValues({ ...formik.values, riwayatPendidikan: formTemp });
    formik.setTouched({ ...formik.touched, riwayatPendidikan: touchTemp });

    if (formik.errors?.hasOwnProperty("riwayatPendidikan")) {
      const errorTemp = [...formik.errors?.riwayatPendidikan];
      errorTemp?.splice(i, 1);
      formik.setErrors({ ...formik.errors, riwayatPendidikan: errorTemp });
    }
  };

  return (
    <Box>
      {formik.values.riwayatPendidikan?.map((e, i) => (
        <Card className={classes.card} key={i}>
          <CardHeader
            className={i === 0 ? classes.header : classes.headerIsClose}
            title={
              <Box className={classes.headerArea}>
                <Typography className={classes.title}>
                  {title + " - " + (i + 1)}
                </Typography>

                {i > 0 && (
                  <IconButton
                    className={classes.iconButtom}
                    onClick={() => onRemoveForm(i)}
                  >
                    <Close className={classes.closeIcon} />
                  </IconButton>
                )}
              </Box>
            }
          />
          <CardContent className={classes.content}>
            <Grid className={classes.container} container>
              <Grid className={classes.grid} item xs={12}>
                <Typography className={classes.label}>
                  {riwayatPendidikan.strata.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  select
                  size="small"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatPendidikan.${i}.${riwayatPendidikan.strata.name}`}
                  value={
                    formik.values.riwayatPendidikan[i][
                      riwayatPendidikan.strata.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatPendidikan &&
                    formik.touched.riwayatPendidikan &&
                    formik.touched?.riwayatPendidikan[i]?.[
                      riwayatPendidikan.strata.name
                    ] &&
                    formik.errors?.riwayatPendidikan[i]?.[
                      riwayatPendidikan.strata.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatPendidikan &&
                    formik.touched.riwayatPendidikan &&
                    formik.errors.riwayatPendidikan[i]?.[
                      riwayatPendidikan.strata.name
                    ] &&
                    formik.touched.riwayatPendidikan[i]?.[
                      riwayatPendidikan.strata.name
                    ]
                      ? formik.errors.riwayatPendidikan[i]?.[
                          riwayatPendidikan.strata.name
                        ]
                      : ""
                  }
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem disabled value="">
                    Pilih Strata
                  </MenuItem>
                  {dataStrata.map((item) => (
                    <MenuItem key={item.strataId} value={item.strataName}>
                      {item.strataName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid className={classes.grid} item xs={12}>
                <Typography className={classes.label}>
                  {riwayatPendidikan.institusi.label}
                </Typography>
                <Autocomplete
                  options={dataInstitusi}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      fullWidth
                      size="small"
                      name={`riwayatPendidikan.${i}.${riwayatPendidikan.institusi.parentName}.${riwayatPendidikan.institusi.childName}`}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.riwayatPendidikan &&
                        formik.touched.riwayatPendidikan &&
                        formik.touched?.riwayatPendidikan[i]?.[
                          riwayatPendidikan.institusi.parentName
                        ]?.[riwayatPendidikan.institusi.childName] &&
                        formik.errors?.riwayatPendidikan[i]?.[
                          riwayatPendidikan.institusi.parentName
                        ]?.[riwayatPendidikan.institusi.childName] &&
                        true
                      }
                      helperText={
                        formik.errors.riwayatPendidikan &&
                        formik.touched.riwayatPendidikan &&
                        formik.errors.riwayatPendidikan[i]?.[
                          riwayatPendidikan.institusi.parentName
                        ]?.[riwayatPendidikan.institusi.childName] &&
                        formik.touched.riwayatPendidikan[i]?.[
                          riwayatPendidikan.institusi.parentName
                        ]?.[riwayatPendidikan.institusi.childName]
                          ? formik.errors.riwayatPendidikan[i]?.[
                              riwayatPendidikan.institusi.parentName
                            ]?.[riwayatPendidikan.institusi.childName]
                          : ""
                      }
                      label="Pilih Lembaga Pendidikan"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                  getOptionLabel={(option) => option.institutionName}
                  value={
                    dataInstitusi?.find(
                      (item) =>
                        item.institutionId ===
                        formik.values.riwayatPendidikan[i][
                          riwayatPendidikan.institusi.parentName
                        ][riwayatPendidikan.institusi.childName]
                    ) || null
                  }
                  onChange={(_event, newOption) => {
                    formik.setFieldValue(
                      `riwayatPendidikan.${i}.${riwayatPendidikan.institusi.parentName}.${riwayatPendidikan.institusi.childName}`,
                      newOption?.institutionId || null
                    );
                  }}
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPendidikan.fakultas.label}
                </Typography>
                <Autocomplete
                  options={dataFakultas}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      fullWidth
                      size="small"
                      name={`riwayatPendidikan.${i}.${riwayatPendidikan.fakultas.parentName}.${riwayatPendidikan.fakultas.childName}`}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.riwayatPendidikan &&
                        formik.touched.riwayatPendidikan &&
                        formik.touched?.riwayatPendidikan[i]?.[
                          riwayatPendidikan.fakultas.parentName
                        ]?.[riwayatPendidikan.fakultas.childName] &&
                        formik.errors?.riwayatPendidikan[i]?.[
                          riwayatPendidikan.fakultas.parentName
                        ]?.[riwayatPendidikan.fakultas.childName] &&
                        true
                      }
                      helperText={
                        formik.errors.riwayatPendidikan &&
                        formik.touched.riwayatPendidikan &&
                        formik.errors.riwayatPendidikan[i]?.[
                          riwayatPendidikan.fakultas.parentName
                        ]?.[riwayatPendidikan.fakultas.childName] &&
                        formik.touched.riwayatPendidikan[i]?.[
                          riwayatPendidikan.fakultas.parentName
                        ]?.[riwayatPendidikan.fakultas.childName]
                          ? formik.errors.riwayatPendidikan[i]?.[
                              riwayatPendidikan.fakultas.parentName
                            ]?.[riwayatPendidikan.fakultas.childName]
                          : ""
                      }
                      label="Pilih Fakultas"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                  getOptionLabel={(option) => option.facultyName}
                  value={
                    dataFakultas?.find(
                      (item) =>
                        item.facultyId ===
                        formik.values.riwayatPendidikan[i][
                          riwayatPendidikan.fakultas.parentName
                        ][riwayatPendidikan.fakultas.childName]
                    ) || null
                  }
                  onChange={(_event, newOption) => {
                    formik.setFieldValue(
                      `riwayatPendidikan.${i}.${riwayatPendidikan.fakultas.parentName}.${riwayatPendidikan.fakultas.childName}`,
                      newOption?.facultyId || null
                    );
                    formik.setFieldValue(
                      `riwayatPendidikan.${i}.${riwayatPendidikan.jurusan.parentName}.${riwayatPendidikan.jurusan.childName}`,
                      null
                    );
                  }}
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPendidikan.jurusan.label}
                </Typography>
                <Autocomplete
                  options={dataJurusan.filter(
                    (item) =>
                      item.faculty.facultyId ===
                      formik.values.riwayatPendidikan[i][
                        riwayatPendidikan.fakultas.parentName
                      ][riwayatPendidikan.fakultas.childName]
                  )}
                  disabled={
                    !formik.values.riwayatPendidikan[i][
                      riwayatPendidikan.fakultas.parentName
                    ][riwayatPendidikan.fakultas.childName]
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      fullWidth
                      size="small"
                      name={`riwayatPendidikan.${i}.${riwayatPendidikan.jurusan.parentName}.${riwayatPendidikan.jurusan.childName}`}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.riwayatPendidikan &&
                        formik.touched.riwayatPendidikan &&
                        formik.touched?.riwayatPendidikan[i]?.[
                          riwayatPendidikan.jurusan.parentName
                        ]?.[riwayatPendidikan.jurusan.childName] &&
                        formik.errors?.riwayatPendidikan[i]?.[
                          riwayatPendidikan.jurusan.parentName
                        ]?.[riwayatPendidikan.jurusan.childName] &&
                        true
                      }
                      helperText={
                        formik.errors.riwayatPendidikan &&
                        formik.touched.riwayatPendidikan &&
                        formik.errors.riwayatPendidikan[i]?.[
                          riwayatPendidikan.jurusan.parentName
                        ]?.[riwayatPendidikan.jurusan.childName] &&
                        formik.touched.riwayatPendidikan[i]?.[
                          riwayatPendidikan.jurusan.parentName
                        ]?.[riwayatPendidikan.jurusan.childName]
                          ? formik.errors.riwayatPendidikan[i]?.[
                              riwayatPendidikan.jurusan.parentName
                            ]?.[riwayatPendidikan.jurusan.childName]
                          : ""
                      }
                      label="Pilih Jurusan"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                  getOptionLabel={(option) => option.majorName}
                  value={
                    dataJurusan?.find(
                      (item) =>
                        item.majorId ===
                        formik.values.riwayatPendidikan[i][
                          riwayatPendidikan.jurusan.parentName
                        ][riwayatPendidikan.jurusan.childName]
                    ) || null
                  }
                  onChange={(_event, newOption) => {
                    formik.setFieldValue(
                      `riwayatPendidikan.${i}.${riwayatPendidikan.jurusan.parentName}.${riwayatPendidikan.jurusan.childName}`,
                      newOption?.majorId || null
                    );
                  }}
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPendidikan.tahunMasuk.label}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    hiddenLabel
                    fullWidth
                    disableFuture
                    size="small"
                    variant="dialog"
                    inputVariant="filled"
                    format="yyyy"
                    placeholder="Pilih Tahun Masuk"
                    views={["year"]}
                    keyboardIcon={<ArrowDropDown />}
                    InputProps={{
                      style: { padding: 0 },
                      disableUnderline: true,
                      readOnly: true,
                    }}
                    onChange={(val) => {
                      formik.setFieldValue(
                        `riwayatPendidikan.${i}.${riwayatPendidikan.semester.name}`,
                        ""
                      );
                      formik.setFieldValue(
                        `riwayatPendidikan.${i}.${riwayatPendidikan.tanggalIjazah.name}`,
                        null
                      );
                      formik.setFieldValue(
                        `riwayatPendidikan.${i}.${riwayatPendidikan.tahunLulus.name}`,
                        null
                      );
                      formik.setFieldValue(
                        `riwayatPendidikan.${i}.${riwayatPendidikan.tahunMasuk.name}`,
                        parseInt(moment(val).format("YYYY"))
                      );
                    }}
                    name={`riwayatPendidikan.${i}.${riwayatPendidikan.tahunMasuk.name}`}
                    value={
                      formik.values.riwayatPendidikan[i][
                        riwayatPendidikan.tahunMasuk.name
                      ] &&
                      formik.values.riwayatPendidikan[i][
                        riwayatPendidikan.tahunMasuk.name
                      ].toString()
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.riwayatPendidikan &&
                      formik.touched.riwayatPendidikan &&
                      formik.touched?.riwayatPendidikan[i]?.[
                        riwayatPendidikan.tahunMasuk.name
                      ] &&
                      formik.errors?.riwayatPendidikan[i]?.[
                        riwayatPendidikan.tahunMasuk.name
                      ] &&
                      true
                    }
                    helperText={
                      formik.errors.riwayatPendidikan &&
                      formik.touched.riwayatPendidikan &&
                      formik.errors.riwayatPendidikan[i]?.[
                        riwayatPendidikan.tahunMasuk.name
                      ] &&
                      formik.touched.riwayatPendidikan[i]?.[
                        riwayatPendidikan.tahunMasuk.name
                      ]
                        ? formik.errors.riwayatPendidikan[i]?.[
                            riwayatPendidikan.tahunMasuk.name
                          ]
                        : ""
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPendidikan.tahunLulus.label}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    hiddenLabel
                    fullWidth
                    disabled={
                      formik.values.riwayatPendidikan[i][
                        riwayatPendidikan.tahunMasuk.name
                      ] === null
                    }
                    disableFuture
                    minDate={
                      formik.values.riwayatPendidikan[i][
                        riwayatPendidikan.tahunMasuk.name
                      ] !== null &&
                      formik.values.riwayatPendidikan[i][
                        riwayatPendidikan.tahunMasuk.name
                      ].toString()
                    }
                    size="small"
                    variant="dialog"
                    inputVariant="filled"
                    format="yyyy"
                    placeholder="Pilih Tahun Lulus"
                    views={["year"]}
                    keyboardIcon={<ArrowDropDown />}
                    InputProps={{
                      style: { padding: 0 },
                      disableUnderline: true,
                      readOnly: true,
                    }}
                    onChange={(val) => {
                      formik.setFieldValue(
                        `riwayatPendidikan.${i}.${riwayatPendidikan.semester.name}`,
                        ""
                      );
                      formik.setFieldValue(
                        `riwayatPendidikan.${i}.${riwayatPendidikan.tanggalIjazah.name}`,
                        null
                      );
                      formik.setFieldValue(
                        `riwayatPendidikan.${i}.${riwayatPendidikan.tahunLulus.name}`,
                        parseInt(moment(val).format("YYYY"))
                      );
                    }}
                    name={`riwayatPendidikan.${i}.${riwayatPendidikan.tahunLulus.name}`}
                    value={
                      formik.values.riwayatPendidikan[i][
                        riwayatPendidikan.tahunLulus.name
                      ] &&
                      formik.values.riwayatPendidikan[i][
                        riwayatPendidikan.tahunLulus.name
                      ].toString()
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.riwayatPendidikan &&
                      formik.touched.riwayatPendidikan &&
                      formik.touched?.riwayatPendidikan[i]?.[
                        riwayatPendidikan.tahunLulus.name
                      ] &&
                      formik.errors?.riwayatPendidikan[i]?.[
                        riwayatPendidikan.tahunLulus.name
                      ] &&
                      true
                    }
                    helperText={
                      formik.errors.riwayatPendidikan &&
                      formik.touched.riwayatPendidikan &&
                      formik.errors.riwayatPendidikan[i]?.[
                        riwayatPendidikan.tahunLulus.name
                      ] &&
                      formik.touched.riwayatPendidikan[i]?.[
                        riwayatPendidikan.tahunLulus.name
                      ]
                        ? formik.errors.riwayatPendidikan[i]?.[
                            riwayatPendidikan.tahunLulus.name
                          ]
                        : ""
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPendidikan.semester.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  select
                  size="small"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  disabled={
                    formik.values.riwayatPendidikan[i][
                      riwayatPendidikan.tahunLulus.name
                    ] === null
                  }
                  name={`riwayatPendidikan.${i}.${riwayatPendidikan.semester.name}`}
                  value={
                    formik.values.riwayatPendidikan[i][
                      riwayatPendidikan.semester.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatPendidikan &&
                    formik.touched.riwayatPendidikan &&
                    formik.touched?.riwayatPendidikan[i]?.[
                      riwayatPendidikan.semester.name
                    ] &&
                    formik.errors?.riwayatPendidikan[i]?.[
                      riwayatPendidikan.semester.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatPendidikan &&
                    formik.touched.riwayatPendidikan &&
                    formik.errors.riwayatPendidikan[i]?.[
                      riwayatPendidikan.semester.name
                    ] &&
                    formik.touched.riwayatPendidikan[i]?.[
                      riwayatPendidikan.semester.name
                    ]
                      ? formik.errors.riwayatPendidikan[i]?.[
                          riwayatPendidikan.semester.name
                        ]
                      : ""
                  }
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem disabled value="">
                    Pilih Semester
                  </MenuItem>
                  {countSemester(i).map((item) => (
                    <MenuItem key={`semester-${item}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPendidikan.ipk.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Contoh : 3.29"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={`riwayatPendidikan.${i}.${riwayatPendidikan.ipk.name}`}
                  value={
                    formik.values.riwayatPendidikan[i][
                      riwayatPendidikan.ipk.name
                    ]
                  }
                  onChange={(e) =>
                    ipkValidation.test(e.target.value) && formik.handleChange(e)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatPendidikan &&
                    formik.touched.riwayatPendidikan &&
                    formik.touched?.riwayatPendidikan[i]?.[
                      riwayatPendidikan.ipk.name
                    ] &&
                    formik.errors?.riwayatPendidikan[i]?.[
                      riwayatPendidikan.ipk.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatPendidikan &&
                    formik.touched.riwayatPendidikan &&
                    formik.errors.riwayatPendidikan[i]?.[
                      riwayatPendidikan.ipk.name
                    ] &&
                    formik.touched.riwayatPendidikan[i]?.[
                      riwayatPendidikan.ipk.name
                    ]
                      ? formik.errors.riwayatPendidikan[i]?.[
                          riwayatPendidikan.ipk.name
                        ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPendidikan.nomorIjazah.label}
                </Typography>
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="filled"
                  placeholder="Masukkan No Ijazah/SKL"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: riwayatPendidikan.nomorIjazah.maximum,
                  }}
                  name={`riwayatPendidikan.${i}.${riwayatPendidikan.nomorIjazah.name}`}
                  value={
                    formik.values.riwayatPendidikan[i][
                      riwayatPendidikan.nomorIjazah.name
                    ]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.riwayatPendidikan &&
                    formik.touched.riwayatPendidikan &&
                    formik.touched?.riwayatPendidikan[i]?.[
                      riwayatPendidikan.nomorIjazah.name
                    ] &&
                    formik.errors?.riwayatPendidikan[i]?.[
                      riwayatPendidikan.nomorIjazah.name
                    ] &&
                    true
                  }
                  helperText={
                    formik.errors.riwayatPendidikan &&
                    formik.touched.riwayatPendidikan &&
                    formik.errors.riwayatPendidikan[i]?.[
                      riwayatPendidikan.nomorIjazah.name
                    ] &&
                    formik.touched.riwayatPendidikan[i]?.[
                      riwayatPendidikan.nomorIjazah.name
                    ]
                      ? formik.errors.riwayatPendidikan[i]?.[
                          riwayatPendidikan.nomorIjazah.name
                        ]
                      : ""
                  }
                />
              </Grid>
              <Grid className={classes.grid} item xs={6}>
                <Typography className={classes.label}>
                  {riwayatPendidikan.tanggalIjazah.label}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    hiddenLabel
                    fullWidth
                    disabled={
                      formik.values.riwayatPendidikan[i][
                        riwayatPendidikan.tahunLulus.name
                      ] === null
                    }
                    minDate={
                      formik.values.riwayatPendidikan[i][
                        riwayatPendidikan.tahunLulus.name
                      ] !== null &&
                      formik.values.riwayatPendidikan[i][
                        riwayatPendidikan.tahunLulus.name
                      ].toString()
                    }
                    size="small"
                    variant="dialog"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    placeholder="mm/dd/yyyy"
                    keyboardIcon={<Event className={classes.icon} />}
                    InputProps={{
                      style: { padding: 0 },
                      disableUnderline: true,
                    }}
                    onChange={(val) =>
                      formik.setFieldValue(
                        `riwayatPendidikan.${i}.${riwayatPendidikan.tanggalIjazah.name}`,
                        val
                      )
                    }
                    name={`riwayatPendidikan.${i}.${riwayatPendidikan.tanggalIjazah.name}`}
                    value={
                      formik.values.riwayatPendidikan[i][
                        riwayatPendidikan.tanggalIjazah.name
                      ]
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.riwayatPendidikan &&
                      formik.touched.riwayatPendidikan &&
                      formik.touched?.riwayatPendidikan[i]?.[
                        riwayatPendidikan.tanggalIjazah.name
                      ] &&
                      formik.errors?.riwayatPendidikan[i]?.[
                        riwayatPendidikan.tanggalIjazah.name
                      ] &&
                      true
                    }
                    helperText={
                      formik.errors.riwayatPendidikan &&
                      formik.touched.riwayatPendidikan &&
                      formik.errors.riwayatPendidikan[i]?.[
                        riwayatPendidikan.tanggalIjazah.name
                      ] &&
                      formik.touched.riwayatPendidikan[i]?.[
                        riwayatPendidikan.tanggalIjazah.name
                      ]
                        ? formik.errors.riwayatPendidikan[i]?.[
                            riwayatPendidikan.tanggalIjazah.name
                          ]
                        : ""
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
      <Button
        className={classes.buttonAdd}
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={() => {
          onAddForm();
        }}
      >
        + Tambah {title}
      </Button>
    </Box>
  );
};

export default Form;
