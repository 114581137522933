import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Pagination from "../../../GlobalComponent/Pagination";
import { MoreVert } from "@material-ui/icons";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";
import StatusChip from "../../../GlobalComponent/StatusChip";
import { config } from "../../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5vw 15vw",
    textAlign: "center",
    
  },
  th: {
    fontSize: "0.9vw",
    fontWeight: "bold",
    paddingTop: "3vh",
    paddingBottom: "3vh",
    textAlign: "center",
    [theme.breakpoints.down(750)]: {
      fontSize:"0.6rem"

    },
  },
  td: {
    fontSize: "0.9vw",
    maxWidth: "9.4vw",
    [theme.breakpoints.down(750)]: {
      fontSize:"0.6rem"
    },
  },
  tdStatus: {
    minWidth: "7vw",
  },
  lamarTitle: {
    color: theme.palette.primary.main,
    fontSize: "1.5vw",
    fontWeight: "bold",
    marginBottom: "3.8vh",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
    }
  },
  iconAction: { color: "black" },
  tableCard: {
    borderRadius: 8,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
    overflowX: "auto"
  },
  widthMenu: {
    width: "100%",
  }

}));

const LamaranPekerjaan = (props) => {
  const classes = useStyles();
  const initialParams = {
    applicantId: localStorage.getItem("applicantId"),
    size: 10,
    page: 0,
    sort: "",
  };
  const [dataLamaranKerja, setDataLamaranKerja] = useState({ Data: [] });
  const [params, setParams] = useState(initialParams);
  const [index, setIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const getLamaranKerja = useCallback(() => {
    setLoading(true);
    API(`lamaranKerja.getLamaranKerja`, {
      params,
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200 || res.data?.Message === "Success") {
          setDataLamaranKerja(res.data);
        }
      })
      .catch((res) => {
        setLoading(false);
        Toast.fire({
          icon: "warning",
          title:
            res.response?.data?.Message || "Gagal mendapat data lamaran kerja!",
        });
      });
  }, [params]);

  const downloadData = (id, type) => {
    API(`lamaranKerja.download${type}`, {
      params: { testResultsId: id },
    })
      .then((res) => {
        if (res.status === 200 && res.data?.Url) {
          window.open(`${config.BASE_URL}${res.data?.Url}`, "_blank");
          Toast.fire({
            icon: "success",
            title: res.data?.Message || `${type} berhasil di download!`,
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || `Gagal mendapat ${type}!`,
        });
      });
  };

  const handlePagination = (val) => {
    setParams({
      ...params,
      size: val.size,
      page: val.page - 1,
    });
  };

  useEffect(() => {
    getLamaranKerja();
  }, [getLamaranKerja]);

  return (
    <Box className={classes.root}>
      <Typography className={classes.lamarTitle} align="left">
        Lamaran Pekerjaan
      </Typography>
      <TableContainer className={classes.tableCard} component={Card}>
        {loading ? (
          <CircularProgress style={{ margin: "5vw" }} />
        ) : (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ paddingLeft: "2vw" }}
                    className={classes.th}
                  >
                    Posisi Kerja
                  </TableCell>
                  <TableCell className={classes.th}>
                    Kategori Lowongan
                  </TableCell>
                  <TableCell className={classes.th}>Lokasi</TableCell>
                  <TableCell className={classes.th}>Waktu Seleksi</TableCell>
                  <TableCell className={classes.th}>Step</TableCell>
                  <TableCell className={classes.th}>Status</TableCell>
                  <TableCell style={{ paddingRight: "2vw" }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {dataLamaranKerja?.Data?.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell
                      style={{ paddingLeft: "2vw" }}
                      className={classes.td}
                    >
                      {row.positionName}
                    </TableCell>
                    <TableCell className={classes.td}>
                      {row.categoryName}
                    </TableCell>
                    <TableCell className={classes.td}>
                      {row.locationName}
                    </TableCell>
                    <TableCell className={classes.td}>
                      {row.selectionDateTime}
                    </TableCell>
                    <TableCell className={classes.td}>
                      {row.stepSelectionName}
                    </TableCell>
                    <TableCell className={classes.tdStatus}>
                      <StatusChip value={row.approvalStatus} />
                    </TableCell>
                    <TableCell
                      className={classes.td}
                      style={{ paddingRight: "2vw" }}
                    >
                      <IconButton
                        onClick={(e) => {
                          setAnchorEl(e.currentTarget);
                          setIndex(i);
                        }}
                        className={classes.iconAction}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                      // ini tes
                      className={classes.widthMenu}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                        PaperProps={{
                          style: {
                            borderRadius: 0,
                            
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          },
                        }}
                        elevation={0}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem
                          disabled={
                            dataLamaranKerja.Data[index]?.selectionDateTime ===
                              "-" ||
                            dataLamaranKerja.Data[index]?.locationName === "-"
                          }
                          onClick={() => {
                            setAnchorEl(null);
                            downloadData(
                              dataLamaranKerja.Data[index].testResultsId,
                              "Kartu"
                            );
                          }}
                        >
                          Download Kartu
                        </MenuItem>
                        <MenuItem
                          disabled={
                            dataLamaranKerja.Data[index]?.selectionDateTime ===
                              "-" ||
                            dataLamaranKerja.Data[index]?.locationName === "-"
                          }
                          onClick={() => {
                            setAnchorEl(null);
                            downloadData(
                              dataLamaranKerja.Data[index].testResultsId,
                              "Dokumen"
                            );
                          }}
                        >
                          Download Dokumen
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setAnchorEl(null);
                            history.push(
                              `/detail-hasil-recruitment/${dataLamaranKerja.Data[index].testResultsId}`
                            );
                          }}
                        >
                          Detail hasil Rekrutmen
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {dataLamaranKerja?.Data?.length === 0 && (
              <Typography align="center" style={{ margin: "2vw" }}>
                Data not found
              </Typography>
            )}
            <Pagination
              totalItem={
                dataLamaranKerja?.TotalData ? dataLamaranKerja?.TotalData : 0
              }
              pages={params.page}
              size={params.size}
              onChange={(val) => handlePagination(val)}
            />
          </>
        )}
      </TableContainer>
    </Box>
  );
};

export default LamaranPekerjaan;
