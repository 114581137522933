const GlobalFunction = {
  // moneyDotFormat(val) {
  //   return val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
  // },

  phoneFormat(val) {
    var temp = val.toString();
    if (val.startsWith("22")) {
      temp = `0${val.slice(0, 2)}-${val.slice(2)}`;
    }

    return temp;
  },

  getFileName(val) {
    if (val) {
      var tempArray = val.toString().split("/");

      return tempArray[tempArray.length - 1] || "";
    }
  },

  numberWithDot(val) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  },

  clearDotNumber(val) {
    return val.toString().split(".").join("");
  },

  base64MimeType(b64) {
    var signatures = {
      JVBERi0: "application/pdf",
      UklGRg: "image/webp",
      iVBORw0KGgo: "image/png",
      "/9j/": "image/jpg",
    };

    var result = null;

    if (typeof b64 !== "string") {
      return result;
    }

    for (var s in signatures) {
      if (b64.indexOf(s) === 0) {
        result = `data:${signatures[s]};base64,${b64}`;
      }
    }

    return result;
  },

  handleImageError(ev) {
    ev.target.src = `${process.env.PUBLIC_URL}/images/image-error.jpg`;
  },
};

export default GlobalFunction;
