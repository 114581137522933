import axios from "axios";
import { config } from "../config";
import { Toast } from "../routes/Component/Notification";
import endpoint from "./endpoints";

const apiClient = axios.create({
  baseURL: `${config.BASE_URL}/rest/talent`,
});

apiClient.interceptors.request.use((request) => {
  const idToken = localStorage.getItem("token");
  const tokenType = localStorage.getItem("tokenType");
  if (idToken) {
    request.headers.Authorization = tokenType + `${idToken}`;
  }
  return request;
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      Toast.fire({
        icon: "info",
        title: "Sesi telah habis, silahkan login kembali!",
      });
      window.location = "/login";
    }
    return Promise.reject(error);
  }
);

const urlBuilder = ({ query, urlApi }) => {
  if (query)
    return Object.keys(query).reduce(
      (url, key) => url.replace(`:${key}`, query[key]),
      urlApi
    );
  return urlApi;
};

export const API = (...args) => {
  const [urlMethod, params] = args;
  const [name, method] = urlMethod.split(".");

  const context = { ...endpoint[name][method], ...params };
  context.url = urlBuilder({ ...params, urlApi: endpoint[name][method].url });

  // console.log("API hit : "+context.url);

  return apiClient(context);
};

export default apiClient;
