import React from "react";
import {
  makeStyles,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  TextField, Button,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import moment from "moment";
import { config } from "../../../config";
import ModalImage from "react-modal-image";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.1vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
    }
  },
  content: {
    padding: "3vh 2.5vw",
    [theme.breakpoints.down(550)]: {
      padding: "3vh",
    },
  },
  accordion: {
    borderRadius: 8,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
  },
  subtitleLabel: {
    color: "#005A7D",
    marginBottom: 8,
    // fontSize: "0.625vw",
    fontSize: "0.825vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.7rem",
    }
  },
  contentLabel: {
    color: "#011627",
    fontWeight: "bold",
    // fontSize: "0.7vw",
    fontSize: "0.9vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "0.7rem",
    }
  },
  image: {
    width: "100%",
  },
  buttonTabs2: {
    borderRadius: 8,
    backgroundColor: "#FFF1F4",
    padding: "1.2vh 1vw",
    border: "1px solid #E21A43",
    fontSize: "0.7vw",
    fontWeight: 600,
    height: "4.8vh",
  },

  sizeFormHide1: {
    [theme.breakpoints.down(550)]: {
      display: "none",
    },

  },
  sizeFormHide2: {
    [theme.breakpoints.up(550)]: {
      display: "none",
    },
  },
}));

const IdentitasDiri = ({ data }) => {
  const classes = useStyles();
      const onButtonClick = () => {
        const pdfUrl = "Sample.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "document.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        id="detail-identitas-diri"
        className={classes.content}
      >
        <Typography className={classes.title}>Identitas Diri</Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={classes.content}>
        <Grid container spacing={4} className={classes.sizeFormHide1}>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Nama Lengkap
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantName || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Jenis Kelamin
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantGender === 0
                ? "Laki-Laki"
                : data?.applicantGender === 1
                ? "Perempuan"
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              NIK
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantNik || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              File KTP
            </Typography>
            {data?.applicantUploadKtpPath ? (
              <ModalImage
                small={`${config.BASE_URL}${data?.applicantUploadKtpPath}`}
                large={`${config.BASE_URL}${data?.applicantUploadKtpPath}`}
                alt="ktp-image"
              />
            ) : (
              <Typography variant="subtitle1" className={classes.contentLabel}>
                Tidak terdapat file KTP
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Tempat Lahir
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantBirthPlace || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Tanggal Lahir
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantBirthDate
                ? moment(data.applicantBirthDate).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Email
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantEmail || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Nomor Telepon
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantPhoneNumber
                ? `${data.applicantPhoneNumber}`
                : "-"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} className={classes.sizeFormHide2}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Nama Lengkap
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantName || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Jenis Kelamin
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantGender === 0
                ? "Laki-Laki"
                : data?.applicantGender === 1
                ? "Perempuan"
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              NIK
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantNik || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Tempat Lahir
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantBirthPlace || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Tanggal Lahir
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantBirthDate
                ? moment(data.applicantBirthDate).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Grid>
          
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Nomor Telepon
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantPhoneNumber
                ? `${data.applicantPhoneNumber}`
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Email
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantEmail || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              File KTP
            </Typography>
            {data?.applicantUploadKtpPath ? (
              <ModalImage
                small={`${config.BASE_URL}${data?.applicantUploadKtpPath}`}
                large={`${config.BASE_URL}${data?.applicantUploadKtpPath}`}
                alt="ktp-image"
              />
            ) : (
              <Typography variant="subtitle1" className={classes.contentLabel}>
                Tidak terdapat file KTP
              </Typography>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider />
      <AccordionDetails className={classes.content}>
        <Grid container spacing={5} className={classes.sizeFormHide1}>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Domisili Sesuai KTP
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantDomicileKtp || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Alamat Sesuai KTP
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantAddressKtp || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Domisili Saat Ini
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantDomicileCurrent || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Alamat Saat Ini
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantAddressCurrent || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Nomor KK
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantNumberKk || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              NPWP
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantNpwp || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Agama
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantReligion || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Kewarganegaraan
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantCitizen || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Suku
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantEthnic || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Status Perkawinan
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantMaritalStatus || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Tinggi Badan
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantHeight ? `${data.applicantHeight} cm` : "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Berat Badan
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantWeight ? `${data.applicantWeight} kg` : "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Golongan Darah
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantBloodType || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Warna Kulit
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantSkinColor || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Bentuk Muka
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantFaceShape || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Jenis Rambut
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantHairType || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Bisa Berbahasa Inggris
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantSkinColor == null ? "-" : (data.isEnglish ? "Bisa" : "Tidak Bisa")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Bisa Membaca Al-Qur'an
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantSkinColor == null ? "-" : (data?.isReadQuran? "Bisa": "Tidak Bisa")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={5} className={classes.sizeFormHide2}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Domisili Sesuai KTP
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantDomicileKtp || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Alamat Sesuai KTP
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantAddressKtp || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Domisili Saat Ini
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantDomicileCurrent || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Alamat Saat Ini
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantAddressCurrent || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Nomor KK
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantNumberKk || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              NPWP
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantNpwp || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Agama
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantReligion || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Kewarganegaraan
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantCitizen || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Suku
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantEthnic || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Status Perkawinan
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantMaritalStatus || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Tinggi Badan
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantHeight ? `${data.applicantHeight} cm` : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Berat Badan
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantWeight ? `${data.applicantWeight} kg` : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Golongan Darah
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantBloodType || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Warna Kulit
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantSkinColor || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Bentuk Muka
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantFaceShape || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Jenis Rambut
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantHairType || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Bisa Berbahasa Inggris
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantSkinColor == null ? "-" : (data.isEnglish ? "Bisa" : "Tidak Bisa")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Bisa Membaca Al-Qur'an
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantSkinColor == null ? "-" : (data?.isReadQuran? "Bisa": "Tidak Bisa")}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider />
      <AccordionDetails className={classes.content}>
        <Grid container spacing={5} className={classes.sizeFormHide1}>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Akun Instagram
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantIg || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Akun Twitter
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantTwitter || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Akun Facebook
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantFb || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Akun LinkedIn
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantLinkedin || "-"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={5} className={classes.sizeFormHide2}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Akun Instagram
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantIg || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Akun Twitter
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantTwitter || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Akun Facebook
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantFb || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Akun LinkedIn
            </Typography>
            <Typography variant="subtitle1" className={classes.contentLabel}>
              {data?.applicantLinkedin || "-"}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider />
      <AccordionDetails className={classes.content}>
        <Grid container spacing={5} className={classes.sizeFormHide1}>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Pas Foto
            </Typography>
            {data?.applicantPassPhotoPath ? (
              <ModalImage
                small={`${config.BASE_URL}${data?.applicantPassPhotoPath}`}
                large={`${config.BASE_URL}${data?.applicantPassPhotoPath}`}
                alt="passfoto-image"
              />
            ) : (
              <Typography variant="subtitle1" className={classes.contentLabel}>
                Tidak terdapat pas foto
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Foto Seluruh Badan
            </Typography>
            {data?.applicantFullBodyPath ? (
              <ModalImage
                small={`${config.BASE_URL}${data?.applicantFullBodyPath}`}
                large={`${config.BASE_URL}${data?.applicantFullBodyPath}`}
                alt="fullbody-image"
              />
            ) : (
              <Typography variant="subtitle1" className={classes.contentLabel}>
                Tidak terdapat foto seluruh badan
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Foto Selfie
            </Typography>
            {data?.applicantSelfiePath ? (
              <ModalImage
                small={`${config.BASE_URL}${data?.applicantSelfiePath}`}
                large={`${config.BASE_URL}${data?.applicantSelfiePath}`}
                alt="selfie-image"
              />
            ) : (
              <Typography variant="subtitle1" className={classes.contentLabel}>
                Tidak terdapat foto selfie
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Dokumen CV
            </Typography>
            {data?.applicantCvPath ? (
                <Button
                    className={classes.buttonTabs2}
                    variant="outlined"
                    disabled={
                        data.applicantCvPath === null ||
                        data.applicantCvPath === ""
                    }
                    
                    onClick={() => {
                      window.open(
                          `${config.BASE_URL}${data.applicantCvPath}`,
                          "_blank",
                      );
                    }}
                >
                  Download Dokumen
                </Button>
            ) : (
                <Typography variant="subtitle1" className={classes.contentLabel}>
                  Tidak terdapat dokumen CV
                </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={5} className={classes.sizeFormHide2}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Pas Foto
            </Typography>
            {data?.applicantPassPhotoPath ? (
              <ModalImage
                small={`${config.BASE_URL}${data?.applicantPassPhotoPath}`}
                large={`${config.BASE_URL}${data?.applicantPassPhotoPath}`}
                alt="passfoto-image"
              />
            ) : (
              <Typography variant="subtitle1" className={classes.contentLabel}>
                Tidak terdapat pas foto
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Foto Seluruh Badan
            </Typography>
            {data?.applicantFullBodyPath ? (
              <ModalImage
                small={`${config.BASE_URL}${data?.applicantFullBodyPath}`}
                large={`${config.BASE_URL}${data?.applicantFullBodyPath}`}
                alt="fullbody-image"
              />
            ) : (
              <Typography variant="subtitle1" className={classes.contentLabel}>
                Tidak terdapat foto seluruh badan
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Foto Selfie
            </Typography>
            {data?.applicantSelfiePath ? (
              <ModalImage
                small={`${config.BASE_URL}${data?.applicantSelfiePath}`}
                large={`${config.BASE_URL}${data?.applicantSelfiePath}`}
                alt="selfie-image"
              />
            ) : (
              <Typography variant="subtitle1" className={classes.contentLabel}>
                Tidak terdapat foto selfie
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.subtitleLabel}>
              Dokumen CV
            </Typography>
            {data?.applicantCvPath ? (
                <Button
                    className={classes.buttonTabs2}
                    variant="outlined"
                    disabled={
                        data.applicantCvPath === null ||
                        data.applicantCvPath === ""
                    }
                    onClick={() => {
                      window.open(
                          `${config.BASE_URL}${data.applicantCvPath}`,
                          "_blank"
                      );
                    }}
                >
                  Download Dokumen
                </Button>
            ) : (
                <Typography variant="subtitle1" className={classes.contentLabel}>
                  Tidak terdapat dokumen CV
                </Typography>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default IdentitasDiri;
