import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  Fab,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";
import moment from "moment";
import { config } from "../../../config";
import { ArrowLeft, ArrowRight, SystemUpdate } from "@material-ui/icons";
import GlobalFunction from "../../../GlobalFunction";

const useStyles = makeStyles((theme) => ({
  eventRecruitment: {
    textAlign: "center",
    padding: "7vw 0",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "2vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "3vw",
    }
  },
  desc: {
    color: theme.palette.primary.main,
    fontSize: "1vw",
    marginTop: "1.5vw",
    [theme.breakpoints.down(750)]: {
      fontSize: "2.3vw",
    }
  },
  eventPadding: {
    display: "flex",
    justifyContent: "center",
    marginTop: "3vw",
    padding: "0 14vw",
  },
  card: {
    background: "#fff",
    display: "flex",
    padding: "1vw 2vw",
    height: "100%",
    borderRadius: 8,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
  },
  left: {
    width: "35%",
    paddingRight: "5%",
  },
  logo: {
    width: "100%",
  },
  right: {
    width: "60%",
    textAlign: "left",
  },
  cardTitle: {
    color: theme.palette.primary.main,
    fontSize: "1.25vw",
    fontWeight: "bold",
  },
  cardEvent: {
    fontSize: "0.8vw",
    color: "#808A93",
  },
  cardContent: {
    fontSize: "0.8vw",
    textAlign: "justify",
    margin: "2vw 0",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  buttonDetail: {
    fontSize: "0.9vw",
    padding: "1vw 1.5vw",
  },
  divider: {
    width: "2.5vw",
    margin: "1vw auto",
    background: theme.palette.secondary.main,
    height: "0.3vw",
  },
  arrowPadding: {
    width: "4.5vw",
    margin: "auto",
  },
  arrow: {
    background: theme.palette.background.paper,
    width: "3vw",
    height: "3vw",
    minHeight: 0,
    cursor: "pointer",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    "&&:active": {
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
  },
}));

const EventRecruitment = () => {
  const classes = useStyles();
  const history = useHistory();
  const isLogin = localStorage.getItem("isLogin");
  const [dataEventSection, setDataEventSection] = useState({});
  const [dataAllEventRecruitment, setDataAllEventRecruitment] = useState([]);
  const [message, setMessage] = useState("Success");

  useEffect(() => {
    if (isLogin) {
      getEventSection("secured");
      getAllEventRecruitment("secured");
    } else {
      getEventSection("open");
      getAllEventRecruitment("open");
    }
  }, [isLogin]);

  const getEventSection = (type) => {
    const url =
      type === "open"
        ? "home.getEventSectionOpen"
        : "home.getEventSectionSecured";

    API(`${url}`)
    .then((res) => {
        setMessage(res.data?.Message);
        if (res.status === 200 && res.data?.Message === "Success" || res.data?.Message === "Not Found") {
          setDataEventSection(res.data?.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data Event!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data Event!",
        });
      });
  };

  const getAllEventRecruitment = (type) => {
    const url =
      type === "open"
        ? "home.getAllEventRecruitmentOpen"
        : "home.getAllEventRecruitmentSecured";

    API(`${url}`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success"  || res.data?.Message === "Not Found") {
          setDataAllEventRecruitment(res.data?.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data Event Recruitment!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data Event Recruitment!",
        });
      });
  };

  const [pageCard, setPageCard] = useState(0);

  const handleClickPageCard = (type) => {
    if (type === "next") {
      setPageCard(pageCard + 1);
    } else {
      setPageCard(pageCard - 1);
    }
  };

  return (
    <div>
      {message.toLowerCase() !== "not found" && (
        <Box className={classes.eventRecruitment}>
          <Typography className={classes.title}>
            {dataEventSection.erSectionTitle || "Event Recruitment"}
          </Typography>
          <Typography className={classes.desc}>
            {dataEventSection.erSectionSubtitle ||
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
          </Typography>
          <Divider className={classes.divider} />
          {dataAllEventRecruitment.length > 0 && (
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.eventPadding}
            >
              <Grid item>
                <Box className={classes.arrowPadding}>
                  <Fab
                    className={classes.arrow}
                    onClick={() => handleClickPageCard("previous")}
                    disabled={pageCard === 0}
                  >
                    <ArrowLeft className={classes.icon} />
                  </Fab>
                </Box>
              </Grid>
              {dataAllEventRecruitment.map((item, i) => (
                <div key={i}>
                  {i >= pageCard && i <= pageCard + 1 && (
                    <Grid item style={{ padding: "2vw 1vw", width: "30vw" }}>
                      <Box className={classes.card}>
                        <Box className={classes.left}>
                          <img
                            className={classes.logo}
                            alt="logo"
                            src={
                              item.eventRecruitmentImg
                                ? `${config.BASE_URL}${item.eventRecruitmentImg}`
                                : `${process.env.PUBLIC_URL}/images/logo.png`
                            }
                            onError={GlobalFunction.handleImageError}
                          />
                        </Box>
                        <Box className={classes.right}>
                          <Typography className={classes.cardEvent}>
                            {`${item.eventRecruitmentLocation}, ${moment(
                              item.eventRecruitmentDate
                            ).format("DD MMMM YYYY")}`}
                          </Typography>
                          <Typography className={classes.cardTitle}>
                            {item.eventRecruitmentName}
                          </Typography>
                          <Typography className={classes.cardContent}>
                            {item.eventRecruitmentContent}
                          </Typography>
                          <Button
                            className={classes.buttonDetail}
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              history.push({
                                pathname: `/detail-event-recruitment/${i}`,
                                state: { item },
                              });
                            }}
                          >
                            Lihat Detail
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </div>
              ))}
              <Grid item>
                <Box className={classes.arrowPadding}>
                  <Fab
                    className={classes.arrow}
                    onClick={() => handleClickPageCard("next")}
                    disabled={
                      dataAllEventRecruitment.length - 1 <= pageCard + 1
                    }
                  >
                    <ArrowRight className={classes.icon} />
                  </Fab>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </div>
  );
};

export default EventRecruitment;
