import { Chip, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  chipBlue: {
    background: "#CCDFFF",
    color: "#003A99",
    fontSize: "0.625vw",
    [theme.breakpoints.down(750)]: {
      fontSize:"0.6rem"
    },
  },
  chipGreen: {
    background: "#CBE5CC",
    color: "#43A047",
    fontSize: "0.625vw",
    [theme.breakpoints.down(750)]: {
      fontSize:"0.6rem"
    },
  },
  chipRed: {
    background: "#FCCBC8",
    color: "#F44336",
    fontSize: "0.625vw",
    [theme.breakpoints.down(750)]: {
      fontSize:"0.6rem"
    },
  },
  chipOrange: {
    background: "#FDDCB9",
    color: "#CE6B00",
    fontSize: "0.625vw",
    [theme.breakpoints.down(750)]: {
      fontSize:"0.6rem"
    },
  },
  chipBlack: {
    background: "#D2D2D6",
    color: "#5A5B6A",
    fontSize: "0.625vw",
    [theme.breakpoints.down(750)]: {
      fontSize:"0.6rem"
    },
  },
}));

const StatusChip = ({ value }) => {
  const classes = useStyles();
  return (
    <>
      {value.toLowerCase() === "disarankan" ||
      value.toLowerCase() === "disarankan dengan pengembangan" ||
      value.toLowerCase() === "sedang diproses" ? (
        <Chip className={classes.chipGreen} label={value} variant="outlined" />
      ) : value.toLowerCase() === "potensial" ||
        value.toLowerCase() === "approved" ||
        value.toLowerCase() === "lolos" ? (
        <Chip className={classes.chipBlue} label={value} variant="outlined" />
      ) : value.toLowerCase() === "tidak disarankan" ||
        value.toLowerCase() === "rejected" ||
        value.toLowerCase() === "tidak lolos" ? (
        <Chip className={classes.chipRed} label={value} variant="outlined" />
      ) : value.toLowerCase() === "blacklist" ? (
        <Chip className={classes.chipBlack} label={value} variant="outlined" />
      ) : value.toLowerCase() === "skip" ? (
        <Chip className={classes.chipOrange} label={value} variant="outlined" />
      ) : (
        <Typography>{value}</Typography>
      )}
    </>
  );
};

export default StatusChip;
