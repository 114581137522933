import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Grid,
  Typography,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Divider,
  makeStyles,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import { API } from "../../../service/axios";
import { Toast } from "../../Component/Notification";
import formModel from "../../../GlobalComponent/FormModel/formModel";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const { gantiPassword } = formModel;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5vw 0",
    [theme.breakpoints.down(1025)]: {
      paddingTop: "10vh",
    },
    [theme.breakpoints.down(750)]: {
      paddingTop: "10vh",
    },
    [theme.breakpoints.down(600)]: {
      paddingTop: "4vh",
    },
    [theme.breakpoints.down(500)]: {
      paddingTop: "10vh",
    },
    [theme.breakpoints.down(378)]: {
      paddingTop: "0vh",
    },
    [theme.breakpoints.down(361)]: {
      paddingTop: "6vh",
    },
  },
  content: {
    minHeight: "calc(100% - 35.2vw)",
    paddingTop: "10vw",
    paddingBottom: "5vw",
    background: "#F3F9FE",
  },
  cardHeaderRoot: {
    padding: "2.5vw 0 0 0",
    justifyContent: "center",
    display: "grid",
    [theme.breakpoints.down(750)]: {
      padding: "5vw 0 2.5vw 0",
    },
    [theme.breakpoints.down(500)]: {
      padding: "8vw 0 2.5vw 0",
    }
  },
  cardContentRoot: {
    padding: "2.5vw",
    [theme.breakpoints.down(750)]: {
      padding: "0 6vw 6vw",
    }
  },
  cardActionsRoot: {
    padding: "2.5vw",
    justifyContent: "center",
  },
  labelSpacing: {
    marginBottom: 3,
  },
  buttonLogin: {
    background: "#E21A43",
    height: 50,
  },
  labelSubmit: {
    textTransform: "capitalize",
    color: "white",
  },
  labelCapitalize1: {
    fontSize: "0.8vw",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    [theme.breakpoints.down(750)]: {
      fontSize: "13px",
    }
  },
  labelCapitalize2: {
    fontSize: "0.8vw",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    cursor: "pointer",
    textDecoration: "underline",
    [theme.breakpoints.down(750)]: {
      fontSize: "13px",
    }
  },
  inputFilled: {
    background: "#F3F4F5 !important ",
  },
  forgotTitle: {
    color: theme.palette.primary.main,
    fontSize: "1.875vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "18px",
    }
  },
  label:{
      fontSize: "1rem",
      textAlign: "left",
      marginBottom: "0.4vw",
      paddingTop: "1rem",
  },
  containerForgot: {
    maxWidth: "400px",
    margin: "0 auto",
    padding: "20px",
    [theme.breakpoints.down(750)]: {
      maxWidth: "100%",
      padding: "0 10vh",
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      padding: "3vh 5vh",
    }
  },
}));

const GantiPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [visibility, setVisibility] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false
  });

  const [loading, setLoading] = useState(false);
  const isSecured = localStorage.getItem("userisSecured") === "true";

  const formik = useFormik({
    initialValues: {
      username: localStorage.getItem("userEmail") || '',
      oldPassword: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required('Kata Sandi masih kosong!'),
      newPassword: Yup.string()
        .min(8, 'Minimal 8 karakter!')
        .matches(/[A-Z]/, 'Harap menggunakan kombinasi huruf besar!')
        .matches(/[a-z]/, 'Harap menggunakan kombinasi huruf kecil!')
        .matches(/[0-9]/, 'Harap menggunakan kombinasi angka!')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Harap menggunakan kombinasi simbol!')
        .required('Kata Sandi masih kosong!'),
      konfirmasiPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Kata Sandi harus sama!')
        .required('Kata Sandi masih kosong!')
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      var dataGantiPassword = {
        ...values,
      };
    
      // Delete the konfirmasiPassword field
      delete dataGantiPassword.konfirmasiPassword;

      setSubmitting(true);
      API(`GantiPassword.submitPassword`, { data: dataGantiPassword })
      .then((res) => {
        setSubmitting(true);
        if (res.data.status === 200) {
          var $toast = Toast.fire({
            icon: "success",
            title:
              "Kata sandi berhasil diubah, mohon login kembali",
          });
          setTimeout(function(){
              $toast.close();
          }, 5000);
          localStorage.clear();
          history.push("/login");
        }else{
          setSubmitting(false);
          Toast.fire({
            icon: "warning",
            title: res.data.message || "Kata sandi gagal diubah!",
          });
        }
      })
      .catch((res) => {
        setSubmitting(false);
        Toast.fire({
          icon: "warning",
          title: res.data.message || "Kata sandi gagal diubah!",
        });
      });
    },
  });

  const handleToggleVisibility = (field) => {
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [field]: !prevVisibility[field]
    }));
  };

  return (
    <Box className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid className={classes.containerForgot}>
          <Card>
            <form onSubmit={formik.handleSubmit}>
              <CardHeader
                className={classes.cardHeaderRoot}
                title="Ganti Kata Sandi"
                titleTypographyProps={{
                  variant: "h4",
                  className: classes.forgotTitle,
                }}
              />
              <CardContent className={classes.cardContentRoot}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
              
                    <Typography className={classes.label}>
                      {gantiPassword.oldPassword.label}
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      hiddenLabel
                      size="medium"
                      type={visibility.oldPassword ? "text" : "password"}
                      placeholder="Masukkan Password Lama"
                      InputProps={{
                        className: classes.inputFilled,
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => handleToggleVisibility('oldPassword')}
                            >
                              {visibility.oldPassword ? (
                                <Visibility className={classes.icon} />
                              ) : (
                                <VisibilityOff className={classes.icon} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      name={gantiPassword.oldPassword.name}
                      value={formik.values[gantiPassword.oldPassword.name]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      error={
                        formik.touched[gantiPassword.oldPassword.name] &&
                        formik.errors[gantiPassword.oldPassword.name] &&
                        true
                      }
                      helperText={
                        formik.errors[gantiPassword.oldPassword.name] &&
                        formik.touched[gantiPassword.oldPassword.name]
                          ? formik.errors[gantiPassword.oldPassword.name]
                          : ""
                      }
                    />
                    <Typography className={classes.label}>
                      {gantiPassword.newPassword.label}
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      hiddenLabel
                      size="medium"
                      type={visibility.newPassword ? "text" : "password"}
                      placeholder="Masukkan Password Baru"
                      InputProps={{
                        className: classes.inputFilled,
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => handleToggleVisibility('newPassword')}
                            >
                              {visibility.newPassword ? (
                                <Visibility className={classes.icon} />
                              ) : (
                                <VisibilityOff className={classes.icon} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      name={gantiPassword.newPassword.name}
                      value={formik.values[gantiPassword.newPassword.name]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      error={
                        formik.touched[gantiPassword.newPassword.name] &&
                        formik.errors[gantiPassword.newPassword.name] &&
                        true
                      }
                      helperText={
                        formik.errors[gantiPassword.newPassword.name] &&
                        formik.touched[gantiPassword.newPassword.name]
                          ? formik.errors[gantiPassword.newPassword.name]
                          : ""
                      }
                    />
                    <Typography className={classes.label}>
                      {gantiPassword.konfirmasiPassword.label}
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      hiddenLabel
                      size="medium"
                      type={visibility.confirmPassword ? "text" : "password"}
                      placeholder="Masukkan Password Baru"
                      InputProps={{
                        className: classes.inputFilled,
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => handleToggleVisibility('confirmPassword')}
                            >
                              {visibility.confirmPassword ? (
                                <Visibility className={classes.icon} />
                              ) : (
                                <VisibilityOff className={classes.icon} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      name={gantiPassword.konfirmasiPassword.name}
                      value={formik.values[gantiPassword.konfirmasiPassword.name]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      error={
                        formik.touched[gantiPassword.konfirmasiPassword.name] &&
                        formik.errors[gantiPassword.konfirmasiPassword.name] &&
                        true
                      }
                      helperText={
                        formik.errors[gantiPassword.konfirmasiPassword.name] &&
                        formik.touched[gantiPassword.konfirmasiPassword.name]
                          ? formik.errors[gantiPassword.konfirmasiPassword.name]
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className={classes.buttonLogin}
                      variant="contained"
                      fullWidth
                      color="secondary"
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? (
                        <i className="fas fa-circle-notch fa-spin" />
                      ) : (
                        ""
                      )}
                      <Typography className={classes.labelSubmit}>
                        Submit
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              {isSecured && (
                <CardActions className={classes.cardActionsRoot}>
                  <Typography className={classes.labelCapitalize1}>
                    Kembali
                  </Typography>
                  <Typography
                    className={classes.labelCapitalize2}
                    onClick={() => history.push("/")}
                  >
                    Beranda
                  </Typography>
                </CardActions>
              )}
              
            </form>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GantiPassword;
