import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Divider,
  Fab,
} from "@material-ui/core";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";
import { PlayArrow } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  tentangKami: {
    background: "#fff",
    textAlign: "center",
    padding: "7vw 0",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "2vw",
    fontWeight: "bold",
  },
  desc: {
    color: theme.palette.primary.main,
    fontSize: "1vw",
    marginTop: "1.5vw",
  },
  divider: {
    width: "2.5vw",
    margin: "1vw auto",
    background: theme.palette.secondary.main,
    height: "0.3vw",
  },
  padding: {
    padding: "2vw 25vw 0 25vw",
  },
  play: {
    color: theme.palette.primary.main,
    position: "absolute",
    width: "4vw",
    height: "4vw",
    minHeight: 0,
  },
  playIcon: {
    fontSize: "2vw",
  },
  video: {
    width: "100%",
    height: "27.5vw",
  },
}));

const TentangKami = ({ innerRef }) => {
  const classes = useStyles();
  const [dataAboutUs, setDataAboutUs] = useState({});
  const isLogin = localStorage.getItem("isLogin");
  const [play, setPlay] = useState(false);
  const [message, setMessage] = useState("Success");

  useEffect(() => {
    if (isLogin) {
      getAboutUs("secured");
    } else {
      getAboutUs("open");
    }
  }, [isLogin]);

  const getAboutUs = (type) => {
    const url =
      type === "open" ? "home.getAboutUsOpen" : "home.getAboutUsSecured";

    API(`${url}`)
      .then((res) => {
        setMessage(res.data?.Message);
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataAboutUs({
            ...res.data.Data,
            aboutUsSectionUrlVideo: getVideoId(
              res.data?.Data.aboutUsSectionUrlVideo
            ),
          });
        } else if(res.status === 200 && res.data?.Message === "Not Found"){
          setDataAboutUs(res.data?.Data);
        }else {
          Toast.fire({
            icon: "info",
            title: res?.data?.Message || "Gagal mendapat data tentang kami!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title:
            res.response?.data?.Message || "Gagal mendapat data tentang kami!",
        });
      });
  };

  const getVideoId = (url) => {
    const tempUrl = url.split("watch?v=");

    return tempUrl[tempUrl.length - 1];
  };

  return (
    <div>
      {message.toLowerCase() !== "not found" && (
        <Box ref={innerRef} className={classes.tentangKami}>
          <Typography className={classes.title}>
            {dataAboutUs?.aboutUsSectionTitle || "Tentang Kami"}
          </Typography>
          <Typography className={classes.desc}>
            {dataAboutUs?.aboutUsSectionSubtitle ||
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
          </Typography>
          <Divider className={classes.divider} />
          <Box className={classes.padding}>
          <iframe
                title="Video"
                className={classes.video}
                src={
                  dataAboutUs.aboutUsSectionUrlVideo
                    ? `https://www.youtube.com/embed/${dataAboutUs.aboutUsSectionUrlVideo}`
                    : "https://www.youtube.com/embed/K1m4-GBgsbw"
                }
                frameBorder="0"
                allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default TentangKami;
