import React, { useEffect, useState } from "react";
import { makeStyles, Box, Typography, Divider, Fab } from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";
import { Toast } from "../../Component/Notification";
import { API } from "../../../service/axios";

const useStyles = makeStyles((theme) => ({
  videoRecruitment: {
    background: "#fff",
    textAlign: "center",
    padding: "7vw 0",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "2vw",
    fontWeight: "bold",
  },
  desc: {
    color: theme.palette.primary.main,
    fontSize: "1vw",
    marginTop: "1.5vw",
  },
  divider: {
    width: "2.5vw",
    margin: "1vw auto",
    background: theme.palette.secondary.main,
    height: "0.3vw",
  },
  videoPadding: {
    padding: "2vw 25vw 0 25vw",
  },
  play: {
    color: theme.palette.primary.main,
    position: "absolute",
    width: "4vw",
    height: "4vw",
    minHeight: 0,
  },
  playIcon: {
    fontSize: "2vw",
  },
  video: {
    width: "100%",
    height: "27.5vw",
  },
}));

const VideoRecruitment = ({ innerRef }) => {
  const classes = useStyles();
  const [play, setPlay] = useState(false);
  const isLogin = localStorage.getItem("isLogin");
  const [dataVideo, setDataVideo] = useState({});
  const [message, setMessage] = useState("Success");

  useEffect(() => {
    if (isLogin) {
      getVideoRecruitment("secured");
    } else {
      getVideoRecruitment("open");
    }
  }, [isLogin]);

  const getVideoRecruitment = (type) => {
    const url =
      type === "open"
        ? "home.getVideoRecruitmentOpen"
        : "home.getVideoRecruitmentSecured";

    API(`${url}`)
      .then((res) => {
        setMessage(res.data?.Message);
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataVideo({
            ...res.data?.Data,
            vrSectionUrlVideo: getVideoId(res.data?.Data.vrSectionUrlVideo),
          });
        } else if(res.status === 200 && res.data?.Message === "Not Found"){
          setDataVideo(res.data?.Data);
        }else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data Video!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data Video!",
        });
      });
  };

  const getVideoId = (url) => {
    const tempUrl = url.split("watch?v=");

    return tempUrl[tempUrl.length - 1];
  };

  return (
    <div>
      {message.toLowerCase() !== "not found" && (
        <Box ref={innerRef} className={classes.videoRecruitment}>
          <Typography className={classes.title}>
            {dataVideo.vrSectionTitle || "Video Recruitment"}
          </Typography>
          <Typography className={classes.desc}>
            {dataVideo.vrSectionSubtitle ||
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
          </Typography>
          <Divider className={classes.divider} />
          <Box className={classes.videoPadding}>
          <iframe
                title="Video"
                className={classes.video}
                src={
                  dataVideo.vrSectionUrlVideo
                    ? `https://www.youtube.com/embed/${dataVideo.vrSectionUrlVideo}`
                    : "https://www.youtube.com/embed/K1m4-GBgsbw"
                }
                frameBorder="0"
                allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default VideoRecruitment;
