import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Switch } from "react-router-dom";
// import "react-perfect-scrollbar/dist/css/styles.css";
import configureStore, { history } from "./redux/store";
// import AppWrapper from './@jumbo/components/AppWrapper';
// import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import 'bootstrap/dist/css/bootstrap.min.css';
import Routes from "./routes";

export const store = configureStore();

const theme = createTheme({
  palette: {
    primary: {
      main: "#005A7D",
    },
    secondary: {
      main: "#E21A43",
    },
  },
});

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Routes />
        </Switch>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>
);

export default App;
